import React from 'react'

import { Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import {
    CheckCircleOutline,
    CheckOutlined,
    CloseOutlined,
    DeleteOutline,
    EditOutlined,
} from '@mui/icons-material'

import {
    PageLayoutMobile,
    ScrollableToolbar,
} from '@backoffice/components/common'
import { HomeworkForm } from '@backoffice/components/forms'
import { CreateHomeworkPageProps } from './index.types'
import {
    HomeworkCreateInput,
    HomeworkUpdateInput,
} from '../../../../../api/root/generated'

const HomeworkPageMobile = ({
    editable,
    onChangeEditable,
    loading,
    onCreate,
    onDelete,
    onEdit,
    formikRef,
    onBack,
    caption,
    homework,
    creation,
    onSubmissionsClick,
}: CreateHomeworkPageProps) => {
    const renderToolbar = () => {
        if (creation) {
            return (
                <ScrollableToolbar
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                    actions={[
                        {
                            label: 'Створити',
                            key: 'create',
                            startIcon: <CheckCircleOutline />,
                            variant: 'success',
                            onClick: () => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            },
                        },
                    ]}
                />
            )
        }
        return editable ? (
            <ScrollableToolbar
                actions={[
                    {
                        label: 'Зберегти',
                        key: 'save',
                        startIcon: <CheckCircleOutline />,
                        variant: 'success',
                        onClick: () => {
                            if (formikRef.current) {
                                formikRef.current.handleSubmit()
                            }
                        },
                    },

                    {
                        label: 'Вiдмiнити',
                        key: 'cancel',
                        startIcon: <CloseOutlined />,
                        variant: 'default',
                        onClick: onChangeEditable,
                    },
                    {
                        label: 'Видалити',
                        key: 'delete',
                        startIcon: <DeleteOutline />,
                        variant: 'error',
                        onClick: onDelete,
                    },
                ]}
            />
        ) : (
            <ScrollableToolbar
                actions={[
                    {
                        label: `Вiдповiдi (${homework?.submissions?.edges?.length})`,
                        key: 'submissions',
                        variant: 'default',
                        disabled: homework?.submissions?.edges?.length === 0,
                        onClick: onSubmissionsClick,
                        active: homework?.submissions?.edges?.length !== 0,
                    },
                    {
                        label: 'Редагувати',
                        key: 'edit',
                        startIcon: <EditOutlined />,
                        variant: 'default',
                        onClick: onChangeEditable,
                    },
                ]}
            />
        )
    }

    return (
        <PageLayoutMobile
            pageTitle={`${t('backoffice:pageTitles.homeworkPage')}`}
            onBack={onBack}
            title={t('backoffice:homework.title')}
            caption={caption}
            toolbar={renderToolbar}
        >
            <HomeworkForm
                homework={homework}
                loading={loading}
                editable={editable}
                onSubmit={(values: HomeworkCreateInput | HomeworkUpdateInput) =>
                    creation
                        ? onCreate(values as HomeworkCreateInput)
                        : onEdit(values as HomeworkUpdateInput)
                }
                formikRef={formikRef}
            />
        </PageLayoutMobile>
    )
}

export default HomeworkPageMobile
