import React, { JSX } from 'react'

import { useDrawer } from 'hooks/common'
import { Drawer } from 'components'

const ReactiveDrawer = (): JSX.Element | null => {
    const {
        data: { component: Component, options, props },
    } = useDrawer()

    return (
        <Drawer {...options} open={!!Component}>
            {Component && <Component {...props} />}
        </Drawer>
    )
}

export default ReactiveDrawer
