export const PAGE_LAYOUT_STYLES = {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 20px',
    gap: '50px',
    '& > *': {
        maxWidth: '1720px',
        width: '100%',
    },
}
