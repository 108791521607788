import React, { useEffect, useState } from 'react'

import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { keyframes } from '@emotion/react'

interface BreadcrumbProps {
    title: string
    caption?: string | null
    onBack?: () => void
}

const Breadcrumb = ({ title, caption, onBack }: BreadcrumbProps) => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const largeMobile = useMediaQuery(theme.breakpoints.up('xs'))

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(true)
        return () => setVisible(false)
    }, [])

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap={tablet ? '10px' : 0}
            onClick={onBack}
            width="fit-content"
            sx={theme => ({
                position: 'relative',
                cursor: 'pointer',
                opacity: visible ? 1 : 0,
                transition: '0.3s ease',
                '& .MuiSvgIcon-root': {
                    position: 'absolute',
                    fontSize: desktop
                        ? '32px'
                        : tablet
                        ? '24px'
                        : largeMobile
                        ? '18px'
                        : '14px',
                    ...(tablet
                        ? {
                              top: '3px',
                              left: '-45px',
                          }
                        : {
                              top: '-25px',
                              left: '-3px',
                              color: 'rgba(255, 255, 255, .3) !important',
                          }),
                },
                '& .MuiSvgIcon-root, & .MuiTypography-root': {
                    transition: '0.3s ease',
                    fontWeight: 700,
                    color: theme.palette.secondary.main,
                },
                '&:hover': {
                    '& .MuiSvgIcon-root, & .MuiTypography-root': {
                        color: theme.palette.secondary.light,
                    },
                },
            })}
        >
            {onBack && <KeyboardBackspace />}

            <Stack gap="5px">
                <Typography
                    color="primary.main"
                    sx={() => ({
                        fontSize: desktop
                            ? '32px'
                            : tablet
                            ? '24px'
                            : largeMobile
                            ? '18px'
                            : '16px',
                        opacity: visible ? 1 : 0,
                        lineHeight: desktop ? '34px' : tablet ? '26px' : '20px',
                        letterSpacing: '-1px',
                    })}
                >
                    {title || '৽৽৽'}
                </Typography>
                {caption && (
                    <Typography
                        color="primary.dark"
                        sx={theme => ({
                            fontSize: desktop
                                ? '18px'
                                : tablet
                                ? '16px'
                                : largeMobile
                                ? '13px'
                                : '12px',

                            fontWeight: '400 !important',
                            opacity: visible ? 1 : 0,
                            lineHeight: '18px',
                            color: `${theme.palette.secondary.dark} !important`,
                        })}
                    >
                        {caption}
                    </Typography>
                )}
            </Stack>
        </Stack>
    )
}

export default Breadcrumb
