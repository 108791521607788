import { string, object } from 'yup'

export interface SocialsFormValues {
    instagram: string
    tiktok: string
    facebook: string
}

export const SocialsFormScheme = object({
    tiktok: string()
        .url('Некоректна URL')
        .matches(
            /^https?:\/\/(www\.)?tiktok\.com\//,
            'Введіть правильне посилання на TikTok'
        )
        .nullable(),
    instagram: string()
        .url('Некоректна URL')
        .matches(
            /^https?:\/\/(www\.)?instagram\.com\//,
            'Введіть правильне посилання на Instagram'
        )
        .nullable(),
    facebook: string()
        .url('Некоректна URL')
        .matches(
            /^https?:\/\/(www\.)?facebook\.com\//,
            'Введіть правильне посилання на Facebook'
        )
        .nullable(),
})
