import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    GrantLessonAccessDocument,
    GrantLessonAccessMutation,
    CourseAccessListDocument,
    CourseAccessType,
    MutationGrantLessonAccessArgs,
    CourseAccessListQueryVariables,
    CourseAccessDocument,
    MutationCourseAccessDeleteArgs,
    CourseAccessDeleteMutation,
    CourseAccessDeleteDocument,
} from 'api/root/generated'

export const useAccesses = (
    variables: CourseAccessListQueryVariables,
    options = {}
) => {
    const [response, setResponse] = useState<CourseAccessType[]>([])

    const { data, loading, error, refetch, networkStatus } = useQuery(
        CourseAccessListDocument,
        {
            variables,
            ...options,
        }
    )

    useEffect(() => {
        if (data?.courseAccessList) {
            const accesses: CourseAccessType[] =
                data?.courseAccessList.edges.map(
                    edge => edge?.node as CourseAccessType
                )
            setResponse(accesses)
        }
    }, [data])

    return {
        accesses: response,
        loading,
        error,
        refetch,
        networkStatus,
    }
}

export const useAccess = (courseId: string, clientId: string) => {
    const { data, loading, error, refetch } = useQuery(CourseAccessDocument, {
        variables: { clientId, courseId },
        skip: !clientId || !courseId,
    })

    let access

    if (data?.courseAccess) {
        access = data.courseAccess
    }

    return {
        access,
        loading,
        error,
        refetch,
    }
}

export const useAccessAPI = () => {
    const [grantLesson, { loading: grantingLessonAccess }] = useMutation(
        GrantLessonAccessDocument
    )

    const [accessDelete, { loading: deletingAccess }] = useMutation(
        CourseAccessDeleteDocument
    )

    const grantLessonAccess = (data: MutationGrantLessonAccessArgs) => {
        return grantLesson({ variables: data }).then(
            (response: FetchResult<GrantLessonAccessMutation>) => {
                return response?.data?.grantLessonAccess
            }
        )
    }

    const deleteAccess = (data: MutationCourseAccessDeleteArgs) => {
        return accessDelete({
            variables: data,
        }).then((response: FetchResult<CourseAccessDeleteMutation>) => {
            return response?.data?.courseAccessDelete
        })
    }

    return {
        deleteAccess,
        grantLessonAccess,
        loading: grantingLessonAccess || deletingAccess,
    }
}
