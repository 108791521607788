import React, { JSX } from 'react'

import { t } from 'i18next'
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import {
    KeyboardArrowRight,
    ThumbDownOffAlt,
    ThumbUpOffAlt,
} from '@mui/icons-material'

import { PageLayout } from '@backoffice/components/common'
import { Editor, SearchField } from 'components'
import { HomeworkSubmissionType } from 'api/root/generated'
import {
    formatUploadedItemFromUrl,
    resolveTextByStatus,
} from 'utils/common.utils'
import { HomeworkSubmissionsPageProps } from '@backoffice/components/pages/HomeworkSubmissionsPage/index.types'

const HomeworkSubmissionsPageDesktop = ({
    onApproveSubmission,
    onRejectSubmission,
    submissions,
    onBack,
    loading,
    caption,
    onSubmissionClick,
    onSearch,
}: HomeworkSubmissionsPageProps): JSX.Element => {
    const renderActions = (submission: HomeworkSubmissionType) => {
        return (
            <Stack direction="row" alignItems="center" gap="15px">
                <IconButton
                    color="error"
                    onClick={() => onRejectSubmission(submission.id)}
                >
                    <ThumbDownOffAlt />
                </IconButton>
                <IconButton
                    color="success"
                    onClick={() => onApproveSubmission(submission.id)}
                >
                    <ThumbUpOffAlt />
                </IconButton>
            </Stack>
        )
    }

    const renderSubmission = (submission: HomeworkSubmissionType) => {
        return (
            <Stack
                key={submission.id}
                sx={{
                    padding: '20px 25px',
                    borderRadius: '16px',
                    background: 'rgb(50, 50, 50)',
                    border: '1px solid #404040',
                }}
            >
                <Stack divider={<Divider />}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        paddingBottom="20px"
                    >
                        <Typography fontWeight={700} fontSize="24px">
                            {submission.client.email}
                        </Typography>
                        {submission.status?.val === 'Pending' ? (
                            renderActions(submission)
                        ) : (
                            <Typography
                                fontWeight={700}
                                color={
                                    submission.status?.val === 'Rejected'
                                        ? 'error'
                                        : 'success'
                                }
                            >
                                {resolveTextByStatus(submission.status)}{' '}
                            </Typography>
                        )}
                    </Stack>
                    <Editor
                        editable={false}
                        content={submission.content}
                        uploadedFiles={formatUploadedItemFromUrl(
                            submission.attachments || []
                        )}
                    />
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        onClick={() => onSubmissionClick(submission)}
                        variant="text"
                        size="large"
                        color="secondary"
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '24px',
                            },
                        }}
                        endIcon={<KeyboardArrowRight />}
                    >
                        <Typography>Детальнiше</Typography>
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return (
        <PageLayout
            pageTitle={`${t('backoffice:homework.submissions.title')}`}
            onBack={onBack}
            title={t('backoffice:homework.submissions.title')}
            caption={caption}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <SearchField
                    sx={{
                        maxWidth: '350px',
                    }}
                    key="search"
                    placeholder={
                        t(
                            'backoffice:homework.submissions.emailSearch'
                        ) as string
                    }
                    onChange={onSearch}
                />
            </Stack>
            <Stack divider={<Divider />} gap="35px">
                {submissions.map(renderSubmission)}
            </Stack>
        </PageLayout>
    )
}

export default HomeworkSubmissionsPageDesktop
