import React from 'react'

import {
    Button,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    KeyboardArrowRight,
    BarChartOutlined,
    InsightsOutlined,
} from '@mui/icons-material'
import { t } from 'i18next'

import { CourseType } from 'api/root/generated'

interface OverviewProps {
    course: CourseType
}

const Overview = ({ course }: OverviewProps) => {
    const PROFIT = 0

    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const renderStatisticItem = (title: string, value: number) => {
        return (
            <Stack
                direction="row"
                alignItems="baseline"
                gap="8px"
                sx={theme => ({
                    padding: '10px',
                    color: theme.palette.secondary.main,
                })}
            >
                <Typography color="secondary.light" fontSize="24px">
                    {value}
                </Typography>
                <Typography fontSize="16px" color="secondary.dark">
                    {title}
                </Typography>
            </Stack>
        )
    }

    const renderEarnedStat = () => {
        return (
            <Stack
                alignItems={tablet ? 'flex-end' : 'baseline'}
                sx={{
                    paddingRight: '25px',
                    width: '50%',
                }}
            >
                <Typography>
                    {tablet ? 'Зароблено на цьому контентi' : 'Зароблено'}{' '}
                </Typography>
                <Typography
                    sx={theme => ({
                        fontSize: tablet ? '53px' : '22px',
                        lineHeight: tablet ? '55px' : '23px',
                        color:
                            PROFIT > 0
                                ? theme.palette.success.main
                                : theme.palette.secondary.dark,
                    })}
                >
                    {PROFIT}$
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack
            gap="20px"
            sx={{
                flexGrow: 1,
                padding: tablet ? '15px 25px 25px' : '15px 25px',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.12)',
                background: 'rgba(255, 255, 255, 0.05)',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                {tablet ? (
                    <Stack gap="8px" direction="row" alignItems="center">
                        <BarChartOutlined />
                        <Typography fontSize="20px" color="secondary.dark">
                            {t('backoffice:course.overview.title')}
                        </Typography>
                    </Stack>
                ) : (
                    renderEarnedStat()
                )}

                <Button
                    endIcon={<KeyboardArrowRight />}
                    variant="text"
                    color="secondary"
                    size="large"
                >
                    <Typography>
                        {tablet ? 'Розширена статистика' : 'Детальнiше'}
                    </Typography>
                </Button>
            </Stack>

            {tablet && (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={theme => ({
                            flexWrap: 'wrap',
                            maxWidth: '50%',
                            width: '100%',
                            '& > *': {
                                flexBasis: '50%',
                            },
                        })}
                    >
                        {renderStatisticItem(
                            t('backoffice:course.overview.lessonCount'),
                            course?.lessonCount as number
                        )}
                        {renderStatisticItem(
                            t('backoffice:course.overview.viewCount'),
                            course?.viewCount as number
                        )}
                        {renderStatisticItem(
                            t('backoffice:course.overview.accessCount'),
                            course?.accessCount as number
                        )}
                    </Stack>
                    {renderEarnedStat()}
                </Stack>
            )}
        </Stack>
    )
}

export default Overview
