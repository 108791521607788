import React, { JSX } from 'react'

import { Divider, Stack, SxProps, Typography } from '@mui/material'

interface PaperProps {
    content: () => JSX.Element | JSX.Element[] | undefined | null
    footer?: () => JSX.Element | JSX.Element[] | undefined | null
    title?: string
    actions?: JSX.Element[]
    sx?: SxProps
    titleSx?: SxProps
}

const Paper = ({
    content,
    titleSx = {},
    title,
    actions,
    footer,
    sx = {},
}: PaperProps) => {
    return (
        <Stack gap="8px" sx={{ ...sx }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                {title && (
                    <Typography
                        fontSize="16px"
                        lineHeight="20px"
                        sx={{ ...titleSx }}
                    >
                        {title}
                    </Typography>
                )}
                {actions && (
                    <Stack direction="row" gap="5px">
                        {actions}
                    </Stack>
                )}
            </Stack>
            <Stack
                divider={<Divider />}
                sx={{
                    background: '#252525',
                    borderRadius: '12px',
                    padding: '0 10px',
                }}
            >
                {content()}
                {footer?.()}
            </Stack>
        </Stack>
    )
}

export default Paper
