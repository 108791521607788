import React, { JSX } from 'react'

import { Divider, Stack } from '@mui/material'

import { CourseType, LessonType, UserType } from 'api/root/generated'

interface DataListProps<T> {
    items: T[]
    renderItem: (item: T) => JSX.Element
    divided?: boolean
    gap?: string
}

const DataList = <T extends CourseType | LessonType | UserType>({
    gap = '25px',
    items,
    renderItem,
    divided = false,
}: DataListProps<T>) => {
    return (
        <Stack gap={gap} divider={divided ? <Divider /> : undefined}>
            {items.map(renderItem)}
        </Stack>
    )
}

export default DataList
