import { useMediaQuery, useTheme } from '@mui/material'

import { useDialog, useDrawer } from '../../../../hooks/common'
import { ResourceConfirmation, ResourceConfirmationProps } from 'components'

const useConfirmationDialog = <T extends { name: string }>() => {
    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const openConfirmationModal = (
        data: T,
        type: 'lesson' | 'module' | 'course',
        onSuccess: () => void,
        props?: Partial<ResourceConfirmationProps<T>>
    ) => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer
        openModal({
            component: ResourceConfirmation,
            props: {
                type,
                onApprove: () => {
                    onSuccess()
                    closeModal()
                },
                onReject: closeModal,
                data,
                ...props,
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    return {
        requestConfirmation: openConfirmationModal,
        close,
    }
}

export default useConfirmationDialog
