import React, { JSX } from 'react'

import { useNavigate } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'

import { paths as backofficePaths } from '@backoffice/index.routes'
import StatisticsPageDesktop from '@backoffice/components/pages/StatisticsPage/index.desktop'
import StatisticsPageMobile from '@backoffice/components/pages/StatisticsPage/index.mobile'

const StatisticsPage = (): JSX.Element => {
    const navigate = useNavigate()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const handleBack = () => navigate(`../${backofficePaths.backoffice}`)

    return tablet ? (
        <StatisticsPageDesktop onBack={handleBack} />
    ) : (
        <StatisticsPageMobile onBack={handleBack} />
    )
}
export default StatisticsPage
