import React, { JSX } from 'react'

import { Typography, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { Add } from '@mui/icons-material'

import { Image } from 'components'
import { PageLayout } from '@backoffice/components/common'
import { CourseType } from 'api/root/generated'
import noBackground from 'assets/images/no-background.png'
import { ContentPageProps } from './index.types'

const ContentPageDesktop = ({
    courses,
    onAdd,
    loading,
    onClick,
    onBack,
}: ContentPageProps): JSX.Element => {
    const columns: GridColDef<CourseType>[] = [
        {
            field: '@',
            headerName: ' ',
            align: 'center',
            renderCell: (params: GridRenderCellParams<CourseType>) => {
                return (
                    <Image
                        sx={{
                            width: '60px',
                            height: '40px',
                            objectFit: 'cover',
                            verticalAlign: 'middle',
                        }}
                        src={
                            params.row.appearance?.background?.img150x100 ||
                            noBackground
                        }
                    />
                )
            },
        },
        {
            field: 'name',
            headerName: t('backoffice:content.table.columns.name') as string,
            flex: 2,
        },
        {
            flex: 0.5,
            field: 'lessonCount',
            headerName: t(
                'backoffice:content.table.columns.lessonCount'
            ) as string,
            cellClassName: 'secondary',
            headerAlign: 'center',
            align: 'center',
        },
        {
            flex: 0.5,
            field: 'accessCount',
            headerName: t(
                'backoffice:content.table.columns.accessCount'
            ) as string,
            cellClassName: 'secondary',
            headerAlign: 'center',
            align: 'center',
        },
        {
            flex: 0.5,
            field: 'streamCount',
            headerName: t(
                'backoffice:content.table.columns.streamCount'
            ) as string,
            cellClassName: 'secondary',
            headerAlign: 'center',
            align: 'center',
        },
    ]

    return (
        <PageLayout
            pageTitle={t('backoffice:pageTitles.contentPage')}
            title={t('backoffice:content.title') as string}
            onBack={onBack}
            actions={[
                <Tooltip
                    key="create"
                    title={
                        <Typography>
                            {t(
                                'backoffice:content.course.commonActions.create'
                            )}
                        </Typography>
                    }
                >
                    <IconButton size="large" color="primary" onClick={onAdd}>
                        <Add />
                    </IconButton>
                </Tooltip>,
            ]}
        >
            <DataGrid
                autoHeight
                loading={loading}
                rows={courses}
                columns={columns}
                onRowClick={params => onClick(params.row.id)}
            />
        </PageLayout>
    )
}
export default ContentPageDesktop
