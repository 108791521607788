import React from 'react'

import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Add, Quiz } from '@mui/icons-material'
import { Button, Divider, Stack, useMediaQuery, useTheme } from '@mui/material'

import { ActionBar } from '@backoffice/components/common'
import { CourseType, LessonType, ModuleType } from 'api/root/generated'
import { LessonForm, ModuleForm } from '@backoffice/components/forms'
import useCourseEntityAPI from '@backoffice/hooks/course'
import { useDialog } from 'hooks/common'
import useConfirmationDialog from '@backoffice/hooks/common/useConfirmationDialog'
import { paths as backofficePaths } from '@backoffice/index.routes'

interface ModuleActionBarProps {
    course: CourseType
    module: ModuleType
    disabled?: boolean
    variant?: 'inline' | 'menu'
}

const ModuleActionBar = ({
    module,
    course,
    disabled = false,
    variant = 'inline',
}: ModuleActionBarProps) => {
    const navigate = useNavigate()

    const { open, close } = useDialog()

    const { enqueueSnackbar } = useSnackbar()

    const { refetch, removeModule } = useCourseEntityAPI(course.id)
    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const handleDeleteModule = () => {
        removeModule({
            input: {
                moduleId: module.id,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                close()
                enqueueSnackbar(
                    t(
                        'backoffice:content.course.module.notifications.successDeleted'
                    ),
                    { variant: 'success' }
                )
            }
        })
    }

    const handleEditModule = () => {
        open({
            component: ModuleForm,
            props: {
                module,
                course,
                onSuccess: (action: 'add' | 'update') => {
                    close()
                    refetch()
                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.module.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.module.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
                onDelete: () => {
                    close()
                    requestConfirmation(
                        module as ModuleType,
                        'module',
                        handleDeleteModule
                    )
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenHomework = () => {
        navigate(
            `../${backofficePaths.homework}?category=module&relationId=${module.id}&homeworkId=${module?.homework?.id}&title=${module?.name}`
        )
    }

    return (
        <ActionBar
            variant={variant}
            size="medium"
            disabled={disabled}
            actions={[
                {
                    type: 'update',
                    key: 'update',
                    title: t('backoffice:content.course.module.actions.edit'),
                    onClick: handleEditModule,
                },
            ]}
            extra={[
                {
                    icon: module.homework ? <Quiz /> : <Add />,
                    key: module.homework ? 'update-homework' : 'add-homework',
                    title: t(
                        module.homework
                            ? 'backoffice:content.course.lesson.actions.updateHomework'
                            : 'backoffice:content.course.lesson.actions.addHomework'
                    ),
                    onClick: handleOpenHomework,
                },
            ]}
        />
    )
}

export default ModuleActionBar
