import React, { RefObject } from 'react'

import { FormikProps, useFormik } from 'formik'
import { Stack, useMediaQuery, useTheme } from '@mui/material'

import { HomeworkFormScheme, HomeworkFormValues } from './index.schema'
import {
    HomeworkCreateInput,
    HomeworkType,
    HomeworkUpdateInput,
} from 'api/root/generated'
import {
    formatUploadedItemFromUrl,
    normalizeAttachmentsForUpdate,
} from 'utils/common.utils'
import { UploadedItem } from 'types'
import { Editor, Form } from 'components'

interface HomeworkFormProps {
    onSubmit: (values: HomeworkUpdateInput | HomeworkCreateInput) => void
    homework?: HomeworkType
    editable: boolean
    formikRef?: RefObject<FormikProps<HomeworkFormValues> | null>
    loading: boolean
}

const HomeworkForm = ({
    onSubmit,
    homework,
    editable,
    formikRef,
    loading,
}: HomeworkFormProps) => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: homework
            ? {
                  content: homework.content || '',
                  attachments: homework.attachments?.length
                      ? formatUploadedItemFromUrl(homework.attachments)
                      : [],
                  requireModeration: false,
                  editable: false,
                  deadline: homework.deadline
                      ? new Date(homework.deadline)
                      : null,
              }
            : {
                  content: '',
                  attachments: [],
                  requireModeration: false,
                  editable: false,
                  deadline: null,
              },
        validationSchema: HomeworkFormScheme,
        enableReinitialize: true,
        onSubmit: async (values: HomeworkFormValues) => {
            const { content, deadline, attachments } = values

            onSubmit({
                content,
                deadline,
                isMandatory: true,
                ...(attachments.length && {
                    attachments: normalizeAttachmentsForUpdate(attachments),
                }),
                ...(homework?.attachments?.length &&
                    !attachments.length && { removeAttachment: true }),
            } as HomeworkCreateInput | HomeworkUpdateInput)
        },
    })

    if (formikRef) {
        formikRef.current = formik
    }

    const handleFileUploadChange = (items: UploadedItem[]) => {
        formik.setFieldValue('attachments', items)
    }

    return (
        <Form
            onSubmit={formik.handleSubmit}
            sx={{
                borderRadius: '16px',
                background: '#252525',
                padding: tablet ? '25px' : '15px 10px',
            }}
        >
            <Stack gap="50px">
                <Stack gap="20px">
                    <Editor
                        error={Boolean(
                            formik.touched.content && formik.errors.content
                        )}
                        helperText={
                            formik.touched.content && formik.errors.content
                                ? (formik.errors.content as string)
                                : ''
                        }
                        deadlineError={Boolean(
                            formik.touched.deadline && formik.errors.deadline
                        )}
                        heightToContent={false}
                        onChange={newContent =>
                            formik.setFieldValue('content', newContent)
                        }
                        editable={editable && !loading}
                        content={formik.values.content}
                        onFileChange={handleFileUploadChange}
                        uploadedFiles={formik.values.attachments}
                        onDeadlineChange={(newValue: Date | null) => {
                            formik.setFieldValue('deadline', newValue)
                        }}
                        deadline={
                            formik.values.deadline
                                ? new Date(formik.values.deadline)
                                : null
                        }
                    />
                </Stack>
            </Stack>
        </Form>
    )
}

export default HomeworkForm
