import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    Button,
    Skeleton,
    Switch,
    Divider,
    CircularProgress,
    LinearProgress,
    IconButton,
} from '@mui/material'
import { t } from 'i18next'
import {
    Add,
    CallSplitRounded,
    CheckCircleOutline,
    DeleteOutline,
    EditOutlined,
    ErrorOutline,
    MoreVert,
    ViewList,
} from '@mui/icons-material'

import { LessonType, ModuleType } from 'api/root/generated'
import {
    LoadingContent,
    PageLayoutMobile,
    Paper,
    ScrollableToolbar,
} from '@backoffice/components/common'
import { useHomeworks } from '@backoffice/hooks/homeworks'
import { CoursePageProps } from './index.types'
import ModuleActionBar from '@backoffice/components/pages/NewCoursePage/components/ModuleActionBar'
import { Image } from 'components'
import noBackground from 'assets/images/no-background.png'
import LessonActionBar from './components/LessonActionBar'
import LessonMenu from './components/LessonMenu'
import { useDrawer } from '../../../../../hooks/common'
import ModuleMenu from './components/ModuleMenu'

const CoursePageMobile = ({
    caption,
    selectable,
    onAddLesson,
    onAddModule,
    onBack,
    course,
    modules,
    selectedLessons,
    onSelectLesson,
    onEditCourse,
    loadingContent,
    onDeleteAccess,
    onGrantAccess,
    onStreamsClick,
    params,
}: CoursePageProps): JSX.Element => {
    const { homeworks } = useHomeworks()
    const { open, close } = useDrawer()

    const handleOpenModuleMenu = (module: ModuleType) => {
        open({
            component: ModuleMenu,
            props: {
                course,
                module,
                onClose: close,
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenLessonMenu = (lesson: LessonType, module: ModuleType) => {
        open({
            component: LessonMenu,
            props: {
                lesson,
                module,
                onClose: close,
            },
            options: {
                onClose: close,
            },
        })
    }

    const renderPlaceholder = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                padding="10px 5px"
            >
                <Skeleton
                    variant="rectangular"
                    width="32px"
                    sx={{
                        height: '32px',
                        borderRadius: '4px',
                    }}
                />
                <Skeleton sx={{ flexGrow: 1 }} />
            </Stack>
        )
    }

    const renderNewLessonButton = (module: ModuleType, first = false) => {
        return (
            <Button
                onClick={() => onAddLesson(module)}
                sx={{
                    padding: '15px 5px',
                    textAlign: 'left',
                    '& .MuiSvgIcon-root': {
                        fontSize: '22px',
                    },
                }}
                variant="text"
                color="primary"
                startIcon={<Add />}
            >
                <Typography fontSize="14px" lineHeight="16px">
                    {first
                        ? t(
                              'backoffice:content.course.lesson.actions.createFirst'
                          )
                        : t('backoffice:content.course.lesson.actions.add')}
                </Typography>
            </Button>
        )
    }

    const renderNewModuleButton = (first = false) => {
        return (
            <Button
                onClick={onAddModule}
                sx={() => ({
                    width: 'fit-content',
                    '& .MuiSvgIcon-root': {
                        fontSize: '22px',
                    },
                })}
                variant="text"
                color="primary"
                startIcon={<Add />}
            >
                <Typography fontSize="16px">
                    {first
                        ? t(
                              'backoffice:content.course.module.actions.createFirst'
                          )
                        : t('backoffice:content.course.module.actions.add')}
                </Typography>
            </Button>
        )
    }

    const renderEmptyCourseScreen = () => {
        return (
            <Stack alignItems="center" justifyContent="center" paddingTop="25%">
                <ViewList
                    sx={() => ({
                        opacity: '0.4',
                        fontSize: '124px',
                        color: 'rgba(255, 255, 255, 0.15)',
                    })}
                />
                <Stack gap="25px" alignItems="center">
                    <Typography color="secondary.dark" fontSize="14px">
                        {t('backoffice:content.course.noContentCaption')}
                    </Typography>
                    {renderNewModuleButton(true)}
                </Stack>
            </Stack>
        )
    }

    const renderToolbar = () => {
        if (selectable && !params.id) {
            return (
                <ScrollableToolbar
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                    actions={[
                        {
                            disabled: selectedLessons.length === 0,
                            label:
                                selectedLessons.length === 0
                                    ? 'Надати доступ'
                                    : `Надати доступ (${selectedLessons.length})`,
                            key: 'edit',
                            startIcon: <CheckCircleOutline />,
                            variant: 'success',
                            onClick: onGrantAccess,
                        },
                    ]}
                />
            )
        }
        if (selectable && params.id) {
            if (selectedLessons.length === 0) {
                return (
                    <ScrollableToolbar
                        sx={{
                            justifyContent: 'flex-end',
                        }}
                        actions={[
                            {
                                label: `Видалити доступи до курсу`,
                                key: 'edit',
                                startIcon: <DeleteOutline />,
                                variant: 'error',
                                onClick: onDeleteAccess,
                            },
                        ]}
                    />
                )
            }

            return (
                <ScrollableToolbar
                    sx={{
                        justifyContent: 'flex-end',
                    }}
                    actions={[
                        {
                            label: `Зберегти (${selectedLessons.length})`,
                            key: 'edit',
                            startIcon: <CheckCircleOutline />,
                            variant: 'success',
                            onClick: onGrantAccess,
                        },
                    ]}
                />
            )
        }

        return (
            <ScrollableToolbar
                actions={[
                    {
                        label: 'Потоки',
                        key: 'streams',
                        startIcon: <CallSplitRounded />,
                        onClick: onStreamsClick,
                    },
                    {
                        label: 'Редагувати курс',
                        key: 'edit',
                        startIcon: <EditOutlined />,
                        onClick: onEditCourse,
                    },
                ]}
            />
        )
    }

    const renderLoadingItem = (item: LoadingContent) => (
        <Stack
            padding="10px 5px"
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            gap="15px"
        >
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                width="100%"
                sx={{
                    opacity: 0.6,
                }}
            >
                <Image
                    sx={{
                        minWidth: '32px',
                        width: '32px',
                        height: '32px',

                        borderRadius: '4px',

                        objectFit: 'cover',
                    }}
                    src={item.image || noBackground}
                />
                <Stack gap="8px" width="100%">
                    <Typography
                        sx={{
                            fontSize: '14px',
                            lineHeight: '16px',
                        }}
                    >
                        {item.name}
                    </Typography>
                    <LinearProgress />
                </Stack>
            </Stack>
            {item.status === 'COMPLETED' ? (
                <CheckCircleOutline
                    sx={{
                        padding: '8px',
                        fontSize: '38px',
                    }}
                    color="success"
                />
            ) : item.status === 'FAILED' ? (
                <ErrorOutline
                    color="error"
                    sx={{
                        padding: '8px',
                        fontSize: '38px',
                    }}
                />
            ) : undefined}
        </Stack>
    )

    return (
        <PageLayoutMobile
            pageTitle={`${t('backoffice:pageTitles.backoffice')} | ${
                course?.name
            }`}
            onBack={onBack}
            title={course?.name as string}
            caption={caption}
            sx={{
                gap: '25px',
            }}
            toolbar={renderToolbar}
        >
            {modules.length !== 0
                ? modules?.map(module => (
                      <Paper
                          sx={{
                              opacity:
                                  selectable &&
                                  module.lessons.edges?.length === 0
                                      ? 0.4
                                      : 1,
                          }}
                          key={module.id}
                          title={module?.name}
                          content={() =>
                              module?.lessons?.edges
                                  ?.map(edge => edge?.node as LessonType)
                                  ?.map((lesson: LessonType) => {
                                      const preselected =
                                          !!selectedLessons.find(
                                              l => l.lessonId === lesson.id
                                          )

                                      return (
                                          <Stack
                                              direction="row"
                                              padding="10px 5px"
                                              gap="15px"
                                              alignItems="center"
                                              justifyContent="space-between"
                                          >
                                              <Stack
                                                  direction="row"
                                                  alignItems="center"
                                                  gap="15px"
                                              >
                                                  <Image
                                                      sx={{
                                                          minWidth: '32px',
                                                          width: '32px',
                                                          height: '32px',
                                                          objectFit: 'cover',
                                                          borderRadius: '4px',
                                                      }}
                                                      src={
                                                          lesson.videoPreview
                                                              ?.img60x40 ||
                                                          noBackground
                                                      }
                                                  />
                                                  <Stack gap="5px">
                                                      <Typography
                                                          sx={{
                                                              fontSize: '15px',
                                                              lineHeight:
                                                                  '15px',
                                                          }}
                                                      >
                                                          {lesson.name}
                                                      </Typography>
                                                      {lesson.homework && (
                                                          <Typography
                                                              color="gold"
                                                              sx={{
                                                                  fontSize:
                                                                      '11px',
                                                                  lineHeight:
                                                                      '11px',
                                                              }}
                                                          >
                                                              Домашне завдання
                                                          </Typography>
                                                      )}
                                                  </Stack>
                                              </Stack>
                                              {selectable ? (
                                                  <Switch
                                                      checked={preselected}
                                                      onChange={() => {
                                                          if (selectable) {
                                                              onSelectLesson(
                                                                  prevState => {
                                                                      if (
                                                                          preselected
                                                                      ) {
                                                                          return prevState.filter(
                                                                              l =>
                                                                                  l.lessonId !==
                                                                                  lesson.id
                                                                          )
                                                                      }
                                                                      return [
                                                                          ...prevState,
                                                                          {
                                                                              lessonId:
                                                                                  lesson.id,
                                                                              moduleId:
                                                                                  lesson
                                                                                      .module
                                                                                      .id,
                                                                          },
                                                                      ]
                                                                  }
                                                              )
                                                          }
                                                      }}
                                                  />
                                              ) : (
                                                  <IconButton
                                                      size="small"
                                                      color="secondary"
                                                      onClick={() =>
                                                          handleOpenLessonMenu(
                                                              lesson,
                                                              module
                                                          )
                                                      }
                                                  >
                                                      <MoreVert />
                                                  </IconButton>
                                              )}
                                          </Stack>
                                      )
                                  })
                          }
                          footer={
                              selectable
                                  ? undefined
                                  : () => {
                                        const loadingsForThisModule =
                                            loadingContent.filter(
                                                content =>
                                                    content.moduleId ===
                                                    module.id
                                            )

                                        return (
                                            <Stack divider={<Divider />}>
                                                {loadingsForThisModule.map(
                                                    renderLoadingItem
                                                )}

                                                {renderNewLessonButton(
                                                    module,
                                                    module.lessons.edges
                                                        .length === 0
                                                )}
                                            </Stack>
                                        )
                                    }
                          }
                          actions={[
                              ...(selectable
                                  ? []
                                  : [
                                        <IconButton
                                            key="more"
                                            color="secondary"
                                            onClick={() =>
                                                handleOpenModuleMenu(module)
                                            }
                                        >
                                            <MoreVert />
                                        </IconButton>,
                                    ]),
                          ]}
                      />
                  ))
                : renderEmptyCourseScreen()}
        </PageLayoutMobile>
    )
}

export default CoursePageMobile
