import React, { JSX } from 'react'

import { t } from 'i18next'
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import {
    KeyboardArrowRight,
    ThumbDownOffAlt,
    ThumbUpOffAlt,
} from '@mui/icons-material'

import { PageLayoutMobile, Paper } from '@backoffice/components/common'
import { Editor, SearchFieldMobile } from 'components'
import { HomeworkSubmissionType } from 'api/root/generated'
import { formatUploadedItemFromUrl } from 'utils/common.utils'
import { HomeworkSubmissionsPageProps } from '@backoffice/components/pages/HomeworkSubmissionsPage/index.types'

const HomeworkSubmissionsPageMobile = ({
    onApproveSubmission,
    onRejectSubmission,
    submissions,
    onBack,
    caption,
    onSubmissionClick,
    onSearch,
}: HomeworkSubmissionsPageProps): JSX.Element => {
    const renderSubmission = (submission: HomeworkSubmissionType) => {
        return (
            <Paper
                title={submission.client.email as string}
                actions={[
                    <IconButton
                        size="small"
                        color="error"
                        onClick={() => onRejectSubmission(submission.id)}
                    >
                        <ThumbDownOffAlt />
                    </IconButton>,
                    <IconButton
                        size="small"
                        color="success"
                        onClick={() => onApproveSubmission(submission.id)}
                    >
                        <ThumbUpOffAlt />
                    </IconButton>,
                ]}
                content={() => (
                    <Stack divider={<Divider />}>
                        <Stack
                            sx={{
                                minHeight: '100px',
                            }}
                        >
                            <Editor
                                editable={false}
                                content={submission.content}
                                uploadedFiles={formatUploadedItemFromUrl(
                                    submission.attachments || []
                                )}
                            />
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            padding="10px"
                        >
                            <Button
                                onClick={() => onSubmissionClick(submission)}
                                variant="text"
                                size="medium"
                                color="secondary"
                                endIcon={<KeyboardArrowRight />}
                            >
                                <Typography>Детальнiше</Typography>
                            </Button>
                        </Stack>
                    </Stack>
                )}
            />
        )
    }

    return (
        <PageLayoutMobile
            pageTitle={`${t('backoffice:homework.submissions.title')}`}
            onBack={onBack}
            title={t('backoffice:homework.submissions.title')}
            caption={caption}
            sx={{
                gap: '25px',
            }}
            toolbar={() => (
                <SearchFieldMobile
                    key="search"
                    sx={{
                        minWidth: '250px',
                    }}
                    light
                    placeholder={
                        t(
                            'backoffice:homework.submissions.emailSearch'
                        ) as string
                    }
                    onChange={onSearch}
                />
            )}
        >
            {submissions.map(renderSubmission)}
        </PageLayoutMobile>
    )
}

export default HomeworkSubmissionsPageMobile
