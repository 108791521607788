import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    TextField,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import { number, object, string } from 'yup'

import { Image } from 'components'
import monobankLogo from 'assets/images/monobank.png'
import { MonobankPayload } from '@backoffice/components/pages/SettingsPage/index.types'

export interface MonobankFormValues {
    paymentService: number
    payload: MonobankPayload
}

interface MonobankFormProps {
    paymentService: number
    onSubmit: (values: MonobankFormValues) => void
    onDelete: (values: MonobankFormValues) => void
    payload: MonobankPayload | null
    loading?: boolean
}

const MonobankForm = ({
    paymentService,
    onSubmit,
    loading,
    payload,
    onDelete,
}: MonobankFormProps): JSX.Element => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: {
            paymentService,
            payload: payload || {
                token: '',
            },
        },
        validationSchema: object({
            paymentService: number(),
            payload: object({
                token: string().required(
                    t('auth:errors.required-field') as string
                ),
            }),
        }),
        onSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                gap="25px"
                padding="25px"
                sx={{
                    maxWidth: '375px',
                    minWidth: 'min(80vw, 375px)',
                }}
            >
                <Typography
                    fontSize={tablet ? '20px' : '16px'}
                    textAlign={tablet ? 'left' : 'center'}
                >
                    {t('backoffice:settings.payment.forms.monobankTitle')}
                </Typography>
                {tablet && (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            boxShadow: '0 0 7px #000',
                            border: `2px solid #000`,
                            padding: '20px 10px',
                            background: '#e5e5e5',
                            position: 'relative',
                            marginLeft: '-24px',
                            marginRight: '-24px',
                        }}
                    >
                        <Image
                            src={monobankLogo}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Stack>
                )}
                <Stack gap="25px">
                    <TextField
                        placeholder={
                            t(
                                'backoffice:settings.payment.forms.monobankTokenLabel'
                            ) as string
                        }
                        variant="outlined"
                        fullWidth
                        name="payload.token"
                        value={formik.values.payload.token}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.token &&
                            Boolean(formik.errors?.payload?.token)
                        }
                        helperText={
                            formik.touched?.payload?.token &&
                            formik.errors?.payload?.token
                        }
                        sx={{
                            ...(!tablet && {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }),
                        }}
                    />
                </Stack>
                <Stack gap="10px">
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="success"
                        sx={{ padding: tablet ? '15px' : '10px' }}
                        loading={loading}
                    >
                        <Typography>
                            {t('backoffice:settings.payment.forms.save')}
                        </Typography>
                    </Button>
                    {payload && (
                        <Button
                            loading={loading}
                            sx={{ padding: '10px' }}
                            color="error"
                            variant="text"
                            fullWidth
                            onClick={() => onDelete(formik.values)}
                        >
                            <Typography>
                                {t('backoffice:settings.payment.forms.cancel')}
                            </Typography>
                        </Button>
                    )}
                </Stack>
            </Stack>
        </form>
    )
}
export default MonobankForm
