import React, { JSX, useEffect, useState } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useDialog, useDrawer } from 'hooks/common'
import { paths, paths as backofficePaths } from '@backoffice/index.routes'
import { CourseType, LessonType, ModuleType } from 'api/root/generated'
import { LoadingContent } from '@backoffice/components/common'
import useCourseEntityAPI from '@backoffice/hooks/course'
import { Loader } from 'components'
import { useAccess } from '@backoffice/hooks/accesses'
import { SelectedLessonType } from './components/CourseContent'
import {
    CourseForm,
    LessonForm,
    ModuleForm,
} from '@backoffice/components/forms'
import CoursePageDesktop from '@backoffice/components/pages/NewCoursePage/index.desktop'
import CoursePageMobile from '@backoffice/components/pages/NewCoursePage/index.mobile'
import useConfirmationDialog from '@backoffice/hooks/common/useConfirmationDialog'

const CoursePage = (): JSX.Element => {
    const navigate = useNavigate()
    const { courseId } = useParams()
    const [searchParams] = useSearchParams()
    const { enqueueSnackbar } = useSnackbar()
    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()

    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const {
        course,
        modules,
        grantAccess,
        deleteAccess,
        courseLoading,
        refetch,
        createLesson,
        removeCourse,
    } = useCourseEntityAPI(courseId)

    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const [selectedLessons, setSelectedLessons] = useState<
        SelectedLessonType[]
    >([])

    const [loadingContent, setLoadingContent] = useState<LoadingContent[]>([])

    const selectable = !!searchParams.get('selectable')
    const email = searchParams.get('email')
    const stream = searchParams.get('stream')
    const id = searchParams.get('id')
    const fastAccess = searchParams.get('fastAccess')

    const { access } = useAccess(courseId as string, id as string)

    useEffect(() => {
        if (access) {
            setSelectedLessons(
                access?.lessons.edges
                    .map(e => e?.node as LessonType)
                    .map(l => ({ lessonId: l.id, moduleId: l.module.id })) || []
            )
        }
    }, [access])

    if (courseLoading) {
        return <Loader />
    }

    const handleDeleteCourse = () => {
        removeCourse(
            {
                input: {
                    courseId: course?.id as string,
                },
            },
            course?.coach?.id as string
        ).then(response => {
            if (response?.success) {
                close()
                enqueueSnackbar(
                    t('backoffice:content.course.notifications.successDeleted'),
                    { variant: 'success' }
                )
                navigate(`../${backofficePaths.content}`)
            }
        })
    }

    const handleEditCourse = () => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer

        openModal({
            component: CourseForm,
            props: {
                onSuccess: (action: 'add' | 'update') => {
                    closeModal()
                    refetch()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
                onDelete: () => {
                    closeModal()
                    requestConfirmation(
                        course as CourseType,
                        'course',
                        handleDeleteCourse
                    )
                },
                course,
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleDeleteAccess = () => {
        deleteAccess({
            input: {
                courseAccessId: access?.id as string,
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar(
                    t(
                        'backoffice:access.grant.notifications.accessDeleteSuccess'
                    ),
                    { variant: 'success' }
                )
                navigate(`../${backofficePaths.client(id as string)}?tab=2`)
            }
        })
    }

    const handleGrantAccess = () => {
        grantAccess(
            {
                input: {
                    ...(stream && {
                        streamId: stream,
                    }),
                    courseId: course?.id as string,
                    lessonIds:
                        (selectedLessons.map(sl => sl.lessonId) as string[]) ||
                        [],
                    clientEmails: [searchParams.get('email') as string],
                    redirectTo: `${window.location.origin}/${course?.id}`,
                },
            },
            id as string
        ).then(response => {
            if (response?.success) {
                enqueueSnackbar(
                    t(
                        'backoffice:access.grant.notifications.accessCreateSuccess'
                    ),
                    { variant: 'success' }
                )
                navigate(
                    `../${backofficePaths.client(
                        response?.courseAccess?.client?.id as string
                    )}?tab=2`
                )
            }
        })
    }

    const handleAddNewModule = () => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer

        openModal({
            component: ModuleForm,
            props: {
                course,
                onSuccess: (action: 'add' | 'update') => {
                    closeModal()
                    refetch()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.module.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.module.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleAddNewLesson = (module: ModuleType) => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer
        openModal({
            component: LessonForm,
            props: {
                module,
                onCreateNew: (newLesson: any) => {
                    const { preview, ...rest } = newLesson
                    closeModal()
                    setLoadingContent(prevState => [
                        ...prevState,
                        {
                            image: preview,
                            name: newLesson.name,
                            status: 'IN_PROGRESS',
                            moduleId: module.id,
                        },
                    ])
                    createLesson({
                        input: rest,
                    }).then(() => {
                        refetch().then(() => {
                            setLoadingContent(prevState =>
                                prevState.filter(
                                    lc => lc.name !== newLesson.name
                                )
                            )
                        })
                    })
                },
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const resolvePageCaption = () => {
        return selectable
            ? id
                ? t('backoffice:course.clientAccessesFor', {
                      email,
                  })
                : stream
                ? t('backoffice:course.emailAccessIsBeingGivenToWithStream', {
                      email,
                  })
                : t('backoffice:course.emailAccessIsBeingGivenTo', {
                      email,
                  })
            : t('backoffice:content.course.caption')
    }

    const handleBack = () => {
        if (fastAccess) {
            navigate(`../${paths.backoffice}`)
        } else {
            navigate(
                id
                    ? `../${backofficePaths.client(id as string)}?tab=2`
                    : `../${backofficePaths.content}`
            )
        }
    }

    const handleGoToStreams = () => {
        navigate(`../${backofficePaths.courseStream(courseId as string)}`)
    }

    return tablet ? (
        <CoursePageDesktop
            loadingContent={loadingContent}
            course={course as CourseType}
            modules={modules as ModuleType[]}
            onBack={handleBack}
            caption={resolvePageCaption()}
            onGrantAccess={handleGrantAccess}
            onAddLesson={handleAddNewLesson}
            onAddModule={handleAddNewModule}
            onDeleteAccess={handleDeleteAccess}
            selectable={selectable}
            onSelectLesson={setSelectedLessons}
            selectedLessons={selectedLessons}
            params={{
                id: id as string,
                email: email as string,
            }}
        />
    ) : (
        <CoursePageMobile
            loadingContent={loadingContent}
            course={course as CourseType}
            modules={modules as ModuleType[]}
            onBack={handleBack}
            caption={selectable ? email : resolvePageCaption()}
            onGrantAccess={handleGrantAccess}
            onAddLesson={handleAddNewLesson}
            onAddModule={handleAddNewModule}
            onDeleteAccess={handleDeleteAccess}
            selectable={selectable}
            onSelectLesson={setSelectedLessons}
            selectedLessons={selectedLessons}
            onEditCourse={handleEditCourse}
            onStreamsClick={handleGoToStreams}
            params={{
                id: id as string,
                email: email as string,
            }}
        />
    )
}

export default CoursePage
