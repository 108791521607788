import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    HomeworkSubmissionApproveDocument,
    HomeworkSubmissionApproveMutation,
    HomeworkSubmissionCreateDocument,
    HomeworkSubmissionCreateMutation,
    HomeworkSubmissionDetailsDocument,
    HomeworkSubmissionListDocument,
    HomeworkSubmissionRejectDocument,
    HomeworkSubmissionRejectMutation,
    HomeworkSubmissionType,
    HomeworkSubmissionUpdateDocument,
    HomeworkSubmissionUpdateMutation,
    MutationHomeworkSubmissionApproveArgs,
    MutationHomeworkSubmissionCreateArgs,
    MutationHomeworkSubmissionRejectArgs,
    MutationHomeworkSubmissionUpdateArgs,
    SubmissionCommentCreateInput,
    SubmissionCommentCreateDocument,
    MutationSubmissionCommentCreateArgs,
    SubmissionCommentCreateMutation,
    LatestHomeworkSubmissionListDocument,
} from 'api/root/generated'

export const useHomeworkSubmissions = (
    id: string,
    email?: string,
    unique = false
) => {
    const [response, setResponse] = useState<HomeworkSubmissionType[]>([])

    const { data, loading, error, refetch } = useQuery(
        HomeworkSubmissionListDocument,
        {
            variables: {
                homeworkId: id,
                ...(email && {
                    email,
                }),
                unique,
            },
        }
    )

    useEffect(() => {
        if (data?.homeworkSubmissionList) {
            const courses: HomeworkSubmissionType[] =
                data?.homeworkSubmissionList.edges.map(
                    edge => edge?.node as HomeworkSubmissionType
                )
            setResponse(courses)
        }
    }, [data])

    return {
        homeworkSubmissions: response,
        loading,
        error,
        refetch,
    }
}

export const useLatestHomeworkSubmissions = (variables = {}) => {
    const [response, setResponse] = useState<HomeworkSubmissionType[]>([])

    const { data, loading, error, refetch } = useQuery(
        LatestHomeworkSubmissionListDocument,
        {
            variables,
        }
    )

    useEffect(() => {
        if (data?.latestHomeworkSubmissionList) {
            const courses: HomeworkSubmissionType[] =
                data?.latestHomeworkSubmissionList.edges.map(
                    edge => edge?.node as HomeworkSubmissionType
                )
            setResponse(courses)
        }
    }, [data])

    return {
        latestHomeworkSubmissions: response,
        loading,
        error,
        refetch,
    }
}

export const useHomeworkSubmissionDetails = (id?: string) => {
    const { data, loading, error, refetch } = useQuery(
        HomeworkSubmissionDetailsDocument,
        {
            ...(id && {
                variables: {
                    id,
                },
            }),
            skip: !id,
        }
    )

    let homeworkSubmission

    if (data?.homeworkSubmissionDetails) {
        homeworkSubmission = data.homeworkSubmissionDetails
    }

    return {
        homeworkSubmission,
        loading: loading || typeof data === 'undefined',
        error,
        refetch,
    }
}

export const useHomeworkSubmissionsAPI = () => {
    const [updateSubmission, { loading: updateLoading }] = useMutation(
        HomeworkSubmissionUpdateDocument
    )
    const [createSubmission, { loading: createLoading }] = useMutation(
        HomeworkSubmissionCreateDocument
    )
    const [rejectSubmission, { loading: rejectLoading }] = useMutation(
        HomeworkSubmissionRejectDocument
    )

    const [approveSubmission, { loading: approveLoading }] = useMutation(
        HomeworkSubmissionApproveDocument
    )

    const [createComment, { loading: creatingCommentLoading }] = useMutation(
        SubmissionCommentCreateDocument
    )

    const update = (data: MutationHomeworkSubmissionUpdateArgs) => {
        return updateSubmission({ variables: data }).then(
            (response: FetchResult<HomeworkSubmissionUpdateMutation>) => {
                return response?.data?.homeworkSubmissionUpdate
            }
        )
    }

    const postComment = (data: MutationSubmissionCommentCreateArgs) => {
        return createComment({ variables: data }).then(
            (response: FetchResult<SubmissionCommentCreateMutation>) => {
                return response?.data?.submissionCommentCreate
            }
        )
    }

    const create = (data: MutationHomeworkSubmissionCreateArgs) => {
        return createSubmission({ variables: data }).then(
            (response: FetchResult<HomeworkSubmissionCreateMutation>) => {
                return response?.data?.homeworkSubmissionCreate
            }
        )
    }

    const reject = (data: MutationHomeworkSubmissionRejectArgs) => {
        return rejectSubmission({ variables: data }).then(
            (response: FetchResult<HomeworkSubmissionRejectMutation>) => {
                return response?.data?.homeworkSubmissionReject
            }
        )
    }

    const approve = (data: MutationHomeworkSubmissionApproveArgs) => {
        return approveSubmission({ variables: data }).then(
            (response: FetchResult<HomeworkSubmissionApproveMutation>) => {
                return response?.data?.homeworkSubmissionApprove
            }
        )
    }

    return {
        update,
        create,
        reject,
        approve,
        postComment,
        loading:
            createLoading ||
            updateLoading ||
            rejectLoading ||
            approveLoading ||
            creatingCommentLoading,
    }
}
