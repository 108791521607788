import React, { useState } from 'react'

import { StaticDateTimePicker, DateOrTimeView } from '@mui/x-date-pickers'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { CheckOutlined, DeleteOutline } from '@mui/icons-material'
import { format } from 'date-fns-tz'

interface DatepickerMobileProps {
    value: Date | null
    onChange: (date: Date | null) => void
    minDate?: Date
}

const DatepickerMobile = ({
    value,
    onChange,
    minDate,
}: DatepickerMobileProps) => {
    const [date, setDate] = useState<Date | null>(value || new Date())
    const [view, setView] = useState<DateOrTimeView>('day')

    const parseDate = (date: Date | null, view: DateOrTimeView) => {
        if (!date) {
            return 'Дата'
        }

        if (view === 'day') {
            return format(date, 'dd.MM.yyyy', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        }
        if (view === 'hours') {
            return format(date, 'HH', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        }

        return format(date, 'mm', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
    }

    return (
        <Stack
            divider={<Divider />}
            sx={{
                background: '#424242',
            }}
        >
            <Stack
                padding="15px"
                gap="10px"
                direction="row"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    onClick={() => setView('day')}
                    sx={{
                        borderRadius: '4px',
                        padding: '5px 10px',
                        background: '#181818',
                        fontSize: '16px',
                        lineHeight: '18px',
                        color: view === 'day' ? 'primary.main' : 'white',
                    }}
                >
                    {parseDate(date, 'day')}
                </Typography>
                <Stack
                    direction="row"
                    sx={{
                        borderRadius: '4px',
                        padding: '5px 10px',
                        background: '#181818',
                    }}
                >
                    <Typography
                        onClick={() => setView('hours')}
                        sx={{
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: view === 'hours' ? 'primary.main' : 'white',
                        }}
                    >
                        {parseDate(date, 'hours')}
                    </Typography>
                    <Typography
                        onClick={() => setView('hours')}
                        sx={{
                            fontSize: '16px',
                            lineHeight: '18px',
                            color: 'white',
                        }}
                    >
                        :
                    </Typography>
                    <Typography
                        onClick={() => setView('minutes')}
                        sx={{
                            fontSize: '16px',
                            lineHeight: '18px',
                            color:
                                view === 'minutes' ? 'primary.main' : 'white',
                        }}
                    >
                        {parseDate(date, 'minutes')}
                    </Typography>
                </Stack>
            </Stack>
            <StaticDateTimePicker
                view={view}
                slotProps={{
                    tabs: {
                        hidden: true,
                    },
                    toolbar: {
                        hidden: true,
                    },
                    actionBar: {
                        hidden: true,
                    },
                }}
                onChange={v => setDate(v)}
                ampm={false}
                value={value}
                minDate={minDate}
                onViewChange={v => setView(v)}
                sx={{
                    background: '#424242',
                    '& .MuiPickersLayout-actionBar': {
                        padding: '8px 15px',
                    },
                    '& .MuiDialogActions-root': {
                        display: 'none',
                    },
                }}
            />
            <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
            >
                <Button
                    fullWidth
                    color="error"
                    variant="text"
                    onClick={() => onChange(null)}
                    startIcon={<DeleteOutline />}
                    sx={{
                        borderRadius: '0',
                        padding: '15px 25px',
                    }}
                >
                    <Typography>Видалити</Typography>
                </Button>

                <Button
                    disabled={value === date}
                    endIcon={<CheckOutlined />}
                    fullWidth
                    variant="text"
                    color="success"
                    onClick={() => onChange(date)}
                    sx={{
                        borderRadius: '0',
                        padding: '15px 25px',
                    }}
                >
                    <Typography>Зберегти</Typography>
                </Button>
            </Stack>
        </Stack>
    )
}

export default DatepickerMobile
