import React, { JSX } from 'react'

import { Box, Stack, SwipeableDrawer, SxProps } from '@mui/material'

export interface DrawerProps {
    open?: boolean
    onClose: () => void
    children?: JSX.Element
    sx?: SxProps
}

const Drawer = (props: DrawerProps): JSX.Element => {
    const { children, onClose, open = true, sx = {} } = props

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onOpen={() => {}}
            onClose={onClose}
            sx={sx}
        >
            <Stack
                direction="row"
                padding="5px"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    sx={{
                        width: '40px',
                        height: '8px',
                        borderRadius: '4px',
                        background: '#090909',
                    }}
                />
            </Stack>
            {children}
        </SwipeableDrawer>
    )
}

export default Drawer
