import React from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { useMe } from 'hooks/user'
import { UserType } from 'api/root/generated'
import { useDialog, useDrawer } from 'hooks/common'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { Loader } from 'components'
import SettingsPageDesktop from '@backoffice/components/pages/SettingsPage/index.desktop'
import SettingsPageMobile from '@backoffice/components/pages/SettingsPage/index.mobile'
import {
    LiqPayForm,
    LiqPayFormValues,
    MonobankForm,
    MonobankFormValues,
    StripeForm,
    StripeFormValues,
    WayForPayForm,
    WayForPayFormValues,
} from '@backoffice/components/pages/SettingsPage/components/modals'
import { usePaymentAPI } from 'hooks/payment'
import { PAYMENT_SERVICE } from 'utils/payment'
import {
    PaymentServices,
    WayForPayPayload,
    MonobankPayload,
    LiqPayPayload,
    StripePayload,
} from './index.types'
import { SocialsForm } from '@backoffice/components/forms'
import { SocialsFormValues } from '@backoffice/components/forms/SocialsForm/index.schema'

const components = {
    [PaymentServices.WAYFORPAY]: WayForPayForm,
    [PaymentServices.MONOBANK]: MonobankForm,
    [PaymentServices.STRIPE]: StripeForm,
    [PaymentServices.LIQPAY]: LiqPayForm,
}

const SettingsPage = () => {
    const navigate = useNavigate()

    const theme = useTheme()

    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const { enqueueSnackbar } = useSnackbar()
    const { setPaymentMethod, removePaymentMethod } = usePaymentAPI()
    const { me, loading, refetch } = useMe()
    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()

    if (loading || !me) {
        return <Loader />
    }

    const handleDeletePaymentMethod = ({
        paymentService,
    }:
        | WayForPayFormValues
        | MonobankFormValues
        | StripeFormValues
        | LiqPayFormValues) => {
        const closeModal = tablet ? close : closeDrawer

        removePaymentMethod({
            input: {
                paymentService,
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar('payment method removed', {
                    variant: 'success',
                })
                refetch()
                closeModal()
            }
        })
    }

    const handleSetUpPaymentMethod = ({
        payload,
        paymentService,
    }:
        | WayForPayFormValues
        | MonobankFormValues
        | StripeFormValues
        | LiqPayFormValues) => {
        const closeModal = tablet ? close : closeDrawer

        setPaymentMethod({
            input: {
                paymentService,
                payload: JSON.stringify(payload),
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar('payment method set up', { variant: 'success' })
                refetch()
                closeModal()
            }
        })
    }

    const handleOpenPaymentModal = (type: PaymentServices) => {
        const component = components[type]
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer

        openModal({
            component,
            props: {
                payload: me?.paymentMethods?.[type]?.payload
                    ? JSON.parse(me?.paymentMethods?.[type]?.payload)
                    : null,
                paymentService: PAYMENT_SERVICE[type],
                onSubmit: handleSetUpPaymentMethod,
                onDelete: handleDeletePaymentMethod,
                onClose: closeModal,
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleOpenSocialsModal = () => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer
        openModal({
            component: SocialsForm,
            props: {
                user: me,
                onSubmit: (values: SocialsFormValues) => {
                    closeModal()
                },
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleBack = () => navigate(`../${backofficePaths.backoffice}`)

    return tablet ? (
        <SettingsPageDesktop
            onEditSocialsClick={handleOpenSocialsModal}
            onPaymentMethodClick={handleOpenPaymentModal}
            onSetupPaymentMethod={handleSetUpPaymentMethod}
            onBack={handleBack}
            data={me as UserType}
        />
    ) : (
        <SettingsPageMobile
            onEditSocialsClick={handleOpenSocialsModal}
            onPaymentMethodClick={handleOpenPaymentModal}
            onSetupPaymentMethod={handleSetUpPaymentMethod}
            onBack={handleBack}
            data={me as UserType}
        />
    )
}
export default SettingsPage
