import React, { JSX, useState } from 'react'

import {
    Button,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { Add, AddCircleOutline, CallSplitRounded } from '@mui/icons-material'
import { t } from 'i18next'

import { CourseStreamType, CourseType } from 'api/root/generated'
import { Loader } from 'components'
import { PageLayout } from '@backoffice/components/common'
import Calendar from './components/Calendar'
import { CourseStreamPageProps } from './index.types'

const CourseStreamPageDesktop = ({
    loading,
    selectedStream,
    onSelectStream,
    onBack,
    onAdd,
    streams,
    onChange,
    course,
}: CourseStreamPageProps): JSX.Element => {
    const [selectedDate, setSelectedDate] = useState<string | null>(null)

    const renderNoStreamSelected = () => (
        <Stack
            alignItems="center"
            justifyContent="center"
            gap="25px"
            paddingTop="10%"
        >
            <CallSplitRounded
                sx={{
                    color: 'rgba(255, 255, 255, .2)',
                    fontSize: '55px',
                }}
            />
            <Typography
                sx={theme => ({ color: theme.palette.secondary.dark })}
                fontSize="max(min(1vw, 26px), 15px)"
            >
                {t('backoffice:stream.noStream')}
            </Typography>
            <Button
                onClick={onAdd}
                sx={() => ({
                    width: 'fit-content',
                    '& .MuiSvgIcon-root': {
                        fontSize: '26px',
                    },
                })}
                variant="text"
                color="secondary"
                startIcon={<AddCircleOutline />}
            >
                <Typography fontSize="22px">
                    {t('backoffice:stream.actions.createFirst')}
                </Typography>
            </Button>
        </Stack>
    )

    if (loading) {
        return <Loader />
    }

    return (
        <PageLayout
            pageTitle={`${t('backoffice:pageTitles.streamPage')} | ${
                course?.name
            }`}
            onBack={onBack}
            title={
                t('backoffice:stream.title', {
                    name: course?.name,
                }) as string
            }
            caption={t('backoffice:stream.caption') as string}
            actions={
                streams?.length > 0
                    ? [
                          <TextField
                              key="select-stream"
                              label={t('backoffice:stream.actions.selectLabel')}
                              variant="outlined"
                              select
                              value={selectedStream?.id || ''}
                              onChange={e =>
                                  onSelectStream(
                                      streams.find(
                                          s => s.id === e.target.value
                                      ) as CourseStreamType
                                  )
                              }
                              sx={{
                                  flexGrow: '1',
                                  width: '350px',
                                  '& .MuiFormLabel-root': {
                                      color: '#6e6e6e',
                                      background: 'transparent',
                                  },
                              }}
                          >
                              {streams.map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                      {option.name}
                                  </MenuItem>
                              ))}
                          </TextField>,
                          <Tooltip
                              key="create"
                              title={
                                  <Typography>
                                      {t(
                                          'backoffice:content.course.commonActions.create'
                                      )}
                                  </Typography>
                              }
                          >
                              <IconButton
                                  color="primary"
                                  size="large"
                                  onClick={onAdd}
                              >
                                  <Add />
                              </IconButton>
                          </Tooltip>,
                      ]
                    : []
            }
        >
            {selectedStream && course ? (
                <Calendar
                    onSelectDate={setSelectedDate}
                    selectedDate={selectedDate}
                    stream={selectedStream}
                    course={course as CourseType}
                    onSaveLessonData={onChange}
                />
            ) : (
                renderNoStreamSelected()
            )}
        </PageLayout>
    )
}
export default CourseStreamPageDesktop
