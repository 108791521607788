import React, { JSX } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Stack,
    Typography,
    TextField,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'

import { CourseStreamFormScheme, CourseStreamFormValues } from './index.schema'
import { CourseStreamType } from 'api/root/generated'
import { useStreamsAPI } from '@backoffice/hooks/streams'

interface CourseStreamFormProps {
    stream?: CourseStreamType
    courseId: string
    onSuccess?: () => void
}

const CourseStreamForm = ({
    stream,
    courseId,
    onSuccess,
}: CourseStreamFormProps): JSX.Element => {
    const { create, update, loading } = useStreamsAPI()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: stream
            ? { name: stream.name }
            : {
                  name: '',
              },
        validationSchema: CourseStreamFormScheme,
        onSubmit: (values: CourseStreamFormValues) => {
            if (stream) {
                update({
                    input: {
                        streamId: stream.id,
                        schedule: stream.schedule,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.()
                    }
                })
            } else {
                create({
                    input: {
                        courseId,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.()
                    }
                })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                ...(tablet && {
                    maxWidth: '500px',
                }),
                minWidth: 'min(80vw, 500px)',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize={tablet ? '20px' : '16px'}
                            textAlign={tablet ? 'left' : 'center'}
                        >
                            {stream
                                ? t('backoffice:stream.form.saveStream')
                                : t('backoffice:stream.form.createStream')}
                        </Typography>
                    </Stack>
                    <TextField
                        fullWidth
                        autoComplete="off"
                        name="name"
                        variant="outlined"
                        placeholder={
                            t('backoffice:stream.form.streamName') as string
                        }
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                        sx={{
                            ...(!tablet && {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }),
                        }}
                    />
                    <Button
                        color="success"
                        loading={loading}
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ padding: '15px' }}
                    >
                        <Typography>
                            {stream
                                ? t('backoffice:stream.form.saveStream')
                                : t('backoffice:stream.form.createStream')}
                        </Typography>
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}

export default CourseStreamForm
