import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import { ROLES } from 'config/user.config'
import {
    MeDocument,
    MutationUserUpdateArgs,
    UserType,
    UserUpdateDocument,
    UserUpdateMutation,
} from 'api/root/generated'
import { extractSubdomain } from '../utils/common.utils'

export const useMe = () => {
    const [response, setResponse] = useState<UserType | null>(null)
    const [role, setRole] = useState<ROLES | null>(ROLES.GUEST)

    const { data, loading, error, refetch } = useQuery(MeDocument, {
        skip: !localStorage.getItem('authenticated'),
    })

    useEffect(() => {
        if (data && !loading) {
            if (data?.me) {
                const currentSubdomain = extractSubdomain(window.location)
                setResponse(data?.me as UserType)
                setRole(
                    currentSubdomain === data?.me?.subdomain
                        ? ROLES.COACH
                        : ROLES.CLIENT
                )
            } else {
                localStorage.removeItem('authenticated')
                setRole(ROLES.GUEST)
            }
        }
    }, [data, loading])

    return {
        me: response,
        role,
        loading,
        error,
        refetch,
    }
}

export const useUsersAPI = () => {
    const [updateUser, { loading }] = useMutation(UserUpdateDocument)

    const update = (data: MutationUserUpdateArgs) => {
        return updateUser({ variables: data }).then(
            (response: FetchResult<UserUpdateMutation>) => {
                return response?.data?.userUpdate
            }
        )
    }

    return {
        update,
        updateLoading: loading,
    }
}
