import React, { JSX } from 'react'

import { Typography, Stack, IconButton, Skeleton } from '@mui/material'
import { t } from 'i18next'
import { Add } from '@mui/icons-material'

import { Image } from 'components'
import { PageLayoutMobile, Paper } from '@backoffice/components/common'
import { CourseType } from 'api/root/generated'
import noBackground from 'assets/images/no-background.png'
import { ContentPageProps } from './index.types'
import { CourseForm } from '@backoffice/components/forms'
import { useDrawer } from 'hooks/common'

const ContentPageMobile = ({
    courses,
    loading,
    onClick,
    onBack,
    onAdd,
}: ContentPageProps): JSX.Element => {
    const renderPlaceholder = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                padding="10px 5px"
            >
                <Skeleton
                    variant="rectangular"
                    width="32px"
                    sx={{
                        height: '32px',
                        borderRadius: '4px',
                    }}
                />
                <Skeleton sx={{ flexGrow: 1 }} />
            </Stack>
        )
    }

    return (
        <PageLayoutMobile
            pageTitle={t('backoffice:pageTitles.contentPage')}
            title={t('backoffice:content.title') as string}
            onBack={onBack}
            actions={[
                <IconButton
                    key="add"
                    size="medium"
                    color="primary"
                    onClick={onAdd}
                >
                    <Add />
                </IconButton>,
            ]}
        >
            <Paper
                title="Курси"
                content={() =>
                    courses?.map((course: CourseType) => {
                        return loading ? (
                            renderPlaceholder()
                        ) : (
                            <Stack
                                key={course.id}
                                direction="row"
                                alignItems="center"
                                gap="15px"
                                padding="10px 5px"
                                onClick={() => onClick(course.id)}
                            >
                                <Image
                                    sx={{
                                        minWidth: '32px',
                                        width: '32px',
                                        height: '32px',
                                        objectFit: 'cover',
                                        borderRadius: '4px',
                                    }}
                                    src={
                                        course.appearance?.background
                                            ?.img150x100 || noBackground
                                    }
                                />
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                    }}
                                >
                                    {course.name}
                                </Typography>
                            </Stack>
                        )
                    })
                }
            />
        </PageLayoutMobile>
    )
}
export default ContentPageMobile
