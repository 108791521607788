import { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

import { LoginForm } from '../../forms'
import { paths } from '@auth/index.routes'
import { Loader } from 'components'
import { useAuth } from '@auth/hooks/auth'
import { useTokenVerification, Credentials } from '@auth/hooks/verification'
import { UserType } from 'api/auth/generated'
import { extractSubdomain } from 'utils/common.utils'
import { useDocumentTitle } from 'hooks/common'

const LoginPage = () => {
    useDocumentTitle(t('auth:pageTitles.login'))

    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const { confirmEmail } = useAuth()

    const { enqueueSnackbar } = useSnackbar()

    const [credentials, setCredentials] = useState<Credentials | undefined>()
    const [isLoading, setLoading] = useState(true)

    const redirectTo = searchParams.get('redirectTo')
    const tokenHasExpired = searchParams.get('expired')

    useTokenVerification({
        uid: searchParams.get('uid64'),
        token: searchParams.get('token'),
        onSuccess: (params: Credentials) => {
            if (searchParams.get('uid64') || searchParams.get('token')) {
                confirmEmail(params).then(response => {
                    if (response?.success) {
                        enqueueSnackbar(
                            'Вашу електронну адресу успiшно активовано',
                            { variant: 'success' }
                        )
                        setCredentials(params)
                    }
                })
            } else {
                setCredentials(params)
            }
        },
        onFinish: () => setLoading(false),
        skip: !searchParams.get('uid64') || !searchParams.get('token'),
    })

    if (isLoading) {
        return <Loader />
    }

    const handleSuccessLogin = (user: UserType) => {
        if (redirectTo) {
            const isBackofficePage = redirectTo.includes('backoffice')

            if (isBackofficePage) {
                if (user.subdomain === extractSubdomain(window.location)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.location.replace(redirectTo)
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.location.replace(window.location.origin)
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.location.replace(redirectTo)
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.location.replace(window.location.origin)
        }
    }

    const renderExpiredLayout = () => {
        return (
            <Typography fontSize="18px" color="primary" textAlign="center">
                Це посилання вже непридатне ;(
            </Typography>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                padding: '25px',
            }}
        >
            {credentials ? (
                <LoginForm
                    tokenHasExpired={!!tokenHasExpired}
                    onForgotPassword={() =>
                        navigate(
                            `../${paths.forgotPassword}?redirectTo=${redirectTo}`
                        )
                    }
                    onSuccess={handleSuccessLogin}
                />
            ) : (
                renderExpiredLayout()
            )}
        </Box>
    )
}

export default LoginPage
