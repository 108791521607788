import React, { JSX, useRef, useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { FormikProps } from 'formik'

import { useHomeworksAPI, useHomework } from '@backoffice/hooks/homeworks'
import {
    HomeworkCreateInput,
    HomeworkType,
    HomeworkUpdateInput,
} from 'api/root/generated'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { HOMEWORK_CATEGORIES } from 'config/homework.config'
import CreateHomeworkPageDesktop from './index.desktop'
import CreateHomeworkPageMobile from './index.mobile'
import { HomeworkFormValues } from '@backoffice/components/forms/HomeworkForm/index.schema'

const HomeworkPage = (): JSX.Element => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const [searchParams, setSearchParams] = useSearchParams()

    const category = searchParams.get('category')
    const relationId = searchParams.get('relationId')
    const homeworkId = searchParams.get('homeworkId')
    const title = searchParams.get('title')

    const navigate = useNavigate()
    const {
        create,
        update,
        remove,
        loading: mutationLoading,
    } = useHomeworksAPI()
    const { enqueueSnackbar } = useSnackbar()

    const { homework } = useHomework(homeworkId || undefined)

    const formikRef = useRef<FormikProps<HomeworkFormValues> | null>(null)

    const [editable, setEditable] = useState(homeworkId === 'undefined')

    const onCreateHomework = (values: HomeworkCreateInput) => {
        create({
            input: {
                ...values,
                relationId: relationId as string,
                category:
                    category === 'lesson'
                        ? HOMEWORK_CATEGORIES.LESSON
                        : HOMEWORK_CATEGORIES.MODULE,
            },
        })
            .then(response => {
                if (response?.homework) {
                    enqueueSnackbar(
                        t('backoffice:homework.notifications.successCreated'),
                        { variant: 'success' }
                    )
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.set('homeworkId', response.homework.id)
                    setSearchParams(newSearchParams)
                    setEditable(false)
                } else {
                    enqueueSnackbar(
                        t('backoffice:homework.notifications.errorGeneric'),
                        { variant: 'error' }
                    )
                }
            })
            .catch(() => {
                enqueueSnackbar(
                    t('backoffice:homework.notifications.errorGeneric'),
                    { variant: 'error' }
                )
            })
    }

    const onDeleteHomework = () => {
        remove({
            input: {
                homeworkId: homeworkId as string,
            },
        })
            .then(response => {
                if (response?.success) {
                    enqueueSnackbar(
                        t('backoffice:homework.notifications.successDeleted'),
                        { variant: 'success' }
                    )
                    navigate(-1)
                } else {
                    enqueueSnackbar(
                        t('backoffice:homework.notifications.errorGeneric'),
                        { variant: 'error' }
                    )
                }
            })
            .catch(() => {
                enqueueSnackbar(
                    t('backoffice:homework.notifications.errorGeneric'),
                    { variant: 'error' }
                )
            })
    }

    const onEditHomework = (values: HomeworkUpdateInput) => {
        update({
            input: {
                ...values,
                homeworkId: homeworkId as string,
            },
        })
            .then(response => {
                if (response?.homework) {
                    enqueueSnackbar(
                        t('backoffice:homework.notifications.successUpdated'),
                        { variant: 'success' }
                    )
                    setEditable(false)
                } else {
                    enqueueSnackbar(
                        t('backoffice:homework.notifications.errorGeneric'),
                        { variant: 'error' }
                    )
                }
            })
            .catch(() => {
                enqueueSnackbar(
                    t('backoffice:homework.notifications.errorGeneric'),
                    { variant: 'error' }
                )
            })
    }

    const handleBack = () => {
        if (homework?.courseId) {
            navigate(`../${backofficePaths.course(homework.courseId)}`)
        } else {
            navigate(-1)
        }
    }
    const handleGoToSubmissions = () =>
        navigate(
            `../${backofficePaths.homeworkSubmissions(homeworkId as string)}`
        )

    return tablet ? (
        <CreateHomeworkPageDesktop
            homework={homework as HomeworkType}
            caption={title || homework?.title}
            creation={homeworkId === 'undefined'}
            loading={mutationLoading}
            onCreate={onCreateHomework}
            onEdit={onEditHomework}
            onDelete={onDeleteHomework}
            editable={editable}
            onChangeEditable={() => setEditable(prevState => !prevState)}
            formikRef={formikRef}
            onBack={handleBack}
            onSubmissionsClick={handleGoToSubmissions}
        />
    ) : (
        <CreateHomeworkPageMobile
            creation={homeworkId === 'undefined'}
            homework={homework as HomeworkType}
            loading={mutationLoading}
            onCreate={onCreateHomework}
            onEdit={onEditHomework}
            onDelete={onDeleteHomework}
            editable={editable}
            onChangeEditable={() => setEditable(prevState => !prevState)}
            formikRef={formikRef}
            onBack={handleBack}
            caption={title || homework?.title}
            onSubmissionsClick={handleGoToSubmissions}
        />
    )
}

export default HomeworkPage
