import React from 'react'

import { Button, IconButton, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import {
    AddOutlined,
    EditOutlined,
    Launch,
    SettingsOutlined,
} from '@mui/icons-material'
import { th } from 'date-fns/locale'

import { PageLayoutMobile, Paper } from '@backoffice/components/common'
import { PaymentServices, SettingsPageProps } from './index.types'

const SettingsPageMobile = ({
    data,
    onBack,
    onPaymentMethodClick,
    onEditSocialsClick,
}: SettingsPageProps) => {
    const mainFields = [
        {
            label: t('backoffice:settings.profile.subdomainLabel'),
            value: () => (
                <Button
                    variant="text"
                    size="small"
                    endIcon={
                        <EditOutlined
                            sx={theme => ({
                                color: theme.palette.secondary.dark,
                            })}
                        />
                    }
                    sx={theme => ({
                        color: 'white',
                        padding: 0,
                    })}
                >
                    <Typography>
                        <Typography
                            variant="caption"
                            sx={theme => ({
                                color: theme.palette.primary.main,
                                fontSize: '14px',
                                lineHeight: '16px',
                            })}
                        >
                            {data.subdomain}
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{
                                color: 'rgba(255, 255, 255, 0.6)',
                                fontSize: '14px',
                                lineHeight: '16px',
                            }}
                        >
                            .sippincup.com
                        </Typography>
                    </Typography>
                </Button>
            ),
        },

        {
            label: t('backoffice:settings.account.emailLabel'),
            value: () => (
                <Button
                    variant="text"
                    size="small"
                    endIcon={
                        <EditOutlined
                            sx={theme => ({
                                color: theme.palette.secondary.dark,
                            })}
                        />
                    }
                    sx={theme => ({
                        color: 'white',
                        padding: 0,
                        fontSize: '14px',
                    })}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: '14px',
                            lineHeight: '16px',
                        }}
                    >
                        {data.email}
                    </Typography>
                </Button>
            ),
        },
        {
            label: t('backoffice:settings.account.passwordLabel'),
            value: () => (
                <Button
                    variant="text"
                    size="small"
                    endIcon={
                        <EditOutlined
                            sx={theme => ({
                                color: theme.palette.secondary.dark,
                            })}
                        />
                    }
                    sx={theme => ({
                        color: 'white',
                        padding: 0,
                        fontSize: '14px',
                    })}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: '14px',
                            lineHeight: '16px',
                        }}
                    >
                        --------
                    </Typography>
                </Button>
            ),
        },
        {
            label: t('backoffice:settings.account.selectedTariffLabel'),
            value: () => (
                <Button
                    variant="text"
                    size="small"
                    endIcon={
                        <Launch
                            sx={{
                                color: '#d6bf62',
                            }}
                        />
                    }
                    sx={theme => ({
                        padding: 0,
                        fontSize: '14px',
                        color: '#d6bf62',
                    })}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            fontSize: '14px',
                            lineHeight: '16px',
                        }}
                    >
                        {t(`backoffice:settings.account.tariffs.pro`)}
                    </Typography>
                </Button>
            ),
        },
    ]

    const paymentFields = [
        {
            label: t('backoffice:settings.payment.wayForPayLabel'),
            value: data?.paymentMethods?.[PaymentServices.WAYFORPAY],
            type: PaymentServices.WAYFORPAY,
        },
        {
            label: t('backoffice:settings.payment.stripeLabel'),
            value: data?.paymentMethods?.[PaymentServices.STRIPE],
            type: PaymentServices.STRIPE,
        },
        {
            label: t('backoffice:settings.payment.monobankLabel'),
            value: data?.paymentMethods?.[PaymentServices.MONOBANK],
            type: PaymentServices.MONOBANK,
        },
        {
            label: t('backoffice:settings.payment.liqPayLabel'),
            value: data?.paymentMethods?.[PaymentServices.LIQPAY],
            type: PaymentServices.LIQPAY,
        },
    ]

    const socialFields = [
        {
            label: t('backoffice:settings.socials.instagramLabel'),
            value: '',
        },
        {
            label: t('backoffice:settings.socials.tiktokLabel'),
            value: '',
        },
        {
            label: t('backoffice:settings.socials.facebookLabel'),
            value: '',
        },
    ]

    return (
        <PageLayoutMobile
            pageTitle={`${t('backoffice:pageTitles.backoffice')} | ${t(
                'backoffice:pageTitles.settingsPage'
            )}`}
            onBack={onBack}
            title={t('backoffice:settings.title') as string}
            sx={{
                gap: '25px',
            }}
        >
            <Paper
                title={t('backoffice:settings.account.title') as string}
                content={() =>
                    mainFields.map(item => (
                        <Stack
                            key={item.label}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="15px"
                            padding="10px 5px"
                        >
                            <Typography
                                sx={{
                                    color: 'rgba(255, 255, 255, .4)',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {item.label}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {typeof item.value === 'function'
                                    ? item.value()
                                    : item.value}
                            </Typography>
                        </Stack>
                    ))
                }
            />
            <Paper
                title={t('backoffice:settings.payment.title') as string}
                content={() =>
                    paymentFields.map(item => (
                        <Stack
                            key={item.label}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="15px"
                            padding="5px"
                        >
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {item.label}
                            </Typography>

                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => onPaymentMethodClick(item.type)}
                            >
                                {item.value ? (
                                    <SettingsOutlined />
                                ) : (
                                    <AddOutlined />
                                )}
                            </IconButton>
                        </Stack>
                    ))
                }
            />
            <Paper
                title={t('backoffice:settings.socials.title') as string}
                actions={[
                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={onEditSocialsClick}
                    >
                        <EditOutlined />
                    </IconButton>,
                ]}
                content={() =>
                    socialFields.map(item => (
                        <Stack
                            key={item.label}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="15px"
                            padding="10px 5px"
                        >
                            <Typography
                                sx={{
                                    color: 'rgba(255, 255, 255, .4)',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {item.label}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {item.value}
                            </Typography>
                        </Stack>
                    ))
                }
            />
        </PageLayoutMobile>
    )
}
export default SettingsPageMobile
