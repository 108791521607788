import React, { JSX, ReactNode } from 'react'

import { Box, IconButton, Stack, SxProps, Typography } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'
import { useInView } from 'react-intersection-observer'

import { useDocumentTitle } from 'hooks/common'

interface PageLayoutProps {
    title: string
    pageTitle: string
    caption?: string | null
    actions?: JSX.Element[]
    onBack?: () => void
    children: ReactNode | ReactNode[]
    sx?: SxProps
    toolbar?: (() => JSX.Element) | undefined
}

const PageLayoutMobile = ({
    title,
    pageTitle,
    caption,
    actions,
    onBack,
    children,
    sx,
    toolbar,
}: PageLayoutProps) => {
    useDocumentTitle(pageTitle)

    const { ref, inView } = useInView({
        threshold: 1,
        initialInView: true,
        rootMargin: '-15px',
    })

    const renderHeader = () => {
        return (
            <Stack
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                gap="10px"
            >
                {onBack ? (
                    <IconButton onClick={onBack}>
                        <KeyboardBackspace />
                    </IconButton>
                ) : (
                    <Box sx={{ width: '40px', height: '40px' }} />
                )}

                <Stack alignItems="center">
                    <Typography
                        noWrap
                        textAlign="center"
                        sx={{
                            fontSize: '14px',
                            lineHeight: '16px',
                            opacity: 0,
                            transition: '0.3s ease',
                            ...(!inView && {
                                opacity: 1,
                            }),
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        noWrap
                        color="primary.dark"
                        fontSize="12px"
                        sx={theme => ({
                            maxWidth: '250px',
                            color: theme.palette.secondary.dark,
                            opacity: 0,
                            transition: '0.3s ease',
                            ...(!inView && {
                                opacity: 1,
                            }),
                        })}
                    >
                        {caption}
                    </Typography>
                </Stack>

                <Stack
                    sx={{
                        minWidth: '56px',
                        opacity: 0,
                        transition: '0.3s ease',
                        ...(!inView && {
                            opacity: 1,
                        }),
                    }}
                >
                    {actions}
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack
            gap="15px"
            sx={{
                padding: '50px 5px 15px',
                ...sx,
            }}
        >
            <Stack
                gap="10px"
                sx={{
                    position: 'fixed',
                    overflow: 'hidden',
                    height: inView ? '54px' : toolbar ? '111px' : '64px',
                    transition: '0.3s ease',
                    top: 0,
                    left: 0,
                    right: 0,
                    background: inView
                        ? 'rgba(24,24,24,1)'
                        : 'rgba(30,30,30,1)',
                    padding: '20px 4px 10px',
                    zIndex: 2,
                    borderBottom: '1px solid',
                    borderColor: inView ? 'transparent' : '#262626',
                }}
            >
                {renderHeader()}
                {toolbar && (
                    <Stack
                        sx={{
                            padding: '0 10px',
                            opacity: 0,
                            transition: '0.3s ease',
                            ...(!inView && {
                                opacity: 1,
                            }),
                        }}
                    >
                        {toolbar()}
                    </Stack>
                )}
            </Stack>

            <Stack
                ref={ref}
                gap="15px"
                sx={{
                    marginBottom: '20px',
                }}
            >
                <Stack
                    sx={{
                        transition: '0.3s ease',
                        opacity: inView ? 1 : 0,
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack>
                        <Typography
                            fontSize="24px"
                            lineHeight="24px"
                            fontWeight={700}
                        >
                            {title}
                        </Typography>
                        <Typography fontSize="14px" color="secondary.dark">
                            {caption}
                        </Typography>
                    </Stack>

                    <Stack>{actions}</Stack>
                </Stack>
                {toolbar && (
                    <Stack
                        sx={{
                            transition: '0.3s ease',
                            opacity: inView ? 1 : 0,
                        }}
                    >
                        {toolbar()}
                    </Stack>
                )}
            </Stack>

            {children}
        </Stack>
    )
}

export default PageLayoutMobile
