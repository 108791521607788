import React, { JSX, useState } from 'react'

import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useMediaQuery, useTheme } from '@mui/material'

import {
    useHomeworkSubmissions,
    useHomeworkSubmissionsAPI,
} from '@backoffice/hooks/homeworkSumbission'
import { HomeworkSubmissionType } from 'api/root/generated'
import { useLesson } from '@backoffice/hooks/lessons'
import { HomeworkSubmissionRejectForm } from '@backoffice/components/forms'
import { useDialog } from '../../../../../hooks/common'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { useHomework } from '@backoffice/hooks/homeworks'
import HomeworkSubmissionsPageDesktop from './index.desktop'
import HomeworkSubmissionsPageMobile from './index.mobile'

const HomeworkSubmissionsPage = (): JSX.Element => {
    const navigate = useNavigate()
    const { homeworkId } = useParams()
    const [searchParams] = useSearchParams()
    const [email, setEmail] = useState('')

    const {
        homeworkSubmissions,
        loading: homeworkSubmissionsLoading,
        refetch,
    } = useHomeworkSubmissions(homeworkId as string, email, true)
    const { homework } = useHomework(homeworkId as string)
    const { enqueueSnackbar } = useSnackbar()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const { open, close } = useDialog()
    const { approve } = useHomeworkSubmissionsAPI()
    const fastAccess = searchParams.get('fastAccess')

    // if (homeworkSubmissionsLoading) {
    //     return <Loader />
    // }

    const handleApproveSubmission = (submissionId: string) => {
        approve({
            input: {
                submissionId,
            },
        }).then(response => {
            if (response?.submission) {
                enqueueSnackbar(t('Submission successfully approved'), {
                    variant: 'success',
                })
                refetch()
            }
        })
    }

    const handleRejectSubmission = (submissionId: string) => {
        open({
            component: HomeworkSubmissionRejectForm,
            props: {
                submissionId,
                onSuccess: () => {
                    close()
                    refetch()

                    enqueueSnackbar(t('Submission rejected'), {
                        variant: 'success',
                    })
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleBack = () => {
        if (fastAccess) {
            navigate(-1)
        } else {
            navigate(
                `../${backofficePaths.homework}?homeworkId=${homework?.id}`
            )
        }
    }

    const handleSubmissionClick = (submission: HomeworkSubmissionType) =>
        navigate(
            `../${backofficePaths.homeworkSubmission(
                homeworkId as string,
                submission.id
            )}?email=${submission.client.email as string}`
        )

    return tablet ? (
        <HomeworkSubmissionsPageDesktop
            caption={homework?.title as string}
            loading={homeworkSubmissionsLoading}
            onApproveSubmission={handleApproveSubmission}
            onRejectSubmission={handleRejectSubmission}
            onBack={handleBack}
            submissions={homeworkSubmissions}
            onSubmissionClick={handleSubmissionClick}
            onSearch={setEmail}
        />
    ) : (
        <HomeworkSubmissionsPageMobile
            caption={homework?.title as string}
            loading={homeworkSubmissionsLoading}
            onApproveSubmission={handleApproveSubmission}
            onRejectSubmission={handleRejectSubmission}
            onBack={handleBack}
            submissions={homeworkSubmissions}
            onSubmissionClick={handleSubmissionClick}
            onSearch={setEmail}
        />
    )
}

export default HomeworkSubmissionsPage
