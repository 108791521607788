import React, { JSX, useEffect, useState } from 'react'

import {
    Typography,
    Stack,
    Button,
    Divider,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { useParams, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { AddCircleOutline, ViewList } from '@mui/icons-material'

import { CourseType, LessonType } from 'api/root/generated'
import { ContentLoader, PageLayout } from '@backoffice/components/common'
import Overview from './components/Overview'
import { useAccess } from '@backoffice/hooks/accesses'
import CourseContent from './components/CourseContent'
import { useHomeworks } from '@backoffice/hooks/homeworks'
import { CoursePageProps } from './index.types'
import CourseActionBar from '@backoffice/components/pages/NewCoursePage/components/CourseActionBar'

const CoursePageDesktop = ({
    caption,
    selectable,
    onAddLesson,
    onAddModule,
    onBack,
    course,
    modules,
    selectedLessons,
    onSelectLesson,
    loadingContent,
    onDeleteAccess,
    onGrantAccess,
    params,
}: CoursePageProps): JSX.Element => {
    const { homeworks } = useHomeworks()

    const renderNewModuleButton = (first = false) => {
        return (
            <Button
                onClick={onAddModule}
                sx={() => ({
                    width: 'fit-content',
                    '& .MuiSvgIcon-root': {
                        fontSize: '26px',
                    },
                })}
                variant="text"
                color="secondary"
                startIcon={<AddCircleOutline />}
            >
                <Typography fontSize="22px">
                    {first
                        ? t(
                              'backoffice:content.course.module.actions.createFirst'
                          )
                        : t('backoffice:content.course.module.actions.add')}
                </Typography>
            </Button>
        )
    }

    const renderEmptyCourseScreen = () => {
        return (
            <Stack alignItems="center" justifyContent="center">
                <ViewList
                    sx={() => ({
                        opacity: '0.4',
                        fontSize: '204px',
                        color: 'rgba(255, 255, 255, 0.15)',
                    })}
                />
                <Stack gap="25px" alignItems="center">
                    <Typography color="secondary.dark" fontSize="20px">
                        {t('backoffice:content.course.noContentCaption')}
                    </Typography>
                    {renderNewModuleButton(true)}
                </Stack>
            </Stack>
        )
    }

    const resolveActions = () => {
        const { id, email } = params

        if (id) {
            return selectedLessons.length === 0
                ? [
                      <Button
                          onClick={onDeleteAccess}
                          color="error"
                          variant="outlined"
                          key="save"
                          sx={theme => ({
                              color: theme.palette.error.main,
                              '& .MuiTypography-root': {
                                  color: theme.palette.error.main,
                              },
                          })}
                      >
                          <Typography>Видалити доступи</Typography>
                      </Button>,
                  ]
                : [
                      <Button
                          onClick={onGrantAccess}
                          color="success"
                          variant="outlined"
                          key="save"
                          sx={theme => ({
                              color: theme.palette.success.main,
                              '& .MuiTypography-root': {
                                  color: theme.palette.success.main,
                              },
                          })}
                      >
                          <Typography>Зберегти доступи</Typography>
                      </Button>,
                  ]
        }

        if (email && !id) {
            return [
                <Button
                    disabled={selectedLessons.length === 0}
                    key="grant"
                    onClick={onGrantAccess}
                    color="success"
                    variant="outlined"
                    sx={theme => ({
                        color: theme.palette.success.main,
                        '& .MuiTypography-root': {
                            color: theme.palette.success.main,
                        },
                        '&.Mui-disabled': {
                            '& .MuiTypography-root': {
                                color: theme.palette.secondary.dark,
                            },
                        },
                    })}
                >
                    <Typography>Надати доступ</Typography>
                </Button>,
            ]
        }

        return [
            <CourseActionBar
                variant="inline"
                key="actions"
                course={course as CourseType}
            />,
        ]
    }

    return (
        <PageLayout
            pageTitle={`${t('backoffice:pageTitles.backoffice')} | ${
                course?.name
            }`}
            onBack={onBack}
            title={course?.name as string}
            caption={caption}
            actions={resolveActions()}
        >
            <ContentLoader
                visible={loadingContent.length !== 0}
                items={loadingContent}
            />
            {!selectable && modules?.length !== 0 && (
                <Overview course={course as CourseType} />
            )}
            <Stack gap="50px" divider={<Divider flexItem />}>
                {modules?.length !== 0 ? (
                    <CourseContent
                        homeworks={homeworks}
                        selectable={selectable}
                        course={course as CourseType}
                        selectedLessons={selectedLessons}
                        onSelectedChange={onSelectLesson}
                        onNewLesson={onAddLesson}
                    />
                ) : (
                    renderEmptyCourseScreen()
                )}
                {modules?.length !== 0 &&
                    !selectable &&
                    renderNewModuleButton()}
            </Stack>
        </PageLayout>
    )
}

export default CoursePageDesktop
