import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    IconButton,
    Tooltip,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { KeyboardArrowRight } from '@mui/icons-material'

import { AccessMenu, PageLayout } from '@backoffice/components/common'
import {
    CourseAccessType,
    CourseStreamType,
    CourseType,
    UserType,
} from 'api/root/generated'
import { SearchField } from 'components'
import { ClientsPageProps } from '@backoffice/components/pages/ClientsPage/index.types'

const ClientsPageDesktop = ({
    clients,
    courses,
    loading,
    onAdd,
    onSearch,
    onClick,
    onBack,
}: ClientsPageProps): JSX.Element => {
    const navigate = useNavigate()

    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))

    const desktopExtraColumns: GridColDef<UserType>[] = [
        {
            flex: 1,
            field: 'streams',
            headerAlign: 'center',
            align: 'center',
            headerName: t('backoffice:clients.table.columns.streams') as string,
            cellClassName: 'secondary',
            valueGetter: (_, row) => {
                const streams = (row.accesses as CourseAccessType[])
                    ?.filter(a => a.stream)
                    .map(a => a?.stream)

                return streams?.length === 0
                    ? '-'
                    : (streams as CourseStreamType[])
                          ?.map((a: CourseStreamType) => a?.name)
                          .join(', ')
            },
        },
        {
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            field: 'accesses',
            headerName: t(
                'backoffice:clients.table.columns.accesses'
            ) as string,
            cellClassName: 'secondary',
            valueGetter: (_, row) => {
                return row.accesses?.length || '-'
            },
        },
    ]

    const columns: GridColDef<UserType>[] = [
        {
            field: 'email',
            headerName: t('backoffice:clients.table.columns.email') as string,
            flex: 3,
        },
        ...(desktop ? desktopExtraColumns : []),
        {
            field: 'id',
            type: 'actions',
            align: 'right',
            headerAlign: 'right',
            getActions: ({ id }: GridRowParams<UserType>) => {
                return [
                    <Tooltip
                        title={
                            <Typography>
                                {t('backoffice:client.sections.personal.title')}
                            </Typography>
                        }
                    >
                        <IconButton
                            size="large"
                            color="secondary"
                            onClick={() => navigate(`${id}?tab=0`)}
                            value={id}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </Tooltip>,
                ]
            },
        },
    ]

    return (
        <PageLayout
            pageTitle={t('backoffice:pageTitles.clientsPage')}
            onBack={onBack}
            title={t('backoffice:clients.title') as string}
            caption={t('backoffice:clients.caption') as string}
            actions={[
                <AccessMenu<CourseType>
                    key="grantAccess"
                    onSelect={onAdd}
                    items={courses}
                />,
            ]}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <SearchField
                    sx={{
                        maxWidth: '350px',
                    }}
                    placeholder={t('backoffice:access.search') as string}
                    onChange={onSearch}
                />
            </Stack>

            <DataGrid
                autoHeight
                hideFooter
                loading={loading}
                rows={clients}
                columns={columns}
                onRowClick={params => onClick(`${params.row.id}`)}
            />
        </PageLayout>
    )
}
export default ClientsPageDesktop
