import React, { JSX } from 'react'

import { t } from 'i18next'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import {
    AccessTime,
    EditOutlined,
    ThumbDownOffAlt,
    ThumbUpOffAlt,
} from '@mui/icons-material'
import { format } from 'date-fns-tz'
import { formatDuration, intervalToDuration, isAfter } from 'date-fns'
import { uk } from 'date-fns/locale'

import { PageLayout } from '@backoffice/components/common'
import { Editor, SubmissionComments } from 'components'
import {
    HomeworkSubmissionType,
    SubmissionCommentType,
    UserType,
} from 'api/root/generated'
import {
    formatUploadedItemFromUrl,
    formatDateDuration,
    resolveTextByStatus,
} from 'utils/common.utils'
import { HomeworkSubmissionPageProps } from '@backoffice/components/pages/HomeworkSubmissionPage/index.types'

const HomeworkSubmissionPageDesktop = ({
    onApproveSubmission,
    onRejectSubmission,
    submissions,
    onBack,
    onPostComment,
    caption,
    user,
    clientEmail,
}: HomeworkSubmissionPageProps): JSX.Element => {
    const renderActions = (submission: HomeworkSubmissionType) => {
        if (submission.status?.val === 'Pending') {
            return [
                <IconButton
                    size="large"
                    color="error"
                    onClick={() => onRejectSubmission(submission.id)}
                >
                    <ThumbDownOffAlt />
                </IconButton>,
                <IconButton
                    size="large"
                    color="success"
                    onClick={() => onApproveSubmission(submission.id)}
                >
                    <ThumbUpOffAlt />
                </IconButton>,
            ]
        }
        return [
            <Typography
                fontWeight={700}
                color={
                    submission.status?.val === 'Rejected' ? 'error' : 'success'
                }
            >
                {resolveTextByStatus(submission.status)}
            </Typography>,
        ]
    }

    const renderSubmission = (
        submission: HomeworkSubmissionType,
        index: number,
        array: HomeworkSubmissionType[]
    ) => {
        const olderSubmission = array[index + 1]

        let diff

        if (olderSubmission) {
            diff = formatDateDuration({
                start: new Date(submission.createdAt),
                end: new Date(olderSubmission.createdAt),
            })
        }

        return (
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    gap="15px"
                    sx={{
                        padding: '20px 25px',
                        borderRadius: '16px',
                        background: 'rgb(28,28,28)',
                        border: '1px solid #404040',
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack direction="row" alignItems="center" gap="15px">
                            <Typography fontSize="32px" color="primary">
                                #
                            </Typography>
                            <Stack>
                                <Typography fontSize="20px">
                                    {format(
                                        new Date(submission.createdAt),
                                        'dd.MM.yyyy HH:mm',
                                        {
                                            timeZone:
                                                Intl.DateTimeFormat().resolvedOptions()
                                                    .timeZone,
                                        }
                                    )}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction="row" justifyContent="flex-end">
                            {submission.status?.val === 'Pending' ? (
                                renderActions(
                                    submission as HomeworkSubmissionType
                                )
                            ) : (
                                <Typography
                                    lineHeight="56px"
                                    fontSize="18px"
                                    color={
                                        submission.status?.val === 'Rejected'
                                            ? 'error'
                                            : 'success'
                                    }
                                >
                                    {resolveTextByStatus(submission.status)}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Editor
                        editable={false}
                        content={submission.content}
                        uploadedFiles={formatUploadedItemFromUrl(
                            submission.attachments || []
                        )}
                    />
                </Stack>
                {diff && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap="15px"
                        width="100%"
                        paddingLeft="43px"
                    >
                        <Box
                            sx={{
                                width: '2px',
                                background: 'rgba(255, 255, 255, 0.1)',
                                height: '50px',
                            }}
                        />
                        <Stack direction="row" alignItems="center" gap="7px">
                            <AccessTime
                                sx={{
                                    fontSize: '20px',
                                    color: 'rgba(255, 255, 255, 0.4)',
                                }}
                            />
                            <Typography color="rgba(255, 255, 255, 0.4)">
                                через {diff}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        )
    }

    const sampleSubmission = submissions?.[0]

    const allComments =
        (submissions as HomeworkSubmissionType[])
            .map(s =>
                s.comments?.edges.map(e => e?.node as SubmissionCommentType)
            )
            .flat() || []

    const sortedComments = allComments.sort((a, b) =>
        isAfter(new Date(a?.createdAt), new Date(b?.createdAt)) ? 1 : -1
    )

    return (
        <PageLayout
            pageTitle={`${t('backoffice:homework.submission.title', {
                email: clientEmail,
            })}`}
            onBack={onBack}
            title={clientEmail}
            caption={caption}
        >
            <Stack
                gap="50px"
                sx={{
                    padding: '20px 25px',
                    borderRadius: '16px',
                    background: 'rgb(50, 50, 50)',
                    border: '1px solid #404040',
                }}
            >
                <Stack gap="25px">
                    <Typography
                        fontSize="18px"
                        color="rgba(255, 255, 255, 0.4)"
                    >
                        Історія виконання завдання
                    </Typography>
                    <Stack>{submissions?.map(renderSubmission)}</Stack>
                </Stack>

                <SubmissionComments
                    submissionId={sampleSubmission?.id as string}
                    onSendComment={onPostComment}
                    empty={allComments?.length === 0}
                    coach={user as UserType}
                    comments={sortedComments as SubmissionCommentType[]}
                />
            </Stack>
        </PageLayout>
    )
}

export default HomeworkSubmissionPageDesktop
