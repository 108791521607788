import React, { JSX } from 'react'

import {
    Box,
    Button,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useFormik } from 'formik'

import {
    HomeworkSubmissionRejectFormScheme,
    HomeworkSubmissionRejectFormValues,
} from './index.schema'
import { useHomeworkSubmissionsAPI } from '@backoffice/hooks/homeworkSumbission'

interface HomeworkSubmissionRejectFormProps {
    submissionId: string
    onSuccess?: () => void
    onDelete: () => void
}

const HomeworkSubmissionRejectForm = ({
    submissionId,
    onSuccess,
    onDelete,
}: HomeworkSubmissionRejectFormProps): JSX.Element => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const { reject, loading } = useHomeworkSubmissionsAPI()
    const formik = useFormik({
        initialValues: {
            reason: '',
        },
        validationSchema: HomeworkSubmissionRejectFormScheme,
        onSubmit: (values: HomeworkSubmissionRejectFormValues) => {
            reject({
                input: {
                    submissionId,
                    ...values,
                },
            }).then(response => {
                if (response?.submission) {
                    onSuccess?.()
                }
            })
        },
    })
    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                maxWidth: '500px',
                minWidth: 'min(80vw, 500px)',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            sx={{
                                fontSize: '20px',
                            }}
                        >
                            Відхилення домашнього завдання
                        </Typography>
                    </Stack>
                    <Stack gap="10px">
                        <TextField
                            fullWidth
                            autoComplete="off"
                            name="reason"
                            variant="outlined"
                            label="Причина пересдачи"
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.reason &&
                                Boolean(formik.errors.reason)
                            }
                        />
                    </Stack>
                    <Stack gap="10px">
                        <Button
                            loading={loading}
                            color="error"
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{ padding: tablet ? '15px' : '10px' }}
                        >
                            <Typography>Відхилити</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default HomeworkSubmissionRejectForm
