import React from 'react'

import { Button, Divider, Stack, Typography } from '@mui/material'
import {
    Add,
    DeleteOutline,
    EditOutlined,
    PlayCircle,
    Quiz,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import useCourseEntityAPI from '@backoffice/hooks/course'
import useConfirmationDialog from '../../../../hooks/common/useConfirmationDialog'
import {
    CourseType,
    LessonType,
    ModuleType,
} from '../../../../../../api/root/generated'
import { ModuleForm } from '@backoffice/components/forms'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { useDrawer } from 'hooks/common'

interface ModuleMenuProps {
    onClose: () => void
    course: CourseType
    module: ModuleType
}

const ModuleMenu = ({ course, onClose, module }: ModuleMenuProps) => {
    const navigate = useNavigate()

    const { open, close } = useDrawer()

    const { enqueueSnackbar } = useSnackbar()
    const { refetch, removeModule } = useCourseEntityAPI(course.id)
    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const handleDeleteModule = () => {
        removeModule({
            input: {
                moduleId: module.id,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                close()
                enqueueSnackbar(
                    t(
                        'backoffice:content.course.module.notifications.successDeleted'
                    ),
                    { variant: 'success' }
                )
            }
        })
    }

    const handleOpenModuleForm = () => {
        onClose()
        open({
            component: ModuleForm,
            props: {
                module,
                course,
                onSuccess: (action: 'add' | 'update') => {
                    close()
                    refetch()
                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.module.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.module.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
                onDelete: () => {
                    close()
                    requestConfirmation(
                        module as ModuleType,
                        'module',
                        handleDeleteModule
                    )
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenHomework = () => {
        onClose()
        navigate(
            `../${backofficePaths.homework}?category=module&relationId=${module.id}&homeworkId=${module?.homework?.id}&title=${module?.name}`
        )
    }

    return (
        <Stack divider={<Divider />} padding="20px" gap="15px">
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                padding="10px"
                sx={{
                    borderRadius: '12px',
                    background: '#181818',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        lineHeight: '20px',
                    }}
                >
                    {module.name}
                </Typography>
            </Stack>

            <Stack
                sx={{
                    '& .MuiButtonBase-root': {
                        width: '100%',
                        padding: '12px 0',
                        justifyContent: 'flex-start',
                    },
                }}
            >
                <Button
                    color="inherit"
                    variant="text"
                    startIcon={<EditOutlined />}
                    onClick={handleOpenModuleForm}
                >
                    <Typography>
                        {t('backoffice:content.course.module.actions.edit')}
                    </Typography>
                </Button>
                <Button
                    color="inherit"
                    variant="text"
                    startIcon={module?.homework ? <Quiz /> : <Add />}
                    onClick={handleOpenHomework}
                >
                    <Typography>
                        {t(
                            module.homework
                                ? 'backoffice:content.course.lesson.actions.updateHomework'
                                : 'backoffice:content.course.lesson.actions.addHomework'
                        )}
                    </Typography>
                </Button>
                <Button
                    color="error"
                    variant="text"
                    startIcon={<DeleteOutline />}
                    onClick={() => {
                        onClose()
                        requestConfirmation(
                            module,
                            'module',
                            handleDeleteModule
                        )
                    }}
                >
                    <Typography>
                        {t('backoffice:content.course.module.actions.delete')}
                    </Typography>
                </Button>
            </Stack>
        </Stack>
    )
}

export default ModuleMenu
