import React, { JSX } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { paths } from '@backoffice/index.routes'
import { useDialog, useDocumentTitle, useDrawer } from 'hooks/common'
import { useCourses } from 'hooks/courses'
import { useClients } from '@backoffice/hooks/clients'
import { GrantAccessScreen } from '@backoffice/components/common'
import {
    CourseType,
    HomeworkSubmissionType,
    UserType,
} from '../../../../../api/root/generated'
import DashboardPageDesktop from '@backoffice/components/pages/NewDashboardPage/index.desktop'
import DashboardPageMobile from '@backoffice/components/pages/NewDashboardPage/index.mobile'
import { AccessForm, CourseForm } from '@backoffice/components/forms'
import { useLatestHomeworkSubmissions } from '@backoffice/hooks/homeworkSumbission'

const DashboardPage = (): JSX.Element => {
    useDocumentTitle(t('backoffice:pageTitles.dashboardPage'))
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()

    const navigate = useNavigate()

    const { courses, loading: coursesLoading } = useCourses(false, {
        first: tablet ? 5 : 3,
        offset: 0,
    })
    const { clients, loading: clientsLoading } = useClients({
        first: tablet ? 5 : 3,
        offset: 0,
    })
    const { latestHomeworkSubmissions, loading: submissionsLoading } =
        useLatestHomeworkSubmissions()

    const handleOpenCreateCourseModal = () => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer

        openModal({
            component: CourseForm,
            props: {
                onSuccess: (action: 'add' | 'update', id: string) => {
                    closeModal()
                    navigate(`../${paths.course(id)}?fastAccess=true`)
                },
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleOpenGrantAccessModal = (item: CourseType) => {
        if (tablet) {
            open({
                component: GrantAccessScreen,
                options: {
                    onClose: close,
                },
                props: {
                    course: item,
                    onCancel: close,
                    onSubmit: (email: string, streamId?: string) => {
                        close()
                        navigate(
                            `../${paths.course(
                                item.id
                            )}?selectable=true&email=${email}${
                                streamId ? `&stream=${streamId}` : ''
                            }&fastAccess=true`
                        )
                    },
                },
            })
        } else {
            openDrawer({
                component: AccessForm,
                options: {
                    onClose: closeDrawer,
                },
                props: {
                    course: item,
                    onCancel: closeDrawer,
                    onSubmit: ({
                        email,
                        stream,
                    }: {
                        email: string
                        stream?: string | null
                    }) => {
                        closeDrawer()
                        navigate(
                            `../${paths.course(
                                item.id
                            )}?selectable=true&email=${email}${
                                stream ? `&stream=${stream}` : ''
                            }&fastAccess=true`
                        )
                    },
                },
            })
        }
    }

    const handleGoToCourse = (course: CourseType) =>
        navigate(`../${paths.course(course.id)}?fastAccess=true`)

    const handleGoToClient = (client: UserType) =>
        navigate(`../${paths.client(client.id)}?fastAccess=true`)

    const handleGoToClientsPage = () => navigate(`../${paths.clients}`)

    const handleGoToContentPage = () => navigate(`../${paths.content}`)
    const handleGoToSubmission = (submission: HomeworkSubmissionType) =>
        navigate(
            `../${paths.homeworkSubmission(
                submission.homework.id as string,
                submission.id
            )}?email=${submission.client.email}&fastAccess=true`
        )

    return tablet ? (
        <DashboardPageDesktop
            courses={courses}
            clients={clients}
            submissions={latestHomeworkSubmissions}
            clientsLoading={clientsLoading}
            coursesLoading={coursesLoading}
            submissionsLoading={submissionsLoading}
            onCourseClick={handleGoToCourse}
            onClientClick={handleGoToClient}
            onMoreAccessClick={handleGoToClientsPage}
            onMoreContentClick={handleGoToContentPage}
            onAddAccessClick={handleOpenGrantAccessModal}
            onAddContentClick={handleOpenCreateCourseModal}
            onSubmissionClick={handleGoToSubmission}
        />
    ) : (
        <DashboardPageMobile
            onSubmissionClick={handleGoToSubmission}
            courses={courses}
            clients={clients}
            submissions={latestHomeworkSubmissions}
            clientsLoading={clientsLoading}
            coursesLoading={coursesLoading}
            onCourseClick={handleGoToCourse}
            onClientClick={handleGoToClient}
            onMoreAccessClick={handleGoToClientsPage}
            onMoreContentClick={handleGoToContentPage}
            onAddAccessClick={handleOpenGrantAccessModal}
            onAddContentClick={handleOpenCreateCourseModal}
        />
    )
}
export default DashboardPage
