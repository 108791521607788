import React, { JSX } from 'react'

import {
    Button,
    Divider,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import { t } from 'i18next'
import { Add, EditOutlined, Link } from '@mui/icons-material'

import {
    AccessMenu,
    ContentList,
    PageLayoutMobile,
    Paper,
} from '@backoffice/components/common'
import { CourseAccessType, CourseType } from 'api/root/generated'
import { ClientPageProps } from '@backoffice/components/pages/ClientPage/index.types'
import { Image } from 'components'
import noBackground from 'assets/images/no-background.png'
import { useDrawer } from '../../../../../hooks/common'
import { AccessForm } from '@backoffice/components/forms'
import { paths } from '@backoffice/index.routes'

const ClientPageMobile = ({
    onGrantAccess,
    onBack,
    loading,
    client,
    courses,
    onCopyAccessLink,
    onAccessClick,
    onAddAccess,
}: ClientPageProps): JSX.Element => {
    const { open, close } = useDrawer()

    const personalData = [
        {
            label: t('backoffice:client.sections.personal.firstName'),
            value: '',
        },
        {
            label: t('backoffice:client.sections.personal.lastName'),
            value: '',
        },

        {
            label: t('backoffice:client.sections.personal.age'),
            value: '',
        },
        {
            label: t('backoffice:client.sections.personal.gender'),
            value: '',
        },
    ]

    const handleOpenContentList = (courses: CourseType[]) => {
        open({
            component: ContentList,
            props: {
                courses,
                onSelect: (course: CourseType) => {
                    close()
                    onAddAccess?.(course)
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const renderEmptyList = () => {
        return (
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                gap="10px"
                padding="10px"
            >
                <Typography
                    fontSize="14px"
                    color="secondary.dark"
                    textAlign="center"
                >
                    {t('backoffice:client.sections.access.noContentCaption')}
                </Typography>
            </Stack>
        )
    }

    const renderPlaceholder = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                padding="10px 5px"
            >
                <Skeleton
                    variant="rectangular"
                    width="32px"
                    sx={{
                        height: '32px',
                        borderRadius: '4px',
                    }}
                />
                <Skeleton
                    variant="text"
                    sx={{ flexGrow: 1, lineHeight: '38px' }}
                />
            </Stack>
        )
    }

    const accessedCoursesIds = client.accesses?.map(a => a?.course.id) || []
    const notAccessedCourses = courses.filter(
        c => !accessedCoursesIds.includes(c.id)
    )

    const renderAccess = (access: CourseAccessType) => {
        return loading ? (
            renderPlaceholder()
        ) : (
            <Stack
                key={access.id}
                width="100%"
                direction="row"
                gap="15px"
                padding="10px 5px"
                onClick={() => onAccessClick(access as CourseAccessType)}
            >
                <Image
                    sx={{
                        minWidth: '32px',
                        width: '32px',
                        height: '32px',
                        objectFit: 'cover',
                        borderRadius: '4px',
                    }}
                    src={
                        access?.course.appearance?.background?.img150x100 ||
                        noBackground
                    }
                />
                <Stack gap="3px" flexGrow={1} width="100%">
                    <Typography
                        sx={{
                            fontSize: '15px',
                            lineHeight: '17px',
                        }}
                    >
                        {access?.course.name}
                    </Typography>
                    <Stack direction="row">
                        <Button
                            onClick={e => {
                                e.stopPropagation()
                                onCopyAccessLink(access as CourseAccessType)
                            }}
                            variant="text"
                            size="small"
                            startIcon={<Link />}
                            color="primary"
                            sx={() => ({
                                padding: 0,
                                fontSize: '12px',
                                lineHeight: '16px',
                                '& .MuiButton-icon': {
                                    marginRight: '4px',
                                },
                            })}
                        >
                            Скопiювати посилання
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    return (
        <PageLayoutMobile
            pageTitle={t('backoffice:pageTitles.clientsPage')}
            caption={t('backoffice:client.caption')}
            onBack={onBack}
            title={client?.email as string}
            sx={{
                gap: '25px',
            }}
        >
            <Paper
                title="Iнформацiя"
                content={() =>
                    personalData.map(item => (
                        <Stack
                            key={item.label}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="15px"
                            padding="10px 5px"
                        >
                            <Typography
                                sx={{
                                    color: 'rgba(255, 255, 255, .4)',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {item.label}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {item.value}
                            </Typography>
                        </Stack>
                    ))
                }
                actions={[
                    <IconButton size="small" color="secondary">
                        <EditOutlined />
                    </IconButton>,
                ]}
            />
            <Paper
                title="Доступний контент"
                actions={[
                    <IconButton
                        onClick={() =>
                            handleOpenContentList(notAccessedCourses)
                        }
                        color="secondary"
                        size="small"
                    >
                        <Add />
                    </IconButton>,
                ]}
                content={() =>
                    client?.accesses?.length === 0
                        ? renderEmptyList()
                        : client.accesses?.map(access =>
                              renderAccess(access as CourseAccessType)
                          )
                }
            />
        </PageLayoutMobile>
    )
}
export default ClientPageMobile
