import React, { useState } from 'react'

import {
    FormatBold,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
    FormatUnderlined,
    Title,
    AccessTime,
} from '@mui/icons-material'
import {
    IconButton,
    Tooltip,
    useTheme,
    useMediaQuery,
    Divider,
    Stack,
    Popover,
    Typography,
    Theme,
} from '@mui/material'
import { Editor } from '@tiptap/react'
import { format } from 'date-fns-tz'

import { FileUpload, TOOLBAR_VARIANTS } from '../../index'
import { UploadedItem } from '../../../types'
import { useDrawer } from '../../../hooks/common'
import { DatepickerMobile } from '@backoffice/components/common'

interface EditorToolbarProps {
    editor: Editor | null
    active: boolean
    onFileChange?: (items: UploadedItem[]) => void
    onDeadlineChange?: (value: Date | null) => void
    deadline?: Date | null
    uploadedFiles?: UploadedItem[]
    toolbar: TOOLBAR_VARIANTS
    deadlineError?: boolean
}

const shakeStyles = {
    animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
    '@keyframes shake': {
        '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
        },
        '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
        },
        '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
        },
        '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
        },
    },
}
const EditorToolbar: React.FC<EditorToolbarProps> = ({
    editor,
    active,
    onFileChange,
    deadline,
    onDeadlineChange,
    uploadedFiles,
    deadlineError,
    toolbar = TOOLBAR_VARIANTS.DEFAULT,
}) => {
    const [deadlineAnchorEl, setDeadlineAnchorEl] =
        useState<null | HTMLElement>(null)
    const theme = useTheme()
    const { open, close } = useDrawer()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    if (!editor) return null

    const handleOpenDeadlineMenu = () => {
        open({
            component: DatepickerMobile,
            props: {
                value: deadline,
                onClose: close,
                onChange: (date: Date | null) => {
                    close()
                    onDeadlineChange?.(date)
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent={tablet ? 'space-between' : 'flex-start'}
            padding="0 0 10px"
        >
            <Stack direction="row">
                {toolbar === TOOLBAR_VARIANTS.DEFAULT && (
                    <>
                        <Tooltip title="Bold">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor.chain().focus().toggleBold().run()
                                }
                                color={
                                    editor.isActive('bold')
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <FormatBold />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Italic">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor.chain().focus().toggleItalic().run()
                                }
                                color={
                                    editor.isActive('italic')
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <FormatItalic />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Underline">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleUnderline()
                                        .run()
                                }
                                color={
                                    editor.isActive('underline')
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <FormatUnderlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Bullet List">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleBulletList()
                                        .run()
                                }
                                color={
                                    editor.isActive('bulletList')
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <FormatListBulleted />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ordered List">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleOrderedList()
                                        .run()
                                }
                                color={
                                    editor.isActive('orderedList')
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <FormatListNumbered />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Blockquote">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleBlockquote()
                                        .run()
                                }
                                color={
                                    editor.isActive('blockquote')
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <FormatQuote />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Heading">
                            <IconButton
                                size={tablet ? 'medium' : 'small'}
                                onClick={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleHeading({ level: 2 })
                                        .run()
                                }
                                color={
                                    editor.isActive('heading', { level: 2 })
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <Title />
                            </IconButton>
                        </Tooltip>
                    </>
                )}

                {(toolbar === TOOLBAR_VARIANTS.DEFAULT ||
                    toolbar === TOOLBAR_VARIANTS.ATTACHMENT) && (
                    <>
                        {tablet && (
                            <Divider
                                sx={{ borderWidth: '1px' }}
                                orientation="vertical"
                                flexItem
                            />
                        )}
                        <FileUpload
                            uploadedFiles={uploadedFiles}
                            editable={active}
                            onChange={onFileChange}
                        />
                    </>
                )}
            </Stack>
            {toolbar === TOOLBAR_VARIANTS.DEFAULT && (
                <Stack direction="row" gap="5px" alignItems="center">
                    {deadline && tablet && (
                        <Typography
                            color={deadlineError ? 'error.light' : 'primary'}
                            fontSize="15px"
                            sx={{
                                ...(deadlineError && shakeStyles),
                            }}
                        >
                            {format(new Date(deadline), 'dd.MM.yyyy HH:mm', {
                                timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                            })}
                        </Typography>
                    )}

                    <Tooltip title="Дата, до якої дозволено здачу відповіді">
                        <IconButton
                            size={tablet ? 'medium' : 'small'}
                            onClick={event => {
                                if (tablet) {
                                    setDeadlineAnchorEl(event.currentTarget)
                                } else {
                                    handleOpenDeadlineMenu()
                                }
                            }}
                            sx={(theme: Theme) => ({
                                color: deadlineError
                                    ? theme.palette.error.light
                                    : deadline
                                    ? theme.palette.primary.main
                                    : theme.palette.text.primary,
                                ...(deadlineError && shakeStyles),
                            })}
                        >
                            <AccessTime />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )}

            <Popover
                anchorEl={deadlineAnchorEl}
                open={Boolean(deadlineAnchorEl)}
                onClose={() => setDeadlineAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DatepickerMobile
                    value={deadline || null}
                    onChange={v => {
                        onDeadlineChange?.(v)
                        setDeadlineAnchorEl(null)
                    }}
                    minDate={new Date()}
                />
            </Popover>
        </Stack>
    )
}

export default EditorToolbar
