import React from 'react'

import {
    CourseStreamPage,
    DashboardPage,
    SettingsPage,
    NotFoundPage,
    ContentPage,
    CoursePage,
    ClientsPage,
    ClientPage,
    NotificationsPage,
    StatisticsPage,
    HomeworkSubmissionPage,
    HomeworkSubmissionsPage,
    NewHomeworkPage,
} from '@backoffice/components/pages'

const paths = {
    backoffice: `/backoffice`,
    access: `access`,
    promotion: `promotion`,
    content: `content`,
    clients: `clients`,
    client: (clientId: string) => `clients/${clientId}`,
    settings: `settings`,
    notifications: `notifications`,
    statistics: `statistics`,
    homework: `homework`,
    homeworkSubmissions: (homeworkId: string) =>
        `homework/${homeworkId}/submissions`,
    homeworkSubmission: (homeworkId: string, submissionId: string) =>
        `homework/${homeworkId}/submissions/${submissionId}`,
    course: (courseId: string) => `content/${courseId}`,
    courseStream: (courseId: string) => `content/${courseId}/stream`,
}

const routes = [
    {
        path: paths.backoffice,
        element: <DashboardPage />,
    },
    {
        path: paths.content,
        element: <ContentPage />,
    },
    {
        path: paths.clients,
        element: <ClientsPage />,
    },
    {
        path: paths.client(':clientId'),
        element: <ClientPage />,
    },
    {
        path: paths.settings,
        element: <SettingsPage />,
    },
    {
        path: paths.statistics,
        element: <StatisticsPage />,
    },
    {
        path: paths.notifications,
        element: <NotificationsPage />,
    },
    {
        path: paths.homework,
        element: <NewHomeworkPage />,
    },
    {
        path: paths.homeworkSubmissions(':homeworkId'),
        element: <HomeworkSubmissionsPage />,
    },
    {
        path: paths.homeworkSubmission(':homeworkId', ':submissionId'),
        element: <HomeworkSubmissionPage />,
    },
    {
        path: paths.course(':courseId'),
        element: <CoursePage />,
    },
    {
        path: paths.courseStream(':courseId'),
        element: <CourseStreamPage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]

export { paths, routes }
