import './index.scss'

import React from 'react'

import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ukUA } from '@mui/x-date-pickers/locales'
import { SnackbarProvider } from 'notistack'

import initializeInternationalization from 'utils/internationalization'
import { client } from 'api'
import App from 'apps'
import { Toast } from './components'

declare global {
    interface Window {
        COMMIT_TAG: string
        __localeId__: string
    }
}

initializeInternationalization()

const root = ReactDOM.createRoot(document.getElementById('root') as Element)

root.render(
    <ApolloProvider client={client}>
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={
                ukUA.components.MuiLocalizationProvider.defaultProps.localeText
            }
        >
            <SnackbarProvider
                maxSnack={3}
                autoHideDuration={5000}
                hideIconVariant
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                Components={{
                    success: Toast,
                    error: Toast,
                    default: Toast,
                }}
            >
                <App />
            </SnackbarProvider>
        </LocalizationProvider>
    </ApolloProvider>
)
