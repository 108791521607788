import React, { useState } from 'react'

import {
    Divider,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { FormatQuote, Send } from '@mui/icons-material'
import { format } from 'date-fns-tz'

import { UserType, SubmissionCommentType } from 'api/root/generated'
import { Editor, TOOLBAR_VARIANTS } from 'components'
import { UploadedItem } from '../../types'
import { formatUploadedItemFromUrl } from 'utils/common.utils'

interface CommentsProps {
    comments: SubmissionCommentType[]
    coach: UserType
    readonly?: boolean
    empty?: boolean
    onSendComment: (
        id: string,
        content: string,
        attachments: UploadedItem[],
        onSuccess: (comment: SubmissionCommentType) => void
    ) => void
    submissionId: string
}

const SubmissionComments = ({
    comments,
    coach,
    readonly = false,
    empty = false,
    onSendComment,
    submissionId,
}: CommentsProps) => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const [newCommentContent, setNewCommentContent] = useState('')
    const [newAttachments, setNewAttachments] = useState<UploadedItem[]>([])

    const handleFileUploadChange = (items: UploadedItem[]) => {
        setNewAttachments(items)
    }

    const renderComment = (comment: SubmissionCommentType) => {
        const authoredByCoach = coach?.email === comment.author.email

        return (
            <Stack gap="15px" key={comment.id}>
                <Stack
                    direction="row"
                    gap="10px"
                    alignItems="baseline"
                    justifyContent={tablet ? 'flex-start' : 'space-between'}
                >
                    <Typography
                        sx={theme => ({
                            fontSize: '18px',
                            fontWeight: 700,
                            color: authoredByCoach
                                ? theme.palette.primary.main
                                : 'rgba(255, 255, 255, 1)',
                        })}
                    >
                        {authoredByCoach ? coach?.name : comment.author.email}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: 'rgba(255, 255, 255, .4)',
                        }}
                    >
                        {format(
                            new Date(comment.createdAt),
                            'dd.MM.yyyy HH:mm',
                            {
                                timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                            }
                        )}{' '}
                    </Typography>
                </Stack>
                <Stack direction="row" gap="5px">
                    <FormatQuote
                        sx={{
                            color: 'rgba(255, 255, 255, .2)',
                        }}
                    />
                    <Editor
                        uploadedFiles={formatUploadedItemFromUrl(
                            comment.attachments || []
                        )}
                        editable={false}
                        content={comment.content}
                        sx={{
                            '& [data-class="attachment-item"]': {
                                width: '45px',
                                height: '45px',
                                '& img': {
                                    height: '45px !important',
                                },
                            },
                        }}
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack
            sx={{
                gap: '15px',
                borderRadius: '12px',
                border: readonly ? '1px solid #414141' : '1px solid #4c4b4b',
                overflow: 'hidden',
            }}
        >
            <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                sx={{
                    ...(readonly && {
                        opacity: 0.4,
                    }),
                    padding: '5px 15px',
                    background: 'rgba(60, 60, 60, 1)',
                    borderRadius: '12px 12px 0 0',
                }}
            >
                <Typography
                    fontSize="16px"
                    lineHeight="40px"
                    color="rgba(255, 255, 255, .5)"
                >
                    {empty ? 'Нема коментарiв' : 'Коментарi'}
                </Typography>
            </Stack>
            {comments.length > 0 && (
                <Stack gap="15px" divider={<Divider />} padding="15px">
                    {!empty && comments.map(renderComment)}
                </Stack>
            )}

            <Stack padding="0 15px 15px">
                <Stack direction="row" alignItems="flex-end" gap="15px">
                    {!readonly && (
                        <Editor
                            content={newCommentContent}
                            bordered
                            editable
                            toolbar={TOOLBAR_VARIANTS.ATTACHMENT}
                            onChange={setNewCommentContent}
                            onFileChange={handleFileUploadChange}
                            uploadedFiles={newAttachments}
                        />
                    )}
                    {!readonly && (
                        <IconButton
                            size="large"
                            color="info"
                            onClick={() =>
                                onSendComment(
                                    submissionId,
                                    newCommentContent,
                                    newAttachments,
                                    () => {
                                        setNewCommentContent('')
                                        setNewAttachments([])
                                    }
                                )
                            }
                        >
                            <Send />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default SubmissionComments
