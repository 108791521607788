import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    CourseListDocument,
    CourseCreateDocument,
    CourseCreateMutation,
    CourseDeleteDocument,
    CourseDeleteMutation,
    CourseUpdateDocument,
    CourseUpdateMutation,
    LessonCreateDocument,
    LessonCreateMutation,
    LessonDeleteDocument,
    LessonDeleteMutation,
    LessonUpdateDocument,
    LessonUpdateMutation,
    ModuleCreateDocument,
    ModuleCreateMutation,
    ModuleDeleteDocument,
    ModuleDeleteMutation,
    ModuleUpdateDocument,
    ModuleUpdateMutation,
    MutationCourseCreateArgs,
    MutationCourseDeleteArgs,
    MutationCourseUpdateArgs,
    MutationLessonCreateArgs,
    MutationLessonDeleteArgs,
    MutationLessonUpdateArgs,
    MutationModuleCreateArgs,
    MutationModuleDeleteArgs,
    MutationModuleUpdateArgs,
    CourseDocument,
    GrantLessonAccessDocument,
    MutationGrantLessonAccessArgs,
    GrantLessonAccessMutation,
    ModuleType,
    ClientDocument,
    CourseAccessDeleteDocument,
    MutationCourseAccessDeleteArgs,
    CourseAccessDeleteMutation,
} from 'api/root/generated'

const useCourseEntityAPI = (id?: string) => {
    const [courseUpdate, { loading: updatingCourse }] =
        useMutation(CourseUpdateDocument)
    const [courseCreate, { loading: creatingCourse }] =
        useMutation(CourseCreateDocument)
    const [courseDelete, { loading: deletingCourse }] =
        useMutation(CourseDeleteDocument)
    const [moduleUpdate, { loading: updatingModule }] =
        useMutation(ModuleUpdateDocument)
    const [moduleCreate, { loading: creatingModule }] =
        useMutation(ModuleCreateDocument)
    const [moduleDelete, { loading: deletingModule }] =
        useMutation(ModuleDeleteDocument)
    const [lessonUpdate, { loading: updatingLesson }] =
        useMutation(LessonUpdateDocument)
    const [lessonCreate, { loading: creatingLesson }] =
        useMutation(LessonCreateDocument)
    const [lessonDelete, { loading: deletingLesson }] =
        useMutation(LessonDeleteDocument)
    const [accessGrant, { loading: grantingAccess }] = useMutation(
        GrantLessonAccessDocument
    )
    const [accessDelete, { loading: deletingAccess }] = useMutation(
        CourseAccessDeleteDocument
    )

    const { data, loading, error, refetch } = useQuery(CourseDocument, {
        ...(id && {
            variables: {
                id,
            },
        }),
        skip: !id,
    })

    let course
    let modules
    let hasContent

    if (data?.course) {
        course = data.course
        modules = data.course?.modules?.edges.map(e => e?.node) as ModuleType[]
        hasContent = modules.length > 0
    }

    const updateLesson = (data: MutationLessonUpdateArgs) => {
        return lessonUpdate({ variables: data }).then(
            (response: FetchResult<LessonUpdateMutation>) => {
                return response?.data?.lessonUpdate
            }
        )
    }

    const createLesson = (data: MutationLessonCreateArgs) => {
        return lessonCreate({ variables: data }).then(
            (response: FetchResult<LessonCreateMutation>) => {
                return response?.data?.lessonCreate
            }
        )
    }

    const removeLesson = (data: MutationLessonDeleteArgs) => {
        return lessonDelete({ variables: data }).then(
            (response: FetchResult<LessonDeleteMutation>) => {
                return response?.data?.lessonDelete
            }
        )
    }

    const updateModule = (data: MutationModuleUpdateArgs) =>
        moduleUpdate({ variables: data }).then(
            (response: FetchResult<ModuleUpdateMutation>) =>
                response?.data?.moduleUpdate
        )

    const createModule = (data: MutationModuleCreateArgs) =>
        moduleCreate({ variables: data }).then(
            (response: FetchResult<ModuleCreateMutation>) =>
                response?.data?.moduleCreate
        )

    const removeModule = (data: MutationModuleDeleteArgs) =>
        moduleDelete({ variables: data }).then(
            (response: FetchResult<ModuleDeleteMutation>) =>
                response?.data?.moduleDelete
        )

    const updateCourse = (data: MutationCourseUpdateArgs) => {
        return courseUpdate({ variables: data }).then(
            (response: FetchResult<CourseUpdateMutation>) => {
                return response?.data?.courseUpdate
            }
        )
    }

    const createCourse = (data: MutationCourseCreateArgs) => {
        return courseCreate({ variables: data }).then(
            (response: FetchResult<CourseCreateMutation>) => {
                return response?.data?.courseCreate
            }
        )
    }

    const removeCourse = (data: MutationCourseDeleteArgs, userId: string) => {
        return courseDelete({
            variables: data,
            refetchQueries: [
                {
                    query: CourseListDocument,
                    variables: {
                        userId,
                    },
                },
            ],
        }).then((response: FetchResult<CourseDeleteMutation>) => {
            return response?.data?.courseDelete
        })
    }

    const grantAccess = (
        data: MutationGrantLessonAccessArgs,
        clientId?: string
    ) => {
        return accessGrant({
            variables: data,
            ...(clientId && {
                refetchQueries: [
                    {
                        query: ClientDocument,
                        variables: {
                            clientId,
                        },
                    },
                ],
            }),
        }).then((response: FetchResult<GrantLessonAccessMutation>) => {
            return response?.data?.grantLessonAccess
        })
    }

    const deleteAccess = (data: MutationCourseAccessDeleteArgs) => {
        return accessDelete({
            variables: data,
        }).then((response: FetchResult<CourseAccessDeleteMutation>) => {
            return response?.data?.courseAccessDelete
        })
    }

    return {
        course,
        modules,
        hasContent,
        error,
        refetch,
        courseLoading:
            loading || creatingCourse || updatingCourse || deletingCourse,
        moduleLoading: creatingModule || updatingModule || deletingModule,
        lessonLoading: creatingLesson || updatingLesson || deletingLesson,
        accessLoading: grantingAccess || deletingAccess,
        updateModule,
        createModule,
        removeModule,
        updateCourse,
        createCourse,
        removeCourse,
        updateLesson,
        createLesson,
        removeLesson,
        grantAccess,
        deleteAccess,
    }
}

export default useCourseEntityAPI
