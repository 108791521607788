import React, { JSX } from 'react'

import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { CallSplitRounded } from '@mui/icons-material'
import { Button, Divider, Stack, useMediaQuery, useTheme } from '@mui/material'

import { ActionBar } from '@backoffice/components/common'
import { CourseType, LessonType, ModuleType } from 'api/root/generated'
import { CourseForm } from '@backoffice/components/forms'
import useCourseEntityAPI from '@backoffice/hooks/course'
import { useDialog } from 'hooks/common'
import useConfirmationDialog from '@backoffice/hooks/common/useConfirmationDialog'
import { paths as backofficePaths } from '@backoffice/index.routes'

interface CourseActionBarProps {
    course: CourseType
    variant?: 'menu' | 'inline'
}

const CourseActionBar = ({
    course,
    variant = 'inline',
}: CourseActionBarProps) => {
    const navigate = useNavigate()

    const { open, close } = useDialog()
    const { enqueueSnackbar } = useSnackbar()

    const { refetch, removeCourse } = useCourseEntityAPI(course.id)
    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const handleDeleteCourse = () => {
        removeCourse(
            {
                input: {
                    courseId: course?.id as string,
                },
            },
            course?.coach?.id as string
        ).then(response => {
            if (response?.success) {
                close()
                enqueueSnackbar(
                    t('backoffice:content.course.notifications.successDeleted'),
                    { variant: 'success' }
                )
                navigate(`../${backofficePaths.content}`)
            }
        })
    }

    const handleEditCourse = () => {
        open({
            component: CourseForm,
            props: {
                onSuccess: (action: 'add' | 'update') => {
                    close()
                    refetch()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
                onDelete: () => {
                    close()
                    requestConfirmation(
                        course as CourseType,
                        'course',
                        handleDeleteCourse
                    )
                },
                course,
            },
            options: {
                onClose: close,
            },
        })
    }

    return (
        <ActionBar
            variant={variant}
            size="large"
            actions={[
                {
                    type: 'update',
                    key: 'update',
                    title: t('backoffice:content.course.actions.edit'),
                    onClick: handleEditCourse,
                },
            ]}
            extra={[
                {
                    title: t('backoffice:content.course.actions.streams'),
                    onClick: () =>
                        navigate(
                            `../${backofficePaths.courseStream(course.id)}`
                        ),
                    icon: <CallSplitRounded />,
                    key: 'streams',
                },
            ]}
        />
    )
}

export default CourseActionBar
