import React from 'react'

import { Stack } from '@mui/material'
import { t } from 'i18next'

import { PaymentMethodsType, UserType } from 'api/root/generated'
import Account from './components/Account'
import Socials from './components/Socials'
import Profile from './components/Profile'
import Payment from './components/Payment'
import { PageLayout } from '@backoffice/components/common'
import { SettingsPageProps } from './index.types'

const SettingsPageDesktop = ({
    data,
    onBack,
    onPaymentMethodClick,
    onEditSocialsClick,
}: SettingsPageProps) => {
    return (
        <PageLayout
            pageTitle={`${t('backoffice:pageTitles.backoffice')} | ${t(
                'backoffice:pageTitles.settingsPage'
            )}`}
            onBack={onBack}
            title={t('backoffice:settings.title') as string}
            sx={{
                gap: '35px',
            }}
        >
            <Stack gap="35px">
                <Profile user={data as UserType} />
                <Socials
                    onEditClick={onEditSocialsClick}
                    user={data as UserType}
                />
                <Payment
                    paymentData={data.paymentMethods as PaymentMethodsType}
                    onPaymentMethodClick={onPaymentMethodClick}
                />
                <Account user={data as UserType} />
            </Stack>
        </PageLayout>
    )
}
export default SettingsPageDesktop
