import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    IconButton,
    Divider,
    Box,
    useTheme,
    Grid2,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import {
    AccountCircle,
    Add,
    ArrowCircleRightOutlined,
} from '@mui/icons-material'

import { Image } from 'components'
import Widget from './components/Widget'
import noBackground from 'assets/images/no-background.png'
import { AccessMenu, PageLayout } from '@backoffice/components/common'
import { CourseType } from 'api/root/generated'
import { DashboardPageProps } from '@backoffice/components/pages/NewDashboardPage/index.types'
import StatisticsWidget from './components/StatisticsWidget'

const DashboardPageDesktop = ({
    onAddContentClick,
    onMoreContentClick,
    onMoreAccessClick,
    onAddAccessClick,
    courses,
    clients,
    submissions,
    coursesLoading,
    clientsLoading,
    onCourseClick,
    onClientClick,
    onSubmissionClick,
    submissionsLoading,
}: DashboardPageProps): JSX.Element => {
    const theme = useTheme()
    const smallDesktop = useMediaQuery(theme.breakpoints.down('lg'))

    return (
        <PageLayout
            pageTitle={t('backoffice:pageTitles.dashboardPage')}
            title={t('backoffice:pageTitles.dashboardPage')}
            sx={{
                gap: '50px',
            }}
        >
            <Grid2 container spacing={2}>
                {/* <Grid2 size={{ xs: smallDesktop ? 6 : 12 }}> */}
                {/*    <StatisticsWidget */}
                {/*        data={{ */}
                {/*            clientsCount: 12, */}
                {/*            coursesCount: 3, */}
                {/*            viewsCount: 1002, */}
                {/*            lessonCount: 55, */}
                {/*        }} */}
                {/*    /> */}
                {/* </Grid2> */}
                <Grid2
                    size={{
                        xs: smallDesktop ? 6 : 4,
                    }}
                >
                    <Widget
                        count={courses.length || 5}
                        width="100%"
                        onMoreClick={onMoreContentClick}
                        loading={coursesLoading}
                        actions={[
                            <IconButton
                                key="add"
                                onClick={onAddContentClick}
                                color="primary"
                                size="medium"
                            >
                                <Add />
                            </IconButton>,
                        ]}
                        title={t('backoffice:plates.content')}
                        content={() => (
                            <Stack divider={<Divider />} gap="10px">
                                {courses.map(course => (
                                    <Stack
                                        key={course.id}
                                        onClick={() => onCourseClick(course)}
                                        sx={{
                                            position: 'relative',
                                            cursor: 'pointer',
                                            padding: '10px 10px',
                                            borderRadius: '16px',
                                            '&:hover': {
                                                background:
                                                    'rgba(255, 255, 255, .1)',
                                                '.MuiSvgIcon-root[data-action="go-to"]':
                                                    {
                                                        opacity: 1,
                                                    },
                                            },
                                        }}
                                        direction="row"
                                        alignItems="center"
                                        gap="15px"
                                    >
                                        <Image
                                            sx={{
                                                minWidth: '38px',
                                                width: '38px',
                                                height: '38px',
                                                objectFit: 'cover',
                                                borderRadius: '4px',
                                            }}
                                            src={
                                                course.appearance?.background
                                                    ?.img60x40 || noBackground
                                            }
                                        />
                                        <Typography
                                            noWrap
                                            sx={{
                                                maxWidth: '300px',
                                                fontSize: '16px',
                                                lineHeight: '18px',
                                            }}
                                        >
                                            {course.name}
                                        </Typography>
                                        <ArrowCircleRightOutlined
                                            data-action="go-to"
                                            sx={{
                                                opacity: 0,
                                                transition: '0.3s ease',
                                                position: 'absolute',
                                                right: '5px',
                                                color: 'rgba(255, 255, 255, .3)',
                                            }}
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    />
                </Grid2>
                <Grid2
                    size={{
                        xs: smallDesktop ? 6 : 4,
                    }}
                >
                    <Widget
                        count={clients.length || 5}
                        width="100%"
                        onMoreClick={onMoreAccessClick}
                        loading={clientsLoading}
                        actions={[
                            <AccessMenu<CourseType>
                                size="medium"
                                key="access"
                                compact
                                onSelect={onAddAccessClick}
                                items={courses}
                            />,
                        ]}
                        title={t('backoffice:plates.clients')}
                        content={() => (
                            <Stack divider={<Divider />} gap="10px">
                                {clients.map(client => (
                                    <Stack
                                        key={client.id}
                                        onClick={() => onClientClick(client)}
                                        sx={{
                                            position: 'relative',
                                            cursor: 'pointer',
                                            padding: '10px',
                                            borderRadius: '16px',
                                            '&:hover': {
                                                background:
                                                    'rgba(255, 255, 255, .1)',
                                                '.MuiSvgIcon-root[data-action="go-to"]':
                                                    {
                                                        opacity: 1,
                                                    },
                                            },
                                        }}
                                        direction="row"
                                        alignItems="center"
                                        gap="15px"
                                    >
                                        <AccountCircle
                                            sx={{
                                                fontSize: '38px',
                                                color: 'rgba(255, 255, 255, .1)',
                                            }}
                                        />
                                        <Typography
                                            noWrap
                                            sx={{
                                                maxWidth: '200px',
                                                fontSize: '16px',
                                                lineHeight: '18px',
                                            }}
                                        >
                                            {client.email}
                                        </Typography>
                                        <ArrowCircleRightOutlined
                                            data-action="go-to"
                                            sx={{
                                                opacity: 0,
                                                transition: '0.3s ease',
                                                position: 'absolute',
                                                right: '5px',
                                                color: 'rgba(255, 255, 255, .3)',
                                            }}
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    />
                </Grid2>
                <Grid2
                    size={{
                        xs: smallDesktop ? 6 : 4,
                    }}
                >
                    <Widget
                        count={submissions.length || 5}
                        width="100%"
                        onMoreClick={onMoreAccessClick}
                        loading={submissionsLoading}
                        hideFooter
                        title={t('backoffice:plates.submissions')}
                        content={() => (
                            <Stack divider={<Divider />} gap="10px">
                                {submissions.map(submission => (
                                    <Stack
                                        key={submission.id}
                                        onClick={() =>
                                            onSubmissionClick(submission)
                                        }
                                        sx={{
                                            position: 'relative',
                                            cursor: 'pointer',
                                            padding: '10px',
                                            borderRadius: '16px',
                                            '&:hover': {
                                                background:
                                                    'rgba(255, 255, 255, .1)',
                                                '.MuiSvgIcon-root[data-action="go-to"]':
                                                    {
                                                        opacity: 1,
                                                    },
                                            },
                                        }}
                                        direction="row"
                                        alignItems="center"
                                        gap="15px"
                                    >
                                        <Stack gap="2px">
                                            <Stack
                                                direction="row"
                                                gap="5px"
                                                alignItems="center"
                                            >
                                                <Box
                                                    sx={theme => ({
                                                        borderRadius: '50%',
                                                        width: '7px',
                                                        height: '7px',
                                                        background:
                                                            submission.status
                                                                ?.val ===
                                                            'Pending'
                                                                ? theme.palette
                                                                      .warning
                                                                      .main
                                                                : submission
                                                                      .status
                                                                      ?.val ===
                                                                  'Rejected'
                                                                ? theme.palette
                                                                      .error
                                                                      .main
                                                                : theme.palette
                                                                      .success
                                                                      .main,
                                                    })}
                                                />
                                                <Typography
                                                    noWrap
                                                    sx={{
                                                        maxWidth: '300px',
                                                        fontSize: '16px',
                                                        lineHeight: '18px',
                                                    }}
                                                >
                                                    {submission.client.email}
                                                </Typography>
                                            </Stack>

                                            <Typography
                                                color="secondary.dark"
                                                noWrap
                                                sx={{
                                                    maxWidth: '300px',
                                                    fontSize: '14px',
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {submission.homework.title}
                                            </Typography>
                                        </Stack>

                                        <ArrowCircleRightOutlined
                                            data-action="go-to"
                                            sx={{
                                                opacity: 0,
                                                transition: '0.3s ease',
                                                position: 'absolute',
                                                right: '5px',
                                                color: 'rgba(255, 255, 255, .3)',
                                            }}
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    />
                </Grid2>
            </Grid2>
        </PageLayout>
    )
}
export default DashboardPageDesktop
