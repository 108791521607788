import React, { JSX, useState } from 'react'

import {
    Button,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { useDropzone } from 'react-dropzone'
import { FileUpload } from '@mui/icons-material'

import { Input } from '../index'
import {
    MAX_PHOTO_SIZE,
    MAX_VIDEO_SIZE,
    PHOTO_ACCEPT_EXTENSIONS,
    VIDEO_ACCEPT_EXTENSIONS,
} from 'config/user.config'

export interface FilePickerProps {
    title: string
    value: string | null | undefined
    onChange: (value: File | null, preview: string) => void
    type?: string
}

const FilePicker = ({
    title,
    onChange,
    value,
    type = 'image',
}: FilePickerProps): JSX.Element => {
    const [fileName, setFileName] = useState('')
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.up('xs'))

    const handleSelect = (file: File[]) => {
        const preview = URL.createObjectURL(file[0])
        onChange(file[0], preview)
        setFileName(file[0].name)
    }

    const { getInputProps } = useDropzone({
        maxFiles: 1,
        maxSize: type === 'image' ? MAX_PHOTO_SIZE : MAX_VIDEO_SIZE,
        accept:
            type === 'image'
                ? PHOTO_ACCEPT_EXTENSIONS
                : VIDEO_ACCEPT_EXTENSIONS,
        onDrop: handleSelect,
        noClick: true,
        noKeyboard: true,
    })

    return (
        <Stack
            direction="row"
            gap="10px"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
        >
            <Stack>
                <Typography>{title}</Typography>
                {fileName && (
                    <Typography fontSize="12px" noWrap maxWidth="150px">
                        {fileName}
                    </Typography>
                )}
            </Stack>

            <Button
                component="label"
                variant="outlined"
                startIcon={<FileUpload />}
                color="secondary"
                size={mobile ? 'medium' : 'small'}
            >
                {value
                    ? t('common:actions.changeFile')
                    : t('common:actions.selectFile')}
                <Input
                    type="file"
                    {...getInputProps()}
                    sx={{
                        clip: 'rect(0 0 0 0)',
                        clipPath: 'inset(50%)',
                        height: 1,
                        overflow: 'hidden',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        whiteSpace: 'nowrap',
                        width: 1,
                    }}
                />
            </Button>
        </Stack>
    )
}

export default FilePicker
