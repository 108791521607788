import React, { JSX, PropsWithChildren } from 'react'

import { Button, Stack, SxProps, Typography } from '@mui/material'
import { KeyboardArrowRightRounded } from '@mui/icons-material'
import { useInView } from 'react-intersection-observer'

export interface ScrollableToolbarItem {
    onClick?: () => void
    label: string
    key: string
    startIcon?: JSX.Element
    endIcon?: JSX.Element
    variant?: 'success' | 'error' | 'default' | 'warning' | 'info'
    disabled?: boolean
    active?: boolean
}

interface ScrollableToolbarProps extends PropsWithChildren {
    actions?: ScrollableToolbarItem[]
    sx?: SxProps
}

const ScrollableToolbar = ({
    actions = [],
    sx = {},
    children,
}: ScrollableToolbarProps) => {
    return (
        <Stack
            sx={{
                position: 'relative',
            }}
        >
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center',
                    paddingRight: '1px',
                    gap: '15px',
                    overflowX: 'auto',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    ...sx,
                }}
            >
                {children}
                {actions.map((action, index, array) => (
                    <Button
                        disabled={action.disabled || false}
                        key={action.key}
                        onClick={action.onClick}
                        startIcon={action.startIcon}
                        endIcon={action.endIcon}
                        sx={theme => ({
                            backgroundColor:
                                action.variant === 'success'
                                    ? theme.palette.success.main
                                    : action.variant === 'error'
                                    ? theme.palette.error.main
                                    : action.active
                                    ? theme.palette.primary.main
                                    : 'rgba(255, 255, 255, .6)',
                            borderRadius: '6px',
                            color: theme.palette.background.paper,
                            padding: '4px 12px',
                            minWidth: 'max-content',
                        })}
                    >
                        <Typography
                            sx={theme => ({
                                fontSize: '14px',
                                width: 'fit-content',
                            })}
                        >
                            {action.label}
                        </Typography>
                    </Button>
                ))}
            </Stack>
        </Stack>
    )
}

export default ScrollableToolbar
