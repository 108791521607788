import React from 'react'

import { Divider, Stack, Typography } from '@mui/material'

import { CourseType } from 'api/root/generated'
import { Image } from 'components'
import noBackground from 'assets/images/no-background.png'

interface ContentListProps {
    courses: CourseType[]
    onSelect: (course: CourseType) => void
}

const ContentList = ({ courses, onSelect }: ContentListProps) => {
    return (
        <Stack padding="25px" gap="15px">
            <Stack justifyContent="center">
                <Typography textAlign="center">
                    Выберите курс для нового доступа
                </Typography>
            </Stack>
            <Stack divider={<Divider />}>
                {courses?.map((course: CourseType) => {
                    return (
                        <Stack
                            key={course.id}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            padding="10px 5px"
                            onClick={() => onSelect(course)}
                        >
                            <Image
                                sx={{
                                    minWidth: '32px',
                                    width: '32px',
                                    height: '32px',
                                    objectFit: 'cover',
                                    borderRadius: '4px',
                                }}
                                src={
                                    course.appearance?.background?.img150x100 ||
                                    noBackground
                                }
                            />
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {course.name}
                            </Typography>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}

export default ContentList
