import React, { useEffect, useState } from 'react'

import { InputAdornment, SxProps, TextField } from '@mui/material'
import { Close, Search } from '@mui/icons-material'

import search from 'assets/images/search.svg'
import { Image } from 'components'
import { useDebounced } from 'hooks/common'

interface SearchFieldProps {
    placeholder?: string
    onChange?: (text: string) => void
    sx?: SxProps
    light?: boolean
}

const SearchFieldMobile = ({
    onChange = () => {},
    placeholder = 'пв',
    sx = {},
    light = false,
}: SearchFieldProps) => {
    const [searchText, setSearchText] = useState('')

    const debouncedSearchText = useDebounced(searchText, 500)

    useEffect(() => {
        onChange(debouncedSearchText)
    }, [debouncedSearchText])

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            variant="outlined"
            sx={sx}
            size="small"
            InputProps={{
                sx: theme => ({
                    background: light ? '#282828' : '#212121',
                    borderRadius: '18px',
                    paddingLeft: '15px',
                    borderColor: 'transparent',
                    fontSize: '14px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    '& .MuiInputBase-input': {
                        paddingLeft: 0,
                        borderColor: 'transparent',
                        '&:focus': {
                            boxShadow: `none`,
                            borderColor: 'transparent',
                        },
                    },
                    '& .MuiOutlinedInput-input': {
                        padding: '6.5px 14px',
                    },
                }),
                startAdornment: (
                    <InputAdornment position="start">
                        <Search
                            sx={{
                                color: 'rgba(255, 255, 255, 0.2)',
                                fontSize: '20px',
                            }}
                        />
                    </InputAdornment>
                ),
                ...(searchText.length > 0 && {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={() => setSearchText('')}
                            sx={{
                                cursor: 'pointer',
                            }}
                        >
                            <Close
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.2)',
                                    fontSize: '20px',
                                }}
                            />
                        </InputAdornment>
                    ),
                }),
            }}
        />
    )
}

export default SearchFieldMobile
