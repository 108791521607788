import React, { Dispatch, SetStateAction, useCallback } from 'react'

import {
    Button,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
    Checkbox,
    useTheme,
    IconButton,
} from '@mui/material'
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
} from '@mui/x-data-grid'
import { t } from 'i18next'
import { Add, AddCircleOutline, Quiz } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import {
    CourseType,
    HomeworkType,
    LessonType,
    ModuleType,
} from 'api/root/generated'
import ModuleActionBar from '@backoffice/components/pages/NewCoursePage/components/ModuleActionBar'
import { DataList } from '@backoffice/components/common'
import { Image } from 'components'
import noBackground from 'assets/images/no-background.png'
import { formatDuration } from 'utils/dates.utils'
import LessonActionBar from '@backoffice/components/pages/NewCoursePage/components/LessonActionBar'
import { paths as backofficePaths } from '@backoffice/index.routes'

export interface SelectedLessonType {
    moduleId: string
    lessonId: string
}

interface CourseContentProps {
    homeworks: HomeworkType[]
    selectable: boolean
    course: CourseType
    selectedLessons: SelectedLessonType[]
    onSelectedChange: Dispatch<SetStateAction<SelectedLessonType[]>>
    onNewLesson: (module: ModuleType) => void
}

const CourseContent = ({
    homeworks,
    selectable,
    onSelectedChange,
    course,
    selectedLessons,
    onNewLesson,
}: CourseContentProps) => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const mobile = useMediaQuery(theme.breakpoints.up('xs'))
    const navigate = useNavigate()

    const modules = course?.modules?.edges.map(e => e?.node) as ModuleType[]

    const desktopExtraColumns: GridColDef<LessonType>[] = [
        {
            field: 'duration',
            headerName: t('backoffice:course.table.columns.duration') as string,
            flex: 0.5,
            cellClassName: 'secondary readonly',
            rowSpanValueGetter: () => null,
            valueFormatter: value => formatDuration(value as number),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'viewsCount',
            headerName: t(
                'backoffice:course.table.columns.viewCount'
            ) as string,
            flex: 0.5,
            cellClassName: 'secondary readonly',
            rowSpanValueGetter: () => null,
            headerAlign: 'center',
            align: 'center',
        },
    ]

    const columns: GridColDef<LessonType>[] = [
        {
            field: '@',
            headerName: ' ',
            align: 'right',
            renderCell: (params: GridRenderCellParams<LessonType>) => {
                return (
                    <Image
                        sx={{
                            width: '60px',
                            height: '40px',
                            objectFit: 'cover',
                            verticalAlign: 'middle',
                        }}
                        src={params.row.videoPreview?.img60x40 || noBackground}
                    />
                )
            },
        },
        {
            field: 'name',
            headerName: t('backoffice:course.table.columns.name') as string,
            flex: 2,
            cellClassName: 'readonly',
        },
        ...(desktop ? desktopExtraColumns : []),
        {
            field: 'homework',
            headerName: t('backoffice:course.table.columns.homework') as string,
            flex: 1,
            cellClassName: 'secondary',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams<LessonType>) => {
                const homework = homeworks.find(
                    h => h.relationId === params.row.id
                )
                return (
                    <IconButton
                        color="secondary"
                        onClick={() =>
                            navigate(
                                `../${backofficePaths.homework}?category=lesson&relationId=${params.row.id}&homeworkId=${homework?.id}&title=${params.row.name}`
                            )
                        }
                    >
                        {homework ? (
                            <Quiz color="secondary" />
                        ) : (
                            <Add color="secondary" />
                        )}
                    </IconButton>
                )
            },
        },
        {
            field: '$',
            headerName: t('backoffice:course.table.columns.actions') as string,
            flex: 0.5,
            type: 'actions',
            rowSpanValueGetter: () => null,
            getActions: (params: GridRowParams<LessonType>) => {
                return [
                    <LessonActionBar
                        disabled={selectable}
                        lesson={params.row}
                        module={params.row.module}
                    />,
                ]
            },
        },
    ]

    const renderNewLessonButton = (module: ModuleType, first = false) => {
        return (
            <Button
                onClick={() => onNewLesson(module)}
                sx={{
                    width: 'fit-content',
                    ...(!tablet && {
                        margin: '0 auto',
                    }),
                    '& .MuiSvgIcon-root': {
                        fontSize: '22px',
                    },
                }}
                variant="text"
                color="secondary"
                startIcon={<AddCircleOutline />}
            >
                <Typography fontSize="16px">
                    {first
                        ? t(
                              'backoffice:content.course.lesson.actions.createFirst'
                          )
                        : t('backoffice:content.course.lesson.actions.add')}
                </Typography>
            </Button>
        )
    }

    const renderEmptyModuleScreen = (module: ModuleType) => {
        return (
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                gap="10px"
            >
                {selectable ? (
                    <Typography fontSize="16px" color="secondary.dark">
                        {t('backoffice:content.course.lesson.noContentCaption')}
                    </Typography>
                ) : (
                    renderNewLessonButton(module, true)
                )}
            </Stack>
        )
    }

    const renderListItem = useCallback(
        (item: LessonType) => {
            console.log(selectedLessons)
            const preselected = !!selectedLessons.find(
                l => l.lessonId === item.id
            )

            return (
                <Stack
                    key={item.id}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="15px"
                    sx={() => ({
                        background:
                            selectable && preselected
                                ? 'rgba(255, 255, 255, .05)'
                                : 'transparent',
                    })}
                    onClick={() => {
                        if (selectable) {
                            onSelectedChange(prevState => {
                                if (preselected) {
                                    return prevState.filter(
                                        l => l.lessonId !== item.id
                                    )
                                }
                                return [
                                    ...prevState,
                                    {
                                        lessonId: item.id,
                                        moduleId: item.module.id,
                                    },
                                ]
                            })
                        }
                    }}
                >
                    <Stack direction="row" alignItems="center" gap="15px">
                        {selectable ? (
                            <Checkbox
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '32px',
                                    },
                                }}
                                checked={preselected}
                            />
                        ) : (
                            <Image
                                sx={{
                                    width: mobile ? '60px' : '40px',
                                    height: mobile ? '40px' : '25px',
                                    objectFit: 'cover',
                                }}
                                src={
                                    item.videoPreview?.img60x40 || noBackground
                                }
                            />
                        )}

                        <Typography
                            noWrap
                            maxWidth={
                                tablet ? '80%' : mobile ? '240px' : '180px'
                            }
                            sx={{
                                fontSize: mobile ? '20px' : '16px',
                                lineHeight: mobile ? '20px' : '16px',
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Stack>
                    {!selectable && (
                        <LessonActionBar
                            disabled={selectable}
                            lesson={item}
                            module={item.module}
                        />
                    )}
                </Stack>
            )
        },
        [selectedLessons, selectable]
    )

    const renderContent = (module: ModuleType, lessons: LessonType[]) => {
        return tablet ? (
            <DataGrid
                autoHeight
                rowSelectionModel={selectedLessons
                    .filter(sl => sl.moduleId === module.id)
                    .map(sl => sl.lessonId)}
                onRowSelectionModelChange={newRowSelectionModel => {
                    onSelectedChange(prevState => {
                        const selectedLessonsFromOtherModules =
                            prevState.filter(l => l.moduleId !== module.id)
                        const newSelectedLessons = newRowSelectionModel.map(
                            s => ({
                                moduleId: module.id,
                                lessonId: s as string,
                            })
                        )
                        return [
                            ...selectedLessonsFromOtherModules,
                            ...newSelectedLessons,
                        ]
                    })
                }}
                checkboxSelection={selectable}
                sx={{
                    '& .MuiDataGrid-columnHeader': {
                        '&.MuiDataGrid-withBorderColor': {
                            borderColor: 'rgba(255, 255, 255, 0)',
                        },
                    },
                }}
                rows={lessons}
                columns={columns}
            />
        ) : (
            <DataList<LessonType>
                gap={tablet ? '25px' : mobile ? '20px' : '15px'}
                items={lessons}
                renderItem={item => renderListItem(item as LessonType)}
            />
        )
    }

    return (
        <Stack gap="50px" divider={<Divider flexItem />}>
            {modules?.map((m, i) => {
                const lessons = m?.lessons?.edges.map(
                    e => e?.node
                ) as LessonType[]

                return (
                    <Stack gap="25px" key={m.id}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                gap="10px"
                                flexGrow={1}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: tablet
                                            ? '22px'
                                            : mobile
                                            ? '20px'
                                            : '18px',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'rgba(255, 255, 255, .2)',
                                    }}
                                >
                                    {i + 1}.
                                </Typography>
                                <Typography
                                    noWrap
                                    sx={theme => ({
                                        maxWidth: tablet
                                            ? '80%'
                                            : mobile
                                            ? '300px'
                                            : '230px',
                                        fontWeight: 700,
                                        color: theme.palette.secondary.dark,
                                    })}
                                >
                                    {m.name}
                                </Typography>
                            </Stack>

                            {!selectable && (
                                <ModuleActionBar
                                    disabled={selectable}
                                    course={course as CourseType}
                                    module={m}
                                />
                            )}
                        </Stack>

                        {lessons.length > 0
                            ? renderContent(m, lessons)
                            : renderEmptyModuleScreen(m)}
                        {lessons.length !== 0 &&
                            !selectable &&
                            renderNewLessonButton(m)}
                    </Stack>
                )
            })}
        </Stack>
    )
}

export default CourseContent
