import React, { useState } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    TextField,
    Stack,
    Typography,
    Button,
    FormControl,
    InputLabel,
    InputBase,
    OutlinedInput,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import { PanoramaOutlined } from '@mui/icons-material'

import { FilePicker } from 'components'
import { CourseFormScheme, CourseFormValues } from './index.schema'
import { CourseType } from 'api/root/generated'
import { useCoursesAPI } from 'hooks/courses'

interface CourseFormProps {
    onSuccess: (action: 'add' | 'update', id?: string) => void
    onDelete: () => void
    course?: Partial<CourseType>
}

const CourseForm = ({ onSuccess, course, onDelete }: CourseFormProps) => {
    // const [background, setBackground] = useState<File | null>(null)

    const { create, update, loading } = useCoursesAPI()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: course
            ? {
                  name: course.name || '',
                  description: course.description || '',
              }
            : {
                  name: '',
                  description: '',
              },
        validationSchema: CourseFormScheme,
        onSubmit: (values: CourseFormValues) => {
            const { name, description } = values
            if (course) {
                update({
                    input: {
                        courseId: course.id as string,
                        name,
                        description,
                        // ...(background && { background }),
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('update')
                    }
                })
            } else {
                create({
                    input: {
                        name,
                        description,
                        // ...(background && { background }),
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('add', response.course?.id as string)
                    }
                })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                minWidth: 'min(80vw, 500px)',
                ...(tablet && {
                    maxWidth: '500px',
                }),
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize={tablet ? '20px' : '16px'}
                            textAlign={tablet ? 'left' : 'center'}
                        >
                            {course
                                ? t('backoffice:content.course.editTitle')
                                : t('backoffice:content.course.createTitle')}
                        </Typography>
                    </Stack>
                    <Stack gap="25px">
                        <TextField
                            variant="outlined"
                            name="name"
                            placeholder={
                                t(
                                    'backoffice:content.course.fields.name'
                                ) as string
                            }
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            name="description"
                            placeholder={
                                t(
                                    'backoffice:content.course.fields.description'
                                ) as string
                            }
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            rows={tablet ? 14 : 10}
                            multiline
                            error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                            }
                            helperText={
                                formik.touched.description &&
                                formik.errors.description
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                    </Stack>
                    <Stack gap="10px">
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            color="success"
                            sx={{ padding: tablet ? '15px' : '10px' }}
                            loading={loading}
                        >
                            <Typography>
                                {course
                                    ? t(
                                          'backoffice:content.course.actions.save'
                                      )
                                    : t(
                                          'backoffice:content.course.actions.create'
                                      )}
                            </Typography>
                        </Button>
                        {course && (
                            <Button
                                loading={loading}
                                sx={{ padding: '10px' }}
                                color="error"
                                variant="text"
                                fullWidth
                                onClick={() => onDelete()}
                            >
                                <Typography>
                                    {t(
                                        'backoffice:content.course.actions.delete'
                                    )}
                                </Typography>
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default CourseForm
