import React, { JSX } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { CourseForm } from '@backoffice/components/forms'
import { useDialog, useDrawer } from 'hooks/common'
import { useCourses } from 'hooks/courses'
import { paths } from '@backoffice/index.routes'
import ContentPageMobile from './index.mobile'
import ContentPageDesktop from './index.desktop'

const ContentPage = (): JSX.Element => {
    const navigate = useNavigate()
    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()

    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const { courses, refetch: refetchCourses, loading } = useCourses()

    const handleOpenCreateCourseModal = () => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer

        openModal({
            component: CourseForm,
            props: {
                onSuccess: (action: 'add' | 'update', id: string) => {
                    closeModal()
                    refetchCourses()
                    navigate(`../${paths.course(id)}`)
                },
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleContentClick = (id: string) => {
        navigate(`../${paths.course(id)}`)
    }

    const handleBack = () => {
        navigate(`../${paths.backoffice}`)
    }

    return tablet ? (
        <ContentPageDesktop
            onBack={handleBack}
            loading={loading}
            courses={courses}
            onAdd={handleOpenCreateCourseModal}
            onClick={handleContentClick}
        />
    ) : (
        <ContentPageMobile
            onRefetchCourses={refetchCourses}
            onBack={handleBack}
            loading={loading}
            courses={courses}
            onAdd={handleOpenCreateCourseModal}
            onClick={handleContentClick}
        />
    )
}
export default ContentPage
