import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  GenericScalar: any;
  JSONString: any;
  SocialCamelJSON: any;
};

export type ActivateAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Mutation for requesting a password reset via email */
export type ActivateAccountPayload = {
  __typename?: 'ActivateAccountPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AppearanceType = {
  __typename?: 'AppearanceType';
  background?: Maybe<BackgroundType>;
  backgroundColor?: Maybe<Scalars['String']>;
  brandColor?: Maybe<Scalars['String']>;
  contentNameColor?: Maybe<Scalars['String']>;
  descriptionColor?: Maybe<Scalars['String']>;
  headerMarkup?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameColor?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  visibleSocials: Scalars['Boolean'];
};

export type AttachmentType = Node & {
  __typename?: 'AttachmentType';
  file: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type BackgroundType = {
  __typename?: 'BackgroundType';
  img60x40?: Maybe<Scalars['String']>;
  img150x100?: Maybe<Scalars['String']>;
  img500x300?: Maybe<Scalars['String']>;
  img800x400?: Maybe<Scalars['String']>;
  img1536x960?: Maybe<Scalars['String']>;
  img1920x1080?: Maybe<Scalars['String']>;
  img2560x1920?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
};

export type CategoryType = Node & {
  __typename?: 'CategoryType';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CategoryTypeConnection = {
  __typename?: 'CategoryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CategoryType` and its cursor. */
export type CategoryTypeEdge = {
  __typename?: 'CategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CategoryType>;
};

export type CheckEmailTokenLinkInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  uid: Scalars['String'];
};

/** Mutation for check reset password/email confirm link expiration */
export type CheckEmailTokenLinkPayload = {
  __typename?: 'CheckEmailTokenLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CourseAccessType = Node & {
  __typename?: 'CourseAccessType';
  /** The client who received the access */
  client: UserType;
  /** The coach who granted the access */
  coach: UserType;
  course: CourseType;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  lessons: LessonTypeConnection;
  lessonsCount?: Maybe<Scalars['Int']>;
  stream?: Maybe<CourseStreamType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CourseAccessTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CourseAccessTypeConnection = {
  __typename?: 'CourseAccessTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseAccessTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CourseAccessType` and its cursor. */
export type CourseAccessTypeEdge = {
  __typename?: 'CourseAccessTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseAccessType>;
};

export type CourseAppearanceType = {
  __typename?: 'CourseAppearanceType';
  background?: Maybe<BackgroundType>;
  backgroundColor?: Maybe<Scalars['String']>;
  brandColor?: Maybe<Scalars['String']>;
  course?: Maybe<CourseType>;
  descriptionColor?: Maybe<Scalars['String']>;
  headerMarkup?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lessonNameColor?: Maybe<Scalars['String']>;
  moduleDescriptionColor?: Maybe<Scalars['String']>;
  moduleNameColor?: Maybe<Scalars['String']>;
  nameColor?: Maybe<Scalars['String']>;
};

export type CourseStreamType = Node & {
  __typename?: 'CourseStreamType';
  access: CourseAccessTypeConnection;
  course: CourseType;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  schedule: Scalars['JSONString'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CourseStreamTypeAccessArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CourseStreamTypeConnection = {
  __typename?: 'CourseStreamTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseStreamTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CourseStreamType` and its cursor. */
export type CourseStreamTypeEdge = {
  __typename?: 'CourseStreamTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseStreamType>;
};

export type CourseType = Node & {
  __typename?: 'CourseType';
  accessCount?: Maybe<Scalars['Int']>;
  appearance?: Maybe<CourseAppearanceType>;
  categories: CategoryTypeConnection;
  coach: UserType;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  feedbackSessions?: Maybe<SessionTypeConnection>;
  hasIncompleteHomework?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  lessonCount?: Maybe<Scalars['Int']>;
  lessonIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  modules?: Maybe<ModuleTypeConnection>;
  name: Scalars['String'];
  streamCount?: Maybe<Scalars['Int']>;
  streams: CourseStreamTypeConnection;
  viewCount?: Maybe<Scalars['Int']>;
};


export type CourseTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseTypeFeedbackSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseTypeModulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type CourseTypeStreamsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type DeleteJsonWebTokenCookie = {
  __typename?: 'DeleteJSONWebTokenCookie';
  deleted: Scalars['Boolean'];
};

export type DeleteRefreshTokenCookie = {
  __typename?: 'DeleteRefreshTokenCookie';
  deleted: Scalars['Boolean'];
};

export type EmailConfirmInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  uid: Scalars['String'];
};

/** Mutation for email confirmation */
export type EmailConfirmPayload = {
  __typename?: 'EmailConfirmPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EmailSignIn = {
  __typename?: 'EmailSignIn';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  user?: Maybe<UserType>;
};

export type HomeworkSubmissionType = Node & {
  __typename?: 'HomeworkSubmissionType';
  attachments?: Maybe<Array<Maybe<AttachmentType>>>;
  client: UserType;
  comments?: Maybe<SubmissionCommentTypeConnection>;
  /** Client answer */
  content: Scalars['JSONString'];
  createdAt?: Maybe<Scalars['DateTime']>;
  homework: HomeworkType;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Reason for rejection */
  reason?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  status?: Maybe<KeyValType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type HomeworkSubmissionTypeCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  submissionId?: InputMaybe<Scalars['String']>;
};

export type HomeworkSubmissionTypeConnection = {
  __typename?: 'HomeworkSubmissionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HomeworkSubmissionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `HomeworkSubmissionType` and its cursor. */
export type HomeworkSubmissionTypeEdge = {
  __typename?: 'HomeworkSubmissionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<HomeworkSubmissionType>;
};

export type HomeworkType = Node & {
  __typename?: 'HomeworkType';
  attachments?: Maybe<Array<Maybe<AttachmentType>>>;
  category?: Maybe<KeyValType>;
  coach: UserType;
  content: Scalars['JSONString'];
  courseId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isMandatory: Scalars['Boolean'];
  relationId?: Maybe<Scalars['ID']>;
  submissions?: Maybe<HomeworkSubmissionTypeConnection>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type HomeworkTypeSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  homeworkId?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  unique?: InputMaybe<Scalars['Boolean']>;
};

export type KeyValType = {
  __typename?: 'KeyValType';
  key?: Maybe<Scalars['Int']>;
  val?: Maybe<Scalars['String']>;
};

export type LessonType = Node & {
  __typename?: 'LessonType';
  compressedVideoUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  hasAccess: Scalars['Boolean'];
  homework?: Maybe<HomeworkType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  isCompressed?: Maybe<Scalars['Boolean']>;
  isLast?: Maybe<Scalars['Boolean']>;
  isLastWatched?: Maybe<Scalars['Boolean']>;
  isProcessing?: Maybe<Scalars['Boolean']>;
  module: ModuleType;
  name: Scalars['String'];
  number: Scalars['Int'];
  videoPreview?: Maybe<VideoPreviewType>;
  videoUrl?: Maybe<Scalars['String']>;
  view?: Maybe<ViewType>;
  viewsCount?: Maybe<Scalars['Int']>;
};

export type LessonTypeConnection = {
  __typename?: 'LessonTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LessonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LessonType` and its cursor. */
export type LessonTypeEdge = {
  __typename?: 'LessonTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LessonType>;
};

export type Logout = {
  __typename?: 'Logout';
  success?: Maybe<Scalars['Boolean']>;
};

export type ModuleType = Node & {
  __typename?: 'ModuleType';
  course: CourseType;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  homework?: Maybe<HomeworkType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isLast?: Maybe<Scalars['Boolean']>;
  lessons: LessonTypeConnection;
  name: Scalars['String'];
  number: Scalars['Int'];
};


export type ModuleTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ModuleTypeConnection = {
  __typename?: 'ModuleTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ModuleTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ModuleType` and its cursor. */
export type ModuleTypeEdge = {
  __typename?: 'ModuleTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ModuleType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation for requesting a password reset via email */
  activateAccount?: Maybe<ActivateAccountPayload>;
  /** Mutation for check reset password/email confirm link expiration */
  checkEmailTokenLink?: Maybe<CheckEmailTokenLinkPayload>;
  deleteRefreshTokenCookie?: Maybe<DeleteRefreshTokenCookie>;
  deleteTokenCookie?: Maybe<DeleteJsonWebTokenCookie>;
  /** Mutation for email confirmation */
  emailConfirm?: Maybe<EmailConfirmPayload>;
  emailSignIn?: Maybe<EmailSignIn>;
  logout?: Maybe<Logout>;
  refreshToken?: Maybe<Refresh>;
  /** Mutation for requesting a password reset via email */
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Mutation for password reset confirm */
  resetPasswordConfirm?: Maybe<ResetPasswordConfirmPayload>;
  /** Mutation for sign ip user with a CLIENT role */
  signUp?: Maybe<SignUpPayload>;
  /** Story: User can auth via Google oAuth2 */
  socialAuth?: Maybe<SocialAuth>;
  verifyToken?: Maybe<Verify>;
};


export type MutationActivateAccountArgs = {
  input: ActivateAccountInput;
};


export type MutationCheckEmailTokenLinkArgs = {
  input: CheckEmailTokenLinkInput;
};


export type MutationEmailConfirmArgs = {
  input: EmailConfirmInput;
};


export type MutationEmailSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordConfirmArgs = {
  input: ResetPasswordConfirmInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSocialAuthArgs = {
  accessToken: Scalars['String'];
  provider: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentMethodPaymentService {
  /** WAYFORPAY */
  A_1 = 'A_1',
  /** MONOBANK */
  A_2 = 'A_2',
  /** STRIPE */
  A_3 = 'A_3',
  /** LIQPAY */
  A_4 = 'A_4'
}

export type PaymentMethodType = Node & {
  __typename?: 'PaymentMethodType';
  /** The ID of the object. */
  id: Scalars['ID'];
  payload: Scalars['JSONString'];
  paymentService: PaymentMethodPaymentService;
};

export type PaymentMethodsType = {
  __typename?: 'PaymentMethodsType';
  LIQPAY?: Maybe<PaymentMethodType>;
  MONOBANK?: Maybe<PaymentMethodType>;
  STRIPE?: Maybe<PaymentMethodType>;
  WAYFORPAY?: Maybe<PaymentMethodType>;
};

export type Query = {
  __typename?: 'Query';
  placeholder?: Maybe<Scalars['String']>;
};

export type QuestionType = Node & {
  __typename?: 'QuestionType';
  /** The ID of the object. */
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type QuestionTypeConnection = {
  __typename?: 'QuestionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuestionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `QuestionType` and its cursor. */
export type QuestionTypeEdge = {
  __typename?: 'QuestionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<QuestionType>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordConfirmInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['String'];
};

/** Mutation for password reset confirm */
export type ResetPasswordConfirmPayload = {
  __typename?: 'ResetPasswordConfirmPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  redirectTo?: InputMaybe<Scalars['String']>;
};

/** Mutation for requesting a password reset via email */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SessionType = Node & {
  __typename?: 'SessionType';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedbackCount?: Maybe<Scalars['Int']>;
  feedbackCountSkipped?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  questions: QuestionTypeConnection;
  /** The course which clients should be asked for a feedback */
  targetCourse?: Maybe<CourseType>;
};


export type SessionTypeQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SessionTypeConnection = {
  __typename?: 'SessionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SessionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SessionType` and its cursor. */
export type SessionTypeEdge = {
  __typename?: 'SessionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SessionType>;
};

export type SignUpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  redirectTo?: InputMaybe<Scalars['String']>;
};

/** Mutation for sign ip user with a CLIENT role */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Story: User can auth via Google oAuth2 */
export type SocialAuth = {
  __typename?: 'SocialAuth';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  refreshToken?: Maybe<Scalars['String']>;
  social?: Maybe<SocialType>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
};

export type SocialType = {
  __typename?: 'SocialType';
  created: Scalars['DateTime'];
  extraData?: Maybe<Scalars['SocialCamelJSON']>;
  id: Scalars['ID'];
  modified: Scalars['DateTime'];
  provider: Scalars['String'];
  uid: Scalars['String'];
  user: UserType;
};

export type SubmissionCommentType = Node & {
  __typename?: 'SubmissionCommentType';
  attachments?: Maybe<Array<Maybe<AttachmentType>>>;
  author: UserType;
  content?: Maybe<Scalars['JSONString']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<SubmissionCommentType>;
  replies: SubmissionCommentTypeConnection;
  submission: HomeworkSubmissionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SubmissionCommentTypeRepliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SubmissionCommentTypeConnection = {
  __typename?: 'SubmissionCommentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SubmissionCommentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SubmissionCommentType` and its cursor. */
export type SubmissionCommentTypeEdge = {
  __typename?: 'SubmissionCommentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SubmissionCommentType>;
};

export type UserType = Node & {
  __typename?: 'UserType';
  accesses?: Maybe<Array<Maybe<CourseAccessType>>>;
  appearance?: Maybe<AppearanceType>;
  /** Categories of courses that user was given access to */
  categories: CategoryTypeConnection;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Real user did set his own password */
  isActivated: Scalars['Boolean'];
  isEmailConfirmed?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<PaymentMethodsType>;
  role?: Maybe<KeyValType>;
  streams?: Maybe<Array<Maybe<CourseStreamType>>>;
  /** User subdmain like teacher.sp.com */
  subdomain?: Maybe<Scalars['String']>;
};


export type UserTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type VideoPreviewType = {
  __typename?: 'VideoPreviewType';
  img60x40?: Maybe<Scalars['String']>;
  img150x100?: Maybe<Scalars['String']>;
  img520x200?: Maybe<Scalars['String']>;
  img700x350?: Maybe<Scalars['String']>;
};

export type ViewType = Node & {
  __typename?: 'ViewType';
  durationMs?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  lesson?: Maybe<LessonType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserType;
};

export type ActivateAccountMutationVariables = Exact<{
  input: ActivateAccountInput;
}>;


export type ActivateAccountMutation = { __typename?: 'Mutation', activateAccount?: { __typename?: 'ActivateAccountPayload', success?: boolean | null, errors?: Array<string | null> | null } | null };

export type CheckEmailTokenLinkMutationVariables = Exact<{
  input: CheckEmailTokenLinkInput;
}>;


export type CheckEmailTokenLinkMutation = { __typename?: 'Mutation', checkEmailTokenLink?: { __typename?: 'CheckEmailTokenLinkPayload', success?: boolean | null, errors?: Array<string | null> | null } | null };

export type EmailConfirmMutationVariables = Exact<{
  input: EmailConfirmInput;
}>;


export type EmailConfirmMutation = { __typename?: 'Mutation', emailConfirm?: { __typename?: 'EmailConfirmPayload', success?: boolean | null, errors?: Array<string | null> | null } | null };

export type EmailSignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type EmailSignInMutation = { __typename?: 'Mutation', emailSignIn?: { __typename?: 'EmailSignIn', success?: boolean | null, errors?: Array<string | null> | null, user?: { __typename?: 'UserType', id: string, subdomain?: string | null } | null } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: { __typename?: 'Logout', success?: boolean | null } | null };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'Refresh', payload: any } | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ResetPasswordPayload', success?: boolean | null, errors?: Array<string | null> | null } | null };

export type ResetPasswordConfirmMutationVariables = Exact<{
  input: ResetPasswordConfirmInput;
}>;


export type ResetPasswordConfirmMutation = { __typename?: 'Mutation', resetPasswordConfirm?: { __typename?: 'ResetPasswordConfirmPayload', success?: boolean | null } | null };

export type VerifyTokenMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
}>;


export type VerifyTokenMutation = { __typename?: 'Mutation', verifyToken?: { __typename?: 'Verify', payload: any } | null };


export const ActivateAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"activateAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ActivateAccountInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activateAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<ActivateAccountMutation, ActivateAccountMutationVariables>;
export const CheckEmailTokenLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"checkEmailTokenLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CheckEmailTokenLinkInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkEmailTokenLink"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<CheckEmailTokenLinkMutation, CheckEmailTokenLinkMutationVariables>;
export const EmailConfirmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"emailConfirm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmailConfirmInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailConfirm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<EmailConfirmMutation, EmailConfirmMutationVariables>;
export const EmailSignInDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"emailSignIn"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailSignIn"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"subdomain"}}]}}]}}]}}]} as unknown as DocumentNode<EmailSignInMutation, EmailSignInMutationVariables>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Logout"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const RefreshTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"refreshToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payload"}}]}}]}}]} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPasswordInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetPasswordConfirmDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resetPasswordConfirm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPasswordConfirmInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPasswordConfirm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>;
export const VerifyTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"verifyToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"auth"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payload"}}]}}]}}]} as unknown as DocumentNode<VerifyTokenMutation, VerifyTokenMutationVariables>;