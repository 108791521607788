import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONString: any;
};

export type DeletePaymentMethodInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentService: Scalars['Int'];
};

export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentMethod?: Maybe<PaymentMethodType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  setUpPaymentMethod?: Maybe<SetUpPaymentMethodPayload>;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationSetUpPaymentMethodArgs = {
  input: SetUpPaymentMethodInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentMethodPaymentService {
  /** WAYFORPAY */
  A_1 = 'A_1',
  /** MONOBANK */
  A_2 = 'A_2',
  /** STRIPE */
  A_3 = 'A_3',
  /** LIQPAY */
  A_4 = 'A_4'
}

export type PaymentMethodType = Node & {
  __typename?: 'PaymentMethodType';
  /** The ID of the object. */
  id: Scalars['ID'];
  payload: Scalars['JSONString'];
  paymentService: PaymentMethodPaymentService;
};

export type PaymentMethodTypeConnection = {
  __typename?: 'PaymentMethodTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentMethodTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaymentMethodType` and its cursor. */
export type PaymentMethodTypeEdge = {
  __typename?: 'PaymentMethodTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PaymentMethodType>;
};

export type Query = {
  __typename?: 'Query';
  paymentMethodList?: Maybe<PaymentMethodTypeConnection>;
};


export type QueryPaymentMethodListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SetUpPaymentMethodInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  payload: Scalars['JSONString'];
  paymentService: Scalars['Int'];
};

export type SetUpPaymentMethodPayload = {
  __typename?: 'SetUpPaymentMethodPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentMethod?: Maybe<PaymentMethodType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodInput;
}>;


export type DeletePaymentMethodMutation = { __typename?: 'Mutation', deletePaymentMethod?: { __typename?: 'DeletePaymentMethodPayload', success?: boolean | null, errors?: Array<string | null> | null } | null };

export type PaymentMethodListQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentMethodListQuery = { __typename?: 'Query', paymentMethodList?: { __typename?: 'PaymentMethodTypeConnection', edges: Array<{ __typename?: 'PaymentMethodTypeEdge', node?: { __typename?: 'PaymentMethodType', payload: any, paymentService: PaymentMethodPaymentService } | null } | null> } | null };

export type SetUpPaymentMethodMutationVariables = Exact<{
  input: SetUpPaymentMethodInput;
}>;


export type SetUpPaymentMethodMutation = { __typename?: 'Mutation', setUpPaymentMethod?: { __typename?: 'SetUpPaymentMethodPayload', success?: boolean | null, errors?: Array<string | null> | null } | null };


export const DeletePaymentMethodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"deletePaymentMethod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeletePaymentMethodInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"payment"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePaymentMethod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const PaymentMethodListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"paymentMethodList"},"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"payment"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentMethodList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payload"}},{"kind":"Field","name":{"kind":"Name","value":"paymentService"}}]}}]}}]}}]}}]} as unknown as DocumentNode<PaymentMethodListQuery, PaymentMethodListQueryVariables>;
export const SetUpPaymentMethodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setUpPaymentMethod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetUpPaymentMethodInput"}}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"api"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"EnumValue","value":"payment"}}]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setUpPaymentMethod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<SetUpPaymentMethodMutation, SetUpPaymentMethodMutationVariables>;