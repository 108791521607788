import React, { JSX } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { t } from 'i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { useClient } from '@backoffice/hooks/clients'
import { Loader } from 'components'
import {
    CourseAccessType,
    CourseType,
    LessonType,
    UserType,
} from 'api/root/generated'
import { paths } from '@backoffice/index.routes'
import { useCourses } from 'hooks/courses'
import { useAccessAPI } from '@backoffice/hooks/accesses'
import ClientPageMobile from '@backoffice/components/pages/ClientPage/index.mobile'
import ClientPageDesktop from '@backoffice/components/pages/ClientPage/index.desktop'

const ClientPage = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()

    const navigate = useNavigate()
    const { clientId } = useParams()
    const { client, loading, refetch } = useClient(clientId as string)
    const [searchParams] = useSearchParams()
    const { courses } = useCourses()
    const { grantLessonAccess } = useAccessAPI()

    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const fastAccess = searchParams.get('fastAccess')

    const handleGrantAccess = (
        course: CourseType,
        streamId?: string | null,
        action?: string
    ) => {
        grantLessonAccess({
            input: {
                streamId,
                courseId: course.id as string,
                lessonIds: course.lessonIds || [],
                clientEmails: [client?.email as string],
                redirectTo: `${window.location.origin}/${course?.id}`,
            },
        }).then(response => {
            if (response?.success) {
                refetch().then(() => {
                    if (action === 'set-stream') {
                        enqueueSnackbar(
                            streamId
                                ? t(
                                      'backoffice:access.notifications.userInScope'
                                  )
                                : t(
                                      'backoffice:access.notifications.userOutOfScope'
                                  ),
                            { variant: 'success' }
                        )
                    } else {
                        enqueueSnackbar(
                            t(
                                'backoffice:access.grant.notifications.accessCreateSuccess'
                            ),
                            { variant: 'success' }
                        )
                    }
                })
            }
        })
    }

    const handleStreamChange = (
        access: CourseAccessType,
        streamId?: string | null
    ) => {
        grantLessonAccess({
            input: {
                streamId,
                courseId: access.course.id as string,
                lessonIds:
                    access.lessons.edges
                        .map(e => e?.node as LessonType)
                        .map(n => n?.id) || [],
                clientEmails: [client?.email as string],
                redirectTo: `${window.location.origin}/${access.course?.id}`,
            },
        }).then(response => {
            if (response?.success) {
                refetch().then(() => {
                    enqueueSnackbar(
                        streamId
                            ? t('backoffice:access.notifications.userInScope')
                            : t(
                                  'backoffice:access.notifications.userOutOfScope'
                              ),
                        { variant: 'success' }
                    )
                })
            }
        })
    }

    const copyAccessLink = (access: CourseAccessType) => {
        const url = `${window.location.origin}/authorization/activate-account`
        const getParams = `?redirectTo=${window.location.origin}/${access.course.id}&email=${access.client.email}`

        navigator.clipboard.writeText(`${url}${getParams}`)
        enqueueSnackbar(t('backoffice:access.accessLinkCopied'), {
            variant: 'success',
        })
    }

    const handleBack = () => {
        if (fastAccess) {
            navigate(`../${paths.backoffice}`)
        } else {
            navigate(`../${paths.clients}`)
        }
    }

    const handleAccessClick = (access: CourseAccessType) => {
        navigate(
            `../${paths.course(
                access.course.id as string
            )}?selectable=true&email=${access.client.email}&id=${
                access.client.id
            }`
        )
    }

    const handleAddAccess = (course: CourseType) => {
        navigate(
            `../${paths.course(course.id as string)}?selectable=true&email=${
                client?.email
            }`
        )
    }

    if (loading) {
        return <Loader />
    }

    return tablet ? (
        <ClientPageDesktop
            loading={loading}
            client={client as UserType}
            courses={courses}
            onBack={handleBack}
            onGrantAccess={handleGrantAccess}
            onStreamChange={handleStreamChange}
            onCopyAccessLink={copyAccessLink}
            onAccessClick={handleAccessClick}
        />
    ) : (
        <ClientPageMobile
            loading={loading}
            client={client as UserType}
            courses={courses}
            onBack={handleBack}
            onGrantAccess={handleGrantAccess}
            onStreamChange={handleStreamChange}
            onCopyAccessLink={copyAccessLink}
            onAccessClick={handleAccessClick}
            onAddAccess={handleAddAccess}
        />
    )
}
export default ClientPage
