import React, { JSX } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useMediaQuery, useTheme } from '@mui/material'
import { isBefore } from 'date-fns'

import { Loader } from 'components'
import {
    useHomeworkSubmissions,
    useHomeworkSubmissionsAPI,
} from '@backoffice/hooks/homeworkSumbission'
import { SubmissionCommentType, UserType } from 'api/root/generated'
import { HomeworkSubmissionRejectForm } from '@backoffice/components/forms'
import { useDialog } from '../../../../../hooks/common'
import { useHomework } from '@backoffice/hooks/homeworks'
import { paths as backofficePaths } from '@backoffice/index.routes'
import HomeworkSubmissionPageDesktop from './index.desktop'
import HomeworkSubmissionPageMobile from './index.mobile'
import { useMe } from '../../../../../hooks/user'
import { UploadedItem } from 'types'
import { normalizeAttachmentsForUpdate } from 'utils/common.utils'

const HomeworkSubmissionPage = (): JSX.Element => {
    const navigate = useNavigate()
    const { homeworkId } = useParams()
    const [searchParams] = useSearchParams()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const { homeworkSubmissions, loading, refetch } = useHomeworkSubmissions(
        homeworkId as string,
        searchParams.get('email') as string
    )
    const { me } = useMe()

    const { enqueueSnackbar } = useSnackbar()
    const { open, close } = useDialog()
    const { homework } = useHomework(homeworkId as string)
    const { postComment, approve } = useHomeworkSubmissionsAPI()

    const fastAccess = searchParams.get('fastAccess')

    if (loading) {
        return <Loader />
    }

    const handlePostComment = (
        submissionId: string,
        content: string,
        attachments: UploadedItem[],
        onSuccess: (comment: SubmissionCommentType) => void
    ) => {
        return postComment({
            input: {
                submissionId,
                content,
                attachments: normalizeAttachmentsForUpdate(attachments),
            },
        })
            .then(response => {
                if (response?.comment) {
                    enqueueSnackbar(t('Comment successfully added'), {
                        variant: 'success',
                    })
                    onSuccess(response.comment as SubmissionCommentType)
                    refetch()
                } else {
                    enqueueSnackbar(t('Something went wrong'), {
                        variant: 'error',
                    })
                }
            })
            .catch(() => {
                enqueueSnackbar(t('Something went wrong'), {
                    variant: 'error',
                })
            })
    }

    const handleApproveSubmission = (submissionId: string) => {
        approve({
            input: {
                submissionId,
            },
        }).then(response => {
            if (response?.submission) {
                enqueueSnackbar(t('Submission successfully approved'), {
                    variant: 'success',
                })
                refetch()
            }
        })
    }

    const handleRejectSubmission = (submissionId: string) => {
        open({
            component: HomeworkSubmissionRejectForm,
            props: {
                submissionId,
                onSuccess: () => {
                    close()
                    refetch()

                    enqueueSnackbar(t('Submission rejected'), {
                        variant: 'success',
                    })
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleBack = () => {
        if (fastAccess) {
            navigate(-1)
        } else {
            navigate(
                `../${backofficePaths.homeworkSubmissions(
                    homework?.id as string
                )}`
            )
        }
    }

    const sortedSubmissions = homeworkSubmissions.sort((a, b) =>
        isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1
    )

    return tablet ? (
        <HomeworkSubmissionPageDesktop
            clientEmail={searchParams.get('email') as string}
            user={me as UserType}
            onPostComment={handlePostComment}
            submissions={sortedSubmissions}
            loading={loading}
            onApproveSubmission={handleApproveSubmission}
            onRejectSubmission={handleRejectSubmission}
            onBack={handleBack}
            caption={homework?.title as string}
        />
    ) : (
        <HomeworkSubmissionPageMobile
            clientEmail={searchParams.get('email') as string}
            user={me as UserType}
            onPostComment={handlePostComment}
            submissions={sortedSubmissions}
            loading={loading}
            onApproveSubmission={handleApproveSubmission}
            onRejectSubmission={handleRejectSubmission}
            onBack={handleBack}
            caption={homework?.title as string}
        />
    )
}

export default HomeworkSubmissionPage
