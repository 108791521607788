import React, { JSX, useEffect, useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useCourse } from 'hooks/courses'
import { CourseStreamType, CourseType, LessonType } from 'api/root/generated'
import { useDialog, useDrawer } from 'hooks/common'
import { Loader } from 'components'
import { CourseStreamForm } from '../../forms'
import { useStreams, useStreamsAPI } from '@backoffice/hooks/streams'
import { paths as backofficePaths } from '@backoffice/index.routes'
import CourseStreamPageDesktop from './index.desktop'
import CourseStreamPageMobile from './index.mobile'

const CourseStreamPage = (): JSX.Element => {
    const navigate = useNavigate()

    const theme = useTheme()

    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const { enqueueSnackbar } = useSnackbar()

    const [selectedStream, setSelectedStream] =
        useState<CourseStreamType | null>(null)
    const [selectedDate, setSelectedDate] = useState<string | null>(null)

    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()
    const { courseId } = useParams()

    const { course, refetch } = useCourse(courseId as string)

    const modules = course?.modules?.edges.map(n => n?.node)
    const lessons = modules
        ?.map(m => m?.lessons.edges.map(e => e?.node))
        .flat() as LessonType[]

    const {
        streams,
        refetch: refetchStreams,
        loading,
    } = useStreams({
        courseId: courseId as string,
    })

    useEffect(() => {
        if (streams.length > 0) {
            setSelectedStream(streams[0])
        }
    }, [streams])

    const { update, remove } = useStreamsAPI()

    const updateSchedule = (
        current: string,
        { id, openAt }: { id: string; openAt: string | null }
    ) => {
        const updatedSchedule: Record<string, string> = {}
        const currentSchedule = JSON.parse(current)

        // eslint-disable-next-line no-restricted-syntax
        for (const lessonId of lessons.map(l => l.id)) {
            if (lessonId === id) {
                if (typeof openAt === 'string') {
                    updatedSchedule[lessonId] = openAt
                }
            } else if (currentSchedule[lessonId]) {
                updatedSchedule[lessonId] = currentSchedule[lessonId]
            }
        }

        return JSON.stringify(updatedSchedule)
    }

    const handleSaveLessonData = (id: string, openAt: string | null) => {
        const updatedSchedule = updateSchedule(selectedStream?.schedule, {
            id,
            openAt,
        })

        update({
            input: {
                name: selectedStream?.name,
                streamId: selectedStream?.id as string,
                schedule: updatedSchedule,
            },
        }).then(response => {
            if (response?.success) {
                setSelectedStream({
                    ...(selectedStream as CourseStreamType),
                    schedule: updatedSchedule,
                })
                close()
                enqueueSnackbar(
                    t('backoffice:stream.notifications.lessonUpdateSuccess'),
                    { variant: 'success', preventDuplicate: true }
                )
                refetch()
                refetchStreams()
                setSelectedDate(null)
            }
        })
    }

    const handleOpenStreamForm = (
        stream?: CourseStreamType,
        action: 'add' | 'edit' = 'add'
    ) => {
        const openModal = tablet ? open : openDrawer
        const closeModal = tablet ? close : closeDrawer

        openModal({
            component: CourseStreamForm,
            props: {
                stream,
                courseId,
                onCancel: closeModal,
                onSuccess: () => {
                    const message =
                        action === 'add'
                            ? 'backoffice:stream.notifications.streamCreateSuccess'
                            : 'backoffice:stream.notifications.streamUpdateSuccess'
                    enqueueSnackbar(t(message), { variant: 'success' })
                    closeModal()
                    refetchStreams()
                },
            },
            options: {
                onClose: closeModal,
            },
        })
    }

    const handleDeleteStream = (id: string) => {
        remove({
            input: {
                streamId: id,
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar(
                    t('backoffice:stream.notifications.streamDeleteSuccess'),
                    { variant: 'success' }
                )
                close()
                refetchStreams()
                setSelectedStream(null)
            }
        })
    }

    if (loading) {
        return <Loader />
    }

    const handleBack = () => {
        navigate(`../${backofficePaths.course(courseId as string)}`)
    }

    return tablet ? (
        <CourseStreamPageDesktop
            course={course as CourseType}
            streams={streams}
            loading={loading}
            selectedStream={selectedStream}
            onSelectStream={setSelectedStream}
            onChange={handleSaveLessonData}
            onDelete={handleDeleteStream}
            onAdd={() => handleOpenStreamForm(undefined, 'add')}
            onEdit={() =>
                handleOpenStreamForm(selectedStream as CourseStreamType, 'edit')
            }
            onBack={handleBack}
            onRefetchStreams={refetchStreams}
        />
    ) : (
        <CourseStreamPageMobile
            course={course as CourseType}
            streams={streams}
            loading={loading}
            selectedStream={selectedStream}
            onSelectStream={setSelectedStream}
            onChange={handleSaveLessonData}
            onDelete={handleDeleteStream}
            onAdd={() => handleOpenStreamForm(undefined, 'add')}
            onEdit={() =>
                handleOpenStreamForm(selectedStream as CourseStreamType, 'edit')
            }
            onBack={handleBack}
            onRefetchStreams={refetchStreams}
        />
    )
}
export default CourseStreamPage
