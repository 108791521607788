import React, { JSX } from 'react'

import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { PlayCircle } from '@mui/icons-material'

import { ActionBar } from '@backoffice/components/common'
import { CourseType, LessonType, ModuleType } from 'api/root/generated'
import { LessonForm } from '@backoffice/components/forms'
import useCourseEntityAPI from '@backoffice/hooks/course'
import { useDialog } from 'hooks/common'
import useConfirmationDialog from '@backoffice/hooks/common/useConfirmationDialog'
import { paths as storefrontPaths } from '@storefront/index.routes'
import { paths as backofficePaths } from '@backoffice//index.routes'

interface LessonActionBarProps {
    module: ModuleType
    lesson: LessonType
    disabled?: boolean
}

const LessonActionBar = ({
    module,
    lesson,
    disabled = false,
}: LessonActionBarProps) => {
    const navigate = useNavigate()

    const { open, close } = useDialog()
    const { enqueueSnackbar } = useSnackbar()

    const { refetch, removeLesson } = useCourseEntityAPI()
    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const handleOpenLessonForm = () => {
        open({
            component: LessonForm,
            props: {
                lesson,
                module,
                onSuccess: (action: 'add' | 'update') => {
                    refetch()
                    close()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.lesson.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.lesson.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenHomework = () => {
        navigate(
            `../${backofficePaths.homework}?category=lesson&relationId=${lesson?.homework?.relationId}&homeworkId=${lesson?.homework?.id}&title=${lesson.name}`
        )
    }

    const handleOpenLesson = () => {
        navigate(`../${storefrontPaths.watch(lesson.id)}`)
    }

    const handleDeleteLesson = (item: LessonType) => {
        removeLesson({
            input: {
                lessonId: item.id,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                close()
                enqueueSnackbar(
                    t(
                        'backoffice:content.course.lesson.notifications.successDeleted'
                    ),
                    { variant: 'success' }
                )
            }
        })
    }

    return (
        <ActionBar
            variant="menu"
            size="small"
            disabled={disabled || false}
            actions={[
                {
                    key: 'update',
                    type: 'update',
                    title: t('backoffice:content.course.lesson.actions.edit'),
                    onClick: handleOpenLessonForm,
                },
                {
                    type: 'delete',
                    key: 'delete',
                    title: t('backoffice:content.course.lesson.actions.delete'),
                    onClick: () => {
                        requestConfirmation(lesson, 'lesson', () =>
                            handleDeleteLesson(lesson)
                        )
                    },
                },
                {
                    type: lesson.homework ? 'update' : 'add',
                    key: lesson.homework ? 'update-homework' : 'add-homework',
                    title: t(
                        lesson.homework
                            ? 'backoffice:content.course.lesson.actions.updateHomework'
                            : 'backoffice:content.course.lesson.actions.addHomework'
                    ),
                    onClick: handleOpenHomework,
                },
            ]}
            extra={[
                {
                    key: 'play',
                    icon: <PlayCircle />,
                    title: t(
                        'backoffice:content.course.lesson.actions.open'
                    ) as string,
                    onClick: handleOpenLesson,
                },
            ]}
        />
    )
}

export default LessonActionBar
