import React, { JSX } from 'react'

import {
    Button,
    Divider,
    Skeleton,
    Stack,
    SxProps,
    Typography,
    Zoom,
} from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'

interface WidgetProps {
    title: string
    actions?: JSX.Element[]
    content: () => JSX.Element
    loading?: boolean
    compact?: boolean
    hideFooter?: boolean
    onMoreClick: () => void
    width?: string
    height?: string
    moreTitle?: string
    count?: number
    sx?: SxProps
}

const Widget = ({
    onMoreClick,
    title,
    content,
    actions = [],
    loading,
    width = '450px',
    height = 'auto',
    compact = false,
    hideFooter = false,
    moreTitle = 'Показати усi',
    sx = {},
    count = 5,
}: WidgetProps) => {
    const renderPlaceholder = () => {
        return (
            <Stack divider={<Divider />} gap="10px">
                {new Array(count).fill(1).map((_, index) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap="15px"
                        key={index}
                        sx={{
                            padding: '10px 5px',
                        }}
                    >
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width={38}
                            height={38}
                            sx={{
                                borderRadius: '4px',
                            }}
                        />
                        <Skeleton
                            animation="wave"
                            sx={{
                                flexGrow: 1,
                                fontSize: '16px',
                                lineHeight: '18px',
                            }}
                            variant="text"
                        />
                    </Stack>
                ))}
            </Stack>
        )
    }

    return (
        <Zoom in>
            <Stack
                sx={{
                    overflow: 'auto',
                    position: 'relative',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    width,
                    height,
                    maxHeight: compact ? '300px' : '523px',
                    borderRadius: '12px',
                    boxShadow: '0px 2px 6px -1px rgba(0,0,0,0.35)',
                    background: '#252525',
                    ...sx,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="50px"
                    sx={{
                        padding: compact ? '5px 10px' : '10px 15px',
                        backgroundColor: '#212121',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                    }}
                >
                    <Typography
                        fontSize="18px"
                        color="rgba(255, 255, 255, .4)"
                        lineHeight="40px"
                    >
                        {title}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        {actions}
                    </Stack>
                </Stack>
                <Stack
                    padding={compact ? '10px 10px 20px' : '15px 15px 25px'}
                    flexGrow={1}
                >
                    {loading ? renderPlaceholder() : content()}
                </Stack>
                {!hideFooter && (
                    <Stack>
                        <Button
                            onClick={onMoreClick}
                            variant="text"
                            color="secondary"
                            size="large"
                            endIcon={<KeyboardArrowRight />}
                            sx={{
                                borderRadius: '0 0 16px 16px',
                                '& .MuiSvgIcon-root': {
                                    fontSize: '24px !important',
                                },
                            }}
                        >
                            {moreTitle}
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Zoom>
    )
}

export default Widget
