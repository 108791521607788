import React, { useEffect, useState } from 'react'

import ukLocale from '@fullcalendar/core/locales/uk'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, {
    DateClickArg,
    EventReceiveArg,
} from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import { Divider, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { QuestionMarkOutlined } from '@mui/icons-material'
import {
    EventDropArg,
    EventInput,
    MoreLinkContentArg,
} from '@fullcalendar/core'
import { t } from 'i18next'

import { CourseStreamType, CourseType, LessonType } from 'api/root/generated'
import { Image } from 'components'
import EventModal from './EventModal'
import { useDialog } from '../../../../../../hooks/common'

export interface EventLikeObject {
    image: string
    title: string
    time?: string
}

interface CalendarProps {
    selectedDate: string | null
    course: CourseType
    stream: CourseStreamType
    onSaveLessonData: (id: string, openAt: string) => void
    onSelectDate: (date: string | null) => void
}

const Calendar = ({
    course,
    stream,
    onSaveLessonData,
    selectedDate,
    onSelectDate,
}: CalendarProps) => {
    const { open, close } = useDialog()

    const [events, setEvents] = useState<EventInput[]>([])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const modules = course?.modules?.edges.map(n => n?.node)
    const lessons = modules
        ?.map(m => m?.lessons.edges.map(e => e?.node))
        .flat() as LessonType[]

    const unassignedLessons = lessons?.filter(l => {
        const schedule = JSON.parse(stream?.schedule || '{}')
        return schedule ? !schedule[l.id] : false
    })

    useEffect(() => {
        if (stream) {
            const schedule = JSON.parse(stream.schedule)

            const events = lessons
                ?.filter(l => schedule[l.id])
                .map(l => ({
                    id: l?.id as string,
                    title: l?.name as string,
                    start: schedule[l.id],
                    extendedProps: {
                        image: l.videoPreview?.img60x40,
                    },
                }))
            setEvents(events)
        }
    }, [stream])

    const renderEvent = ({ image, time, title }: EventLikeObject) => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                divider={<Divider orientation="vertical" flexItem />}
            >
                {time && <Typography fontSize="13px">{time}</Typography>}
                <Stack direction="row" gap="10px" alignItems="center">
                    <Stack
                        maxWidth="100%"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '30px',
                            height: '18px',
                            boxShadow: '0 0 3px #000',
                            border: `1px solid transparent`,
                        }}
                    >
                        {image ? (
                            <Image
                                src={image as string}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                                alt=""
                            />
                        ) : (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <QuestionMarkOutlined
                                    sx={{
                                        color: '#4e4e4e',
                                        fontSize: '20px',
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <Typography noWrap maxWidth="120px" fontSize="14px">
                        {title}
                    </Typography>
                </Stack>
            </Stack>
        )
    }

    const handleEventReceive = (info: EventReceiveArg) => {
        setEvents([...events, info.event as EventInput])
    }
    const handleEventDrop = (info: EventDropArg) => {
        onSaveLessonData(
            info.event.id,
            info.event.start?.toISOString() as string
        )
    }

    const handleCellClick = (info: DateClickArg) => {
        setAnchorEl(info.dayEl)
        const { date } = info
        date.setHours(20)
        date.setMinutes(0)
        onSelectDate(date.toISOString())
    }

    const handleEditEvent = (id: string, type: 'add' | 'edit' = 'edit') => {
        const date = JSON.parse(stream?.schedule)[id] || null

        open({
            component: EventModal,
            props: {
                type,
                lesson: lessons.find(l => l.id === id),
                date: type === 'add' ? selectedDate : date,
                onCancel: close,
                onSubmit: onSaveLessonData,
            },
            options: {
                onClose: close,
            },
        })
    }

    const renderMore = ({ num }: MoreLinkContentArg) => (
        <Typography color="primary">
            {t('backoffice:stream.moreItems', {
                count: num,
            })}
        </Typography>
    )

    return (
        <Stack width="100%" height="920px">
            <FullCalendar
                titleFormat={{ year: 'numeric', month: 'long' }}
                height="100%"
                locale={ukLocale}
                dayMaxEvents={2}
                events={events}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                droppable
                editable
                eventDrop={handleEventDrop}
                eventReceive={handleEventReceive}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                }}
                eventContent={arg => {
                    return renderEvent({
                        image: arg.event.extendedProps.image,
                        title: arg.event.title,
                        time: arg.timeText,
                    })
                }}
                eventClick={arg => handleEditEvent(arg.event.id)}
                dateClick={handleCellClick}
                moreLinkContent={renderMore}
            />
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        maxHeight: '250px',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        ...(unassignedLessons?.length !== 0 && {
                            '& .MuiList-root': {
                                paddingTop: 0,
                            },
                        }),
                    },
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'center',
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'center',
                }}
            >
                <MenuItem
                    disabled
                    sx={theme => ({
                        background: '#393939',
                        borderBottom:
                            unassignedLessons?.length > 0
                                ? `1px solid rgba(255, 255, 255, 0.2)`
                                : 'none',
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        opacity:
                            unassignedLessons?.length > 0
                                ? '1 !important'
                                : 'inherit',
                    })}
                >
                    <Typography>
                        {unassignedLessons?.length > 0
                            ? t('backoffice:stream.addEvent')
                            : t('backoffice:stream.noEventsToAdd')}
                    </Typography>
                </MenuItem>

                {unassignedLessons?.map(l => (
                    <MenuItem
                        key={l.id}
                        onClick={() => handleEditEvent(l.id, 'add')}
                        sx={{
                            padding: '10px 16px',
                        }}
                    >
                        {renderEvent({
                            image: l.videoPreview?.img60x40 as string,
                            title: l.name,
                        })}
                    </MenuItem>
                ))}
            </Menu>
        </Stack>
    )
}

export default Calendar
