import React, { JSX } from 'react'

import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'

import { paths as backofficePaths } from '@backoffice/index.routes'
import { PageLayout, PageLayoutMobile } from '@backoffice/components/common'
import { StatisticsPageProps } from '@backoffice/components/pages/StatisticsPage/index.types'

const StatisticsPageMobile = ({ onBack }: StatisticsPageProps): JSX.Element => {
    return (
        <PageLayoutMobile
            pageTitle={`${t('backoffice:pageTitles.backoffice')} | ${t(
                'backoffice:pageTitles.statisticsPage'
            )}`}
            onBack={onBack}
            title={t('backoffice:statistics.title') as string}
            sx={{
                gap: '35px',
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Typography color="secondary.dark" textAlign="center">
                    На данний момент статистика по вашому профiлю не доступна
                </Typography>
            </Stack>
        </PageLayoutMobile>
    )
}
export default StatisticsPageMobile
