import { UserType } from 'api/root/generated'
import {
    LiqPayFormValues,
    MonobankFormValues,
    StripeFormValues,
    WayForPayFormValues,
} from '@backoffice/components/pages/SettingsPage/components/modals'

export interface StripePayload {
    secret_key: string
}

export interface MonobankPayload {
    token: string
}

export interface LiqPayPayload {
    public_key: string
    private_key: string
}

export interface WayForPayPayload {
    merchant_login: string
    merchant_secret_key: string
}

export enum PaymentServices {
    WAYFORPAY = 'WAYFORPAY',
    MONOBANK = 'MONOBANK',
    STRIPE = 'STRIPE',
    LIQPAY = 'LIQPAY',
}

export interface SettingsPageProps {
    data: UserType
    onBack: () => void
    onPaymentMethodClick: (type: PaymentServices) => void
    onEditSocialsClick: () => void
    onSetupPaymentMethod: (
        data:
            | WayForPayFormValues
            | MonobankFormValues
            | StripeFormValues
            | LiqPayFormValues
    ) => void
}
