import React, { FC } from 'react'

import { AttachFile } from '@mui/icons-material'
import {
    Stack,
    useMediaQuery,
    useTheme,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material'
import { Accept, useDropzone } from 'react-dropzone'

import { UploadedItem } from 'types'
import { Input } from '../index'
import {
    FILE_ACCEPT_EXTENSIONS,
    PHOTO_ACCEPT_EXTENSIONS,
} from '../../config/user.config'

interface FileUploadProps {
    editable?: boolean
    onChange?: (items: UploadedItem[]) => void
    uploadedFiles?: UploadedItem[]
}

const FileUpload: FC<FileUploadProps> = ({
    onChange,
    uploadedFiles = [],
    editable,
}) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.up('xs'))

    const isFilesDisabledByAmount =
        uploadedFiles && uploadedFiles.filter(f => !f.delete).length >= 10

    const onDrop = (acceptedFiles: File[]) => {
        const newItems = acceptedFiles.map(file => ({
            id: `${Date.now()}_${Math.random()}`,
            file,
            fromClient: true,
            uploadType: file.type.startsWith('image/')
                ? ('image' as const)
                : ('file' as const),
            preview: file.type.startsWith('image/')
                ? URL.createObjectURL(file)
                : undefined,
        }))
        if (onChange) {
            onChange([...uploadedFiles, ...newItems])
        }
    }

    const accept: Accept = {
        ...PHOTO_ACCEPT_EXTENSIONS,
        ...FILE_ACCEPT_EXTENSIONS,
    }

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 10 - uploadedFiles.filter(f => !f.delete).length,
        onDrop,
        accept,
        maxSize: 5242880, // 5 MB
        multiple: true,
        noClick: true,
        noKeyboard: true,
        noDrag: true,
    })

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap="20px"
            {...getRootProps()}
        >
            <Tooltip
                title={
                    isFilesDisabledByAmount ? (
                        <Typography>
                            Максімальна кількість вкладень: 10
                        </Typography>
                    ) : (
                        ''
                    )
                }
            >
                <span>
                    <IconButton
                        disabled={!editable || isFilesDisabledByAmount}
                        component="label"
                        size={mobile ? 'medium' : 'small'}
                    >
                        <AttachFile />
                        <Input
                            type="file"
                            {...getInputProps()}
                            disabled={!editable || isFilesDisabledByAmount}
                            sx={{
                                clip: 'rect(0 0 0 0)',
                                clipPath: 'inset(50%)',
                                height: 1,
                                overflow: 'hidden',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 0,
                                whiteSpace: 'nowrap',
                                width: 1,
                            }}
                        />
                    </IconButton>
                </span>
            </Tooltip>
        </Stack>
    )
}

export default FileUpload
