import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    Divider,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import { EditOutlined } from '@mui/icons-material'

import Layout from './Layout'
import monoIcon from 'assets/images/monobank_icon.png'
import wayForPayIcon from 'assets/images/wayforpay_icon.png'
import stripeIcon from 'assets/images/stripe_icon.png'
import liqPayIcon from 'assets/images/liqpay_icon.png'
import { Image } from 'components'
import { PaymentServices } from '../index.types'
import { PaymentMethodsType } from 'api/root/generated'

interface AccountProps {
    onPaymentMethodClick: (type: PaymentServices) => void
    paymentData: PaymentMethodsType
}

const Payment = ({
    onPaymentMethodClick,
    paymentData,
}: AccountProps): JSX.Element => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const renderConfiguredPaymentSystemButton = (
        type: PaymentServices,
        title: string,
        value: string
    ) => {
        return (
            <Button
                onClick={() => onPaymentMethodClick(type)}
                endIcon={<EditOutlined />}
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ maxWidth: 'fit-content' }}
            >
                <Typography
                    fontSize="15px"
                    color="secondary.light"
                    sx={{
                        marginRight: '10px',
                    }}
                >
                    {title}
                </Typography>
                <Typography sx={{ wordBreak: 'break-word' }} fontSize="17px">
                    {value}
                </Typography>
            </Button>
        )
    }

    const renderConfigureButton = (type: PaymentServices) => {
        return (
            <Button
                onClick={() => onPaymentMethodClick(type)}
                variant="outlined"
            >
                {t('backoffice:settings.payment.setUpButton')}
            </Button>
        )
    }

    const renderPaymentServiceRow = (
        icon: string,
        externalLink: string,
        title: string,
        description: string,
        button: JSX.Element
    ) => {
        return (
            <Stack
                direction={tablet ? 'row' : 'column'}
                justifyContent="space-between"
                alignItems={tablet ? 'center' : 'flex-start'}
                gap="25px"
            >
                <Stack direction="row" alignItems="center" gap="15px">
                    <a
                        style={{ fontSize: 0 }}
                        href={externalLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            src={icon}
                            sx={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                            }}
                        />
                    </a>

                    <Stack gap="15px">
                        <Typography fontSize="22px">{title}</Typography>
                        {tablet ? (
                            <Typography
                                fontSize="17px"
                                sx={{ color: 'secondary.dark' }}
                                variant="caption"
                            >
                                {description}
                            </Typography>
                        ) : (
                            button
                        )}
                    </Stack>
                </Stack>
                {tablet && button}
            </Stack>
        )
    }

    console.log(paymentData)

    return (
        <Layout title={t('backoffice:settings.payment.title')}>
            <Stack gap="15px" divider={<Divider />}>
                {renderPaymentServiceRow(
                    wayForPayIcon,
                    'https://wayforpay.com/',
                    t('backoffice:settings.payment.wayForPayLabel'),
                    t('backoffice:settings.payment.wayForPayCaption'),
                    paymentData?.WAYFORPAY
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.WAYFORPAY,
                              'Merchant login:',
                              JSON.parse(paymentData?.WAYFORPAY?.payload)
                                  ?.merchant_login
                          )
                        : renderConfigureButton(PaymentServices.WAYFORPAY)
                )}
                {renderPaymentServiceRow(
                    stripeIcon,
                    'https://www.stripe.com',
                    t('backoffice:settings.payment.stripeLabel'),
                    t('backoffice:settings.payment.stripeCaption'),
                    paymentData?.STRIPE
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.STRIPE,
                              'Secret key:',
                              JSON.parse(paymentData?.STRIPE?.payload)
                                  ?.secret_key as unknown as string
                          )
                        : renderConfigureButton(PaymentServices.STRIPE)
                )}
                {renderPaymentServiceRow(
                    monoIcon,
                    'https://www.monobank.ua',
                    t('backoffice:settings.payment.monobankLabel'),
                    t('backoffice:settings.payment.monobankCaption'),
                    paymentData?.MONOBANK
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.MONOBANK,
                              'Token:',
                              JSON.parse(paymentData?.MONOBANK?.payload)
                                  ?.token as unknown as string
                          )
                        : renderConfigureButton(PaymentServices.MONOBANK)
                )}
                {renderPaymentServiceRow(
                    liqPayIcon,
                    'https://www.liqpay.ua',
                    t('backoffice:settings.payment.liqPayLabel'),
                    t('backoffice:settings.payment.liqPayCaption'),
                    paymentData?.LIQPAY
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.LIQPAY,
                              'Public key:',
                              JSON.parse(paymentData?.LIQPAY?.payload)
                                  ?.public_key as unknown as string
                          )
                        : renderConfigureButton(PaymentServices.LIQPAY)
                )}
            </Stack>
        </Layout>
    )
}
export default Payment
