import React from 'react'

import { useFormik } from 'formik'
import {
    Box,
    TextField,
    Stack,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    InputAdornment,
    MenuItem,
} from '@mui/material'
import { t } from 'i18next'
import { CallSplitRounded } from '@mui/icons-material'
import isEmail from 'validator/lib/isEmail'

import { AccessFormScheme, AccessFormValues } from './index.schema'
import { CourseStreamType, CourseType } from 'api/root/generated'

interface CourseFormProps {
    onSubmit: (values: AccessFormValues) => void
    course?: Partial<CourseType>
}

const AccessForm = ({ onSubmit, course }: CourseFormProps) => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: {
            email: '',
            stream: 'none',
        },
        validationSchema: AccessFormScheme,
        onSubmit,
    })

    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                minWidth: 'min(80vw, 500px)',
                ...(tablet && {
                    maxWidth: '500px',
                }),
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize={tablet ? '20px' : '16px'}
                            textAlign={tablet ? 'left' : 'center'}
                        >
                            {t('backoffice:clients.actions.grantAccessTitle')}
                        </Typography>
                    </Stack>
                    <Stack gap="25px">
                        <Stack gap="10px">
                            <Typography
                                fontSize="14px"
                                color="rgba(255, 255, 255, 0.6)"
                            >
                                {t(
                                    'backoffice:clients.actions.grantAccessCaption'
                                )}
                            </Typography>
                            <TextField
                                variant="outlined"
                                name="email"
                                placeholder={
                                    t(
                                        'backoffice:clients.actions.grantAccessEmailPlaceholder'
                                    ) as string
                                }
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                                sx={{
                                    ...(!tablet && {
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }),
                                }}
                            />
                        </Stack>

                        {course?.streamCount !== 0 && (
                            <Stack gap="10px">
                                <Typography
                                    fontSize="14px"
                                    color="rgba(255, 255, 255, 0.6)"
                                >
                                    {t(
                                        'backoffice:clients.actions.chooseStreamCaption'
                                    )}
                                </Typography>
                                <TextField
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CallSplitRounded />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            padding: '5px 0 5px 14px',
                                        },
                                        ...(!tablet && {
                                            backgroundColor:
                                                theme.palette.background.paper,
                                            borderRadius: '12px',
                                            '& .MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: 'none',
                                                },
                                        }),
                                    }}
                                    select
                                    variant="outlined"
                                    placeholder="Stream"
                                    onChange={e => {
                                        formik.setFieldValue(
                                            'stream',
                                            e.target.value === 'none'
                                                ? null
                                                : e.target.value
                                        )
                                    }}
                                    value={formik.values.stream || undefined}
                                    defaultValue="none"
                                >
                                    <MenuItem value="none">
                                        {t('backoffice:access.grant.noStream')}
                                    </MenuItem>
                                    {course?.streams?.edges
                                        .map(e => e?.node as CourseStreamType)
                                        .map(stream => (
                                            <MenuItem
                                                key={stream.id}
                                                value={stream.id}
                                            >
                                                {stream?.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Stack>
                        )}
                    </Stack>
                    <Stack gap="10px">
                        <Button
                            disabled={!isEmail(formik.values.email)}
                            variant="contained"
                            type="submit"
                            fullWidth
                            color="success"
                            sx={{ padding: tablet ? '15px' : '10px' }}
                        >
                            <Typography>Перейти до вибору урокiв</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default AccessForm
