import React from 'react'

import { Button, Typography } from '@mui/material'
import { t } from 'i18next'
import {
    CloseOutlined,
    CheckCircleOutline,
    DeleteOutline,
    EditOutlined,
} from '@mui/icons-material'

import { PageLayout } from '@backoffice/components/common'
import { HomeworkForm } from '@backoffice/components/forms'
import { CreateHomeworkPageProps } from './index.types'
import { HomeworkCreateInput, HomeworkUpdateInput } from 'api/root/generated'

const HomeworkPageDesktop = ({
    editable,
    loading,
    onCreate,
    onEdit,
    onDelete,
    formikRef,
    onBack,
    caption,
    creation,
    homework,
    onChangeEditable,
    onSubmissionsClick,
}: CreateHomeworkPageProps) => {
    const resolveActions = () => {
        if (creation) {
            return [
                <Button
                    onClick={() => {
                        if (formikRef.current) {
                            formikRef.current.handleSubmit()
                        }
                    }}
                    startIcon={<CheckCircleOutline />}
                    loading={loading}
                    disabled={!editable}
                    color="success"
                    key="create"
                >
                    <Typography>
                        {t('backoffice:homework.actions.create')}
                    </Typography>
                </Button>,
            ]
        }

        if (editable) {
            return [
                <Button
                    onClick={onDelete}
                    startIcon={<DeleteOutline />}
                    loading={loading}
                    color="error"
                    key="delete"
                >
                    <Typography>Видалити</Typography>
                </Button>,
                <Button
                    startIcon={<CloseOutlined />}
                    onClick={onChangeEditable}
                    loading={loading}
                    color="secondary"
                    key="cancel"
                >
                    <Typography>Вiдмiнити</Typography>
                </Button>,
                <Button
                    startIcon={<CheckCircleOutline />}
                    onClick={() => {
                        if (formikRef.current) {
                            formikRef.current.handleSubmit()
                        }
                    }}
                    loading={loading}
                    color="success"
                    key="save"
                >
                    <Typography>Зберегти</Typography>
                </Button>,
            ]
        }
        return [
            <Button
                onClick={onChangeEditable}
                startIcon={<EditOutlined />}
                loading={loading}
                color="secondary"
                key="edit"
            >
                <Typography>Редагувати</Typography>
            </Button>,
            <Button
                onClick={onSubmissionsClick}
                loading={loading}
                color="primary"
                disabled={homework?.submissions?.edges?.length === 0}
                key="submissions"
            >
                <Typography>
                    Вiдповiдi ({homework?.submissions?.edges?.length})
                </Typography>
            </Button>,
        ]
    }

    return (
        <PageLayout
            pageTitle={`${t('backoffice:pageTitles.homeworkPage')}`}
            onBack={onBack}
            title={t('backoffice:homework.title')}
            caption={caption}
            actions={resolveActions()}
        >
            <HomeworkForm
                homework={homework}
                loading={loading}
                editable={editable}
                onSubmit={(values: HomeworkCreateInput | HomeworkUpdateInput) =>
                    creation
                        ? onCreate(values as HomeworkCreateInput)
                        : onEdit(values as HomeworkUpdateInput)
                }
                formikRef={formikRef}
            />
        </PageLayout>
    )
}

export default HomeworkPageDesktop
