import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
    ClientListDocument,
    ClientListQueryVariables,
    UserType,
    ClientDocument,
    ClientQueryVariables,
} from 'api/root/generated'

export const useClients = (
    variables: ClientListQueryVariables = {},
    options = {}
) => {
    const [total, setTotal] = useState(0)
    const [response, setResponse] = useState<UserType[]>([])

    const { data, loading, error, refetch, networkStatus, fetchMore } =
        useQuery(ClientListDocument, {
            variables,
            ...options,
        })

    useEffect(() => {
        if (data?.clientList?.edges) {
            const clients: UserType[] = data?.clientList?.edges?.map(
                edge => edge?.node as UserType
            )
            setTotal(data?.clientList?.totalCount || 0)
            setResponse(clients)
        }
    }, [data])

    return {
        clients: response,
        loading,
        error,
        refetch,
        networkStatus,
        total,
        fetchMore,
    }
}

export const useClient = (id?: string) => {
    const { data, loading, error, refetch } = useQuery(ClientDocument, {
        ...(id && {
            variables: {
                id,
            },
        }),
        skip: !id,
    })

    let client

    if (data?.client) {
        client = data.client
    }

    return {
        client,
        loading: loading || typeof data === 'undefined',
        error,
        refetch,
    }
}
