import React, { JSX } from 'react'

import { Stack } from '@mui/material'
import { t } from 'i18next'

import { ClientPageProps } from '@backoffice/components/pages/ClientPage/index.types'
import { PageLayout } from '@backoffice/components/common'
import { Loader } from 'components'
import ClientDetails from './components/ClientDetails'
import ClientAccesses from './components/ClientAccesses'
import { UserType } from 'api/root/generated'

const ClientPageDesktop = ({
    onGrantAccess,
    onStreamChange,
    client,
    onBack,
    courses,
    loading,
    onCopyAccessLink,
    onAccessClick,
}: ClientPageProps): JSX.Element => {
    if (loading) {
        return <Loader />
    }

    return (
        <PageLayout
            pageTitle={t('backoffice:pageTitles.clientsPage')}
            caption={t('backoffice:client.caption')}
            onBack={onBack}
            title={client?.email as string}
            sx={{ gap: '100px' }}
        >
            <Stack gap="25px">
                <ClientDetails client={client as UserType} />
                <ClientAccesses
                    courses={courses}
                    client={client as UserType}
                    onNewAccess={onGrantAccess}
                    onStreamChange={onStreamChange}
                    onCopyAccessLink={onCopyAccessLink}
                    onAccessClick={onAccessClick}
                />
            </Stack>
        </PageLayout>
    )
}
export default ClientPageDesktop
