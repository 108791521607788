import React from 'react'

import {
    Box,
    Button,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { Add } from '@mui/icons-material'

import { UserType } from 'api/root/generated'

interface DetailsTabContentProps {
    client: UserType
}

const ClientDetails = ({ client }: DetailsTabContentProps) => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const renderField = (title: string, content: string) => {
        return (
            <Stack justifyContent="space-between" gap="10px">
                <Typography
                    sx={theme => ({
                        color: theme.palette.secondary.dark,
                        fontSize: '18px',
                    })}
                >
                    {title}
                </Typography>
                <Typography
                    sx={theme => ({
                        color: theme.palette.secondary.light,
                        fontSize: '22px',
                    })}
                >
                    {content}
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack
            sx={{
                flexGrow: 1,
                padding: '15px 25px 25px',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    marginBottom: tablet ? '50px' : '25px',
                }}
            >
                <Typography
                    sx={theme => ({
                        fontSize: tablet ? '26px' : '18px',
                        color: theme.palette.secondary.dark,
                    })}
                >
                    {t('backoffice:client.sections.personal.title')}
                </Typography>
            </Stack>
            <Stack gap="25px" width="100%">
                <Stack
                    direction="row"
                    gap={tablet ? '50px' : '15px'}
                    flexWrap="wrap"
                    sx={{
                        '& > *': {
                            flexBasis: tablet ? '15%' : '40%',
                        },
                    }}
                >
                    {renderField(
                        t('backoffice:client.sections.personal.firstName'),
                        '-'
                    )}
                    {renderField(
                        t('backoffice:client.sections.personal.lastName'),
                        '-'
                    )}
                    {renderField(
                        t('backoffice:client.sections.personal.email'),
                        client.email as string
                    )}
                </Stack>
                <Stack
                    direction="row"
                    gap={tablet ? '50px' : '15px'}
                    flexWrap="wrap"
                    sx={{
                        '& > *': {
                            flexBasis: tablet ? '15%' : '40%',
                        },
                    }}
                >
                    {renderField(
                        t('backoffice:client.sections.personal.gender'),
                        '-'
                    )}
                    {renderField(
                        t('backoffice:client.sections.personal.age'),
                        '-'
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ClientDetails
