import React, { JSX } from 'react'

import { Typography, Stack, IconButton, Divider, Box } from '@mui/material'
import { t } from 'i18next'
import {
    AccountCircle,
    Add,
    ArrowCircleRightOutlined,
} from '@mui/icons-material'

import { Image } from 'components'
import Widget from './components/Widget'
import noBackground from 'assets/images/no-background.png'
import {
    AccessMenu,
    ContentList,
    PageLayoutMobile,
} from '@backoffice/components/common'
import { CourseType } from 'api/root/generated'
import { DashboardPageProps } from '@backoffice/components/pages/NewDashboardPage/index.types'
import { useDrawer } from '../../../../../hooks/common'

const DashboardPageMobile = ({
    onAddContentClick,
    onMoreContentClick,
    onMoreAccessClick,
    onAddAccessClick,
    courses,
    clients,
    coursesLoading,
    clientsLoading,
    onCourseClick,
    onClientClick,
    submissionsLoading,
    submissions,
    onSubmissionClick,
}: DashboardPageProps): JSX.Element => {
    const { open, close } = useDrawer()

    const handleOpenContentList = () => {
        open({
            component: ContentList,
            props: {
                courses,
                onSelect: (course: CourseType) => {
                    close()
                    onAddAccessClick(course)
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    return (
        <PageLayoutMobile
            pageTitle={t('backoffice:pageTitles.dashboardPage')}
            title={t('backoffice:pageTitles.dashboardPage')}
            sx={{
                gap: '50px',
            }}
        >
            <Stack gap="25px">
                <Widget
                    width="100%"
                    onMoreClick={onMoreContentClick}
                    loading={coursesLoading}
                    actions={[
                        <IconButton
                            onClick={onAddContentClick}
                            color="primary"
                            size="medium"
                        >
                            <Add />
                        </IconButton>,
                    ]}
                    title={t('backoffice:plates.content')}
                    compact
                    content={() => (
                        <Stack divider={<Divider />} gap="10px">
                            {courses.map(course => (
                                <Stack
                                    key={course.id}
                                    onClick={() => onCourseClick(course)}
                                    sx={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                        borderRadius: '12px',
                                        '&:hover': {
                                            background:
                                                'rgba(255, 255, 255, .1)',
                                            '.MuiSvgIcon-root[data-action="go-to"]':
                                                {
                                                    opacity: 1,
                                                },
                                        },
                                    }}
                                    direction="row"
                                    alignItems="center"
                                    gap="15px"
                                >
                                    <Image
                                        sx={{
                                            minWidth: '32px',
                                            width: '32px',
                                            height: '32px',
                                            objectFit: 'cover',
                                            borderRadius: '4px',
                                        }}
                                        src={
                                            course.appearance?.background
                                                ?.img60x40 || noBackground
                                        }
                                    />
                                    <Typography
                                        noWrap
                                        sx={{
                                            maxWidth: '220px',
                                            fontSize: '16px',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        {course.name}
                                    </Typography>
                                    <ArrowCircleRightOutlined
                                        data-action="go-to"
                                        sx={{
                                            opacity: 0,
                                            transition: '0.3s ease',
                                            position: 'absolute',
                                            right: '5px',
                                            color: 'rgba(255, 255, 255, .3)',
                                        }}
                                    />
                                </Stack>
                            ))}
                        </Stack>
                    )}
                />
                <Widget
                    width="100%"
                    compact
                    onMoreClick={onMoreAccessClick}
                    loading={clientsLoading}
                    actions={[
                        <IconButton
                            color="primary"
                            onClick={handleOpenContentList}
                        >
                            <Add />
                        </IconButton>,
                    ]}
                    title={t('backoffice:plates.clients')}
                    content={() => (
                        <Stack divider={<Divider />} gap="10px">
                            {clients.map(client => (
                                <Stack
                                    key={client.id}
                                    onClick={() => onClientClick(client)}
                                    sx={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                        borderRadius: '12px',
                                        '&:hover': {
                                            background:
                                                'rgba(255, 255, 255, .1)',
                                            '.MuiSvgIcon-root[data-action="go-to"]':
                                                {
                                                    opacity: 1,
                                                },
                                        },
                                    }}
                                    direction="row"
                                    alignItems="center"
                                    gap="15px"
                                >
                                    <AccountCircle
                                        sx={{
                                            fontSize: '32px',
                                            color: 'rgba(255, 255, 255, .1)',
                                        }}
                                    />
                                    <Typography
                                        noWrap
                                        sx={{
                                            maxWidth: '220px',
                                            fontSize: '16px',
                                            lineHeight: '18px',
                                        }}
                                    >
                                        {client.email}
                                    </Typography>
                                    <ArrowCircleRightOutlined
                                        data-action="go-to"
                                        sx={{
                                            opacity: 0,
                                            transition: '0.3s ease',
                                            position: 'absolute',
                                            right: '5px',
                                            color: 'rgba(255, 255, 255, .3)',
                                        }}
                                    />
                                </Stack>
                            ))}
                        </Stack>
                    )}
                />
                <Widget
                    compact
                    count={5}
                    width="100%"
                    onMoreClick={onMoreAccessClick}
                    loading={submissionsLoading}
                    hideFooter
                    title={t('backoffice:plates.submissions')}
                    content={() => (
                        <Stack divider={<Divider />} gap="10px">
                            {submissions.map(submission => (
                                <Stack
                                    key={submission.id}
                                    onClick={() =>
                                        onSubmissionClick(submission)
                                    }
                                    sx={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderRadius: '16px',
                                        '&:hover': {
                                            background:
                                                'rgba(255, 255, 255, .1)',
                                            '.MuiSvgIcon-root[data-action="go-to"]':
                                                {
                                                    opacity: 1,
                                                },
                                        },
                                    }}
                                    direction="row"
                                    alignItems="center"
                                    gap="15px"
                                >
                                    <Stack gap="2px">
                                        <Stack
                                            direction="row"
                                            gap="5px"
                                            alignItems="center"
                                        >
                                            <Box
                                                sx={theme => ({
                                                    borderRadius: '50%',
                                                    width: '7px',
                                                    height: '7px',
                                                    background:
                                                        submission.status
                                                            ?.val === 'Pending'
                                                            ? theme.palette
                                                                  .warning.main
                                                            : submission.status
                                                                  ?.val ===
                                                              'Rejected'
                                                            ? theme.palette
                                                                  .error.main
                                                            : theme.palette
                                                                  .success.main,
                                                })}
                                            />
                                            <Typography
                                                noWrap
                                                sx={{
                                                    maxWidth: '300px',
                                                    fontSize: '16px',
                                                    lineHeight: '18px',
                                                }}
                                            >
                                                {submission.client.email}
                                            </Typography>
                                        </Stack>

                                        <Typography
                                            color="secondary.dark"
                                            noWrap
                                            sx={{
                                                maxWidth: '300px',
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                            }}
                                        >
                                            {submission.homework.title}
                                        </Typography>
                                    </Stack>

                                    <ArrowCircleRightOutlined
                                        data-action="go-to"
                                        sx={{
                                            opacity: 0,
                                            transition: '0.3s ease',
                                            position: 'absolute',
                                            right: '5px',
                                            color: 'rgba(255, 255, 255, .3)',
                                        }}
                                    />
                                </Stack>
                            ))}
                        </Stack>
                    )}
                />
            </Stack>
        </PageLayoutMobile>
    )
}
export default DashboardPageMobile
