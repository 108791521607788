import { object, string } from 'yup'
import { t } from 'i18next'

export interface HomeworkSubmissionRejectFormValues {
    reason: string
}

export const HomeworkSubmissionRejectFormScheme = object({
    reason: string().required(t('auth:errors.required-field') || ''),
})
