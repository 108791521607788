import React, { useState } from 'react'

import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    Add,
    Close,
    Dashboard,
    DataSaverOff,
    DonutSmall,
    Notifications,
    NotificationsNone,
    Settings,
    ShowChart,
    StorefrontTwoTone,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useMatch, useNavigate } from 'react-router-dom'
import { keyframes } from '@emotion/react'

import { paths as storefrontPaths } from '@storefront/index.routes'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { Logo } from 'components'

const Navigation = () => {
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const isDashboard = !!useMatch('/backoffice')
    const isSettings = !!useMatch('/backoffice/settings')
    const isStatistics = !!useMatch('/backoffice/statistics')

    return (
        <Stack
            alignItems="flex-start"
            sx={{
                transition: '0.3s ease',
                height: `calc(${
                    open ? '246px' : '67px'
                } + env(safe-area-inset-bottom, 0px))`,
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: 0,
                background: 'rgb(24,24,24)',
                zIndex: 25,
                borderTop: '1px solid rgba(255, 255, 255, 0.05)',
                '& .MuiIconButton-root': {
                    touchAction: 'manipulation',
                },
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                gap="8px"
                width="100%"
                sx={{
                    padding: '5px 10px',
                }}
            >
                <IconButton
                    color={isDashboard ? 'primary' : 'inherit'}
                    sx={{
                        transition: '0.3s ease',
                        opacity: open ? 0 : 1,
                        '& .MuiSvgIcon-root': {
                            fontSize: '28px',
                        },
                    }}
                    size="large"
                    onClick={() => navigate(`../${backofficePaths.backoffice}`)}
                >
                    <Dashboard />
                </IconButton>
                <IconButton
                    color={isStatistics ? 'primary' : 'inherit'}
                    sx={{
                        transition: '0.3s ease',
                        opacity: open ? 0 : 1,
                        '& .MuiSvgIcon-root': {
                            fontSize: '28px',
                        },
                    }}
                    size="large"
                    onClick={() => navigate(`./${backofficePaths.statistics}`)}
                >
                    <DataSaverOff />
                </IconButton>
                <Box
                    sx={{
                        borderRadius: '50%',
                        background: '#252525',
                    }}
                >
                    <IconButton
                        size="large"
                        color="primary"
                        onClick={() => setOpen(prevState => !prevState)}
                        sx={{
                            transition: '.5s ease',

                            transform: open ? 'rotate(45deg)' : 'none',
                        }}
                    >
                        <Add />
                    </IconButton>
                </Box>

                <IconButton
                    color={isSettings ? 'primary' : 'inherit'}
                    sx={{
                        transition: '0.3s ease',
                        opacity: open ? 0 : 1,
                        '& .MuiSvgIcon-root': {
                            fontSize: '28px',
                        },
                    }}
                    size="large"
                    onClick={() => navigate(`./${backofficePaths.settings}`)}
                >
                    <Settings />
                </IconButton>
                <IconButton
                    sx={{
                        transition: '0.3s ease',
                        opacity: open ? 0 : 1,
                        '& .MuiSvgIcon-root': {
                            fontSize: '28px',
                        },
                    }}
                    size="large"
                    onClick={() => navigate(`../${storefrontPaths.expert}`)}
                >
                    <StorefrontTwoTone
                        sx={{
                            fontSize: '28px',
                            '& > path:first-of-type': {
                                fill: '#e8383c',
                                opacity: 1,
                            },
                        }}
                    />
                </IconButton>
            </Stack>
            <Stack
                gap="10px"
                padding="25px"
                width="100%"
                sx={{
                    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
                    transition: '0.3s ease',
                    opacity: open ? 1 : 0,
                }}
            >
                <Button
                    variant="text"
                    fullWidth
                    sx={theme => ({
                        borderRadius: 0,
                        color: theme.palette.secondary.light,
                        justifyContent: 'flex-start',
                    })}
                >
                    <Typography>Створити курс</Typography>
                </Button>
                <Button
                    variant="text"
                    fullWidth
                    sx={theme => ({
                        borderRadius: 0,
                        color: theme.palette.secondary.light,
                        justifyContent: 'flex-start',
                    })}
                >
                    <Typography>Створити новий доступ</Typography>
                </Button>
                <Button
                    disabled
                    variant="text"
                    fullWidth
                    sx={theme => ({
                        borderRadius: 0,
                        color: theme.palette.secondary.light,
                        justifyContent: 'flex-start',
                    })}
                >
                    <Typography>Створити вiдеоматерiал</Typography>
                </Button>
            </Stack>
        </Stack>
    )
}

export default Navigation
