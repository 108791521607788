import React from 'react'

import {
    Button,
    ButtonGroup,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { t } from 'i18next'
import {
    ArrowCircleRightOutlined,
    CallSplitRounded,
    Close,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import {
    CourseAccessType,
    CourseStreamType,
    CourseType,
    UserType,
} from 'api/root/generated'
import noBackground from 'assets/images/no-background.png'
import { Image } from 'components'
import { paths } from '@backoffice/index.routes'
import { AccessMenu } from '@backoffice/components/common'
import { useConfirmationDialog } from '@backoffice/hooks/common'
import ClientAccessItem from '@backoffice/components/pages/ClientPage/components/ClientAccessItem'

interface AccessTabContentProps {
    client: UserType
    courses: CourseType[]
    onNewAccess: (course: CourseType) => void
    onStreamChange: (access: CourseAccessType, streamId?: string | null) => void
    onCopyAccessLink: (access: CourseAccessType) => void
    onAccessClick: (access: CourseAccessType) => void
}
const ClientAccesses = ({
    client,
    courses,
    onNewAccess,
    onStreamChange,
    onCopyAccessLink,
    onAccessClick,
}: AccessTabContentProps) => {
    const navigate = useNavigate()

    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const accessedCoursesIds = client.accesses?.map(a => a?.course.id) || []
    const notAccessedCourses = courses.filter(
        c => !accessedCoursesIds.includes(c.id)
    )

    const handleGrantNewAccess = (item: CourseType) => {
        navigate(
            `../${paths.course(item?.id as string)}?selectable=true&email=${
                client.email
            }`
        )
    }

    const renderEmptyList = () => {
        return (
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                gap="10px"
                padding="25px"
            >
                <Typography fontSize="16px" color="secondary.dark">
                    {t('backoffice:client.sections.access.noContentCaption')}
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack
            sx={{
                flexGrow: 1,
                padding: '15px 25px 25px',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    marginBottom: tablet ? '50px' : '25px',
                }}
            >
                <Typography
                    sx={theme => ({
                        fontSize: tablet ? '26px' : '18px',
                        color: theme.palette.secondary.dark,
                    })}
                >
                    {t('backoffice:client.sections.access.title')}
                </Typography>
                <AccessMenu<CourseType>
                    compact={!tablet}
                    key="grantAccess"
                    onSelect={handleGrantNewAccess}
                    items={notAccessedCourses}
                />
            </Stack>
            <Stack gap="25px" divider={<Divider flexItem />}>
                {client?.accesses?.length !== 0
                    ? client.accesses?.map(a => {
                          const streams = a?.course?.streams.edges || []
                          return (
                              <ClientAccessItem
                                  key={a?.id}
                                  streams={
                                      streams.map(
                                          e => e?.node
                                      ) as CourseStreamType[]
                                  }
                                  onCopyAccessLink={onCopyAccessLink}
                                  onStreamChange={onStreamChange}
                                  onClick={onAccessClick}
                                  access={a as CourseAccessType}
                              />
                          )
                      })
                    : renderEmptyList()}
            </Stack>
        </Stack>
    )
}

export default ClientAccesses
