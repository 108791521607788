import React, { useState, JSX } from 'react'

import {
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    EditOutlined,
    DeleteOutline,
    Add,
    MoreVertOutlined,
} from '@mui/icons-material'

interface ActionItem {
    type: 'delete' | 'update' | 'add'
    title: string
    onClick: () => void
    key: string
}

interface ActionExtraItem {
    title: string
    onClick: () => void
    icon: JSX.Element
    key: string
}

interface ActionBarProps {
    variant: 'inline' | 'menu'
    actions: ActionItem[]
    extra?: ActionExtraItem[]
    size: 'small' | 'medium' | 'large'
    disabled?: boolean
}

const ActionBar = ({
    variant,
    actions,
    extra = [],
    size = 'medium',
    disabled = false,
}: ActionBarProps) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    if (variant === 'inline') {
        return (
            <Stack direction="row" gap="15px">
                {extra?.map(a => (
                    <Tooltip
                        key={a.key}
                        title={<Typography>{a.title}</Typography>}
                    >
                        <IconButton
                            color="secondary"
                            size={size}
                            disabled={disabled || false}
                            onClick={() => {
                                setAnchor(null)
                                a.onClick()
                            }}
                        >
                            {a.icon}
                        </IconButton>
                    </Tooltip>
                ))}
                {actions.map(a => (
                    <Tooltip
                        key={a.key}
                        title={<Typography>{a.title}</Typography>}
                    >
                        <IconButton
                            color="secondary"
                            size={size}
                            disabled={disabled || false}
                            onClick={() => {
                                setAnchor(null)
                                a.onClick()
                            }}
                        >
                            {a.type === 'delete' ? (
                                <DeleteOutline />
                            ) : a.type === 'add' ? (
                                <Add />
                            ) : (
                                <EditOutlined />
                            )}
                        </IconButton>
                    </Tooltip>
                ))}
            </Stack>
        )
    }

    return (
        <Stack direction="row" gap="15px">
            <IconButton
                disabled={disabled}
                onClick={e => setAnchor(e.currentTarget)}
                sx={theme => ({
                    fontSize: '18px',
                    color: theme.palette.secondary.dark,
                    '&:focus, &:focus-within': {
                        outline: 'none !important',
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize:
                            size === 'large'
                                ? '32px'
                                : size === 'medium'
                                ? '26px'
                                : '22px',
                        color: theme.palette.secondary.dark,
                    },
                    '&:hover': {
                        color: theme.palette.secondary.light,
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.text.primary,
                        },
                    },
                })}
            >
                <MoreVertOutlined />
            </IconButton>
            <Menu
                open={!!anchor}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={() => setAnchor(null)}
            >
                {extra?.map(a => (
                    <MenuItem
                        key={a.key}
                        sx={{
                            gap: '10px',
                        }}
                        onClick={() => {
                            setAnchor(null)
                            a.onClick()
                        }}
                    >
                        {a.icon}
                        <Typography>{a.title}</Typography>
                    </MenuItem>
                ))}
                {actions.map(a => (
                    <MenuItem
                        key={a.key}
                        sx={{
                            gap: '10px',
                        }}
                        onClick={() => {
                            setAnchor(null)
                            a.onClick()
                        }}
                    >
                        {a.type === 'delete' ? (
                            <DeleteOutline />
                        ) : a.type === 'add' ? (
                            <Add />
                        ) : (
                            <EditOutlined />
                        )}
                        <Typography>{a.title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Stack>
    )
}

export default ActionBar
