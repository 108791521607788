import React, { JSX, ReactElement, ReactNode } from 'react'

import { Stack, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { t } from 'i18next'

import { PAGE_LAYOUT_STYLES } from '@backoffice/components/pages/index.config'
import { PageHeader } from '@backoffice/components/common'
import { useDocumentTitle } from '../../../../../hooks/common'

interface PageLayoutProps {
    title: string
    pageTitle: string
    caption?: string | null
    actions?: JSX.Element[]
    onBack?: () => void
    children: ReactNode | ReactNode[]
    sx?: SxProps
}

const PageLayout = ({
    title,
    pageTitle,
    caption,
    actions,
    onBack,
    children,
    sx,
}: PageLayoutProps) => {
    useDocumentTitle(pageTitle)

    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Stack
            sx={{
                ...PAGE_LAYOUT_STYLES,
                ...(tablet && {
                    padding: '5% 50px',
                }),
                ...sx,
            }}
        >
            <PageHeader
                onBack={onBack}
                title={title}
                actions={actions}
                caption={caption}
            />
            {children}
        </Stack>
    )
}

export default PageLayout
