import React, { JSX } from 'react'

import {
    Dialog as MuiDialog,
    DialogContent,
    IconButton,
    Slide,
    SxProps,
    useMediaQuery,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'

import DialogButtons from './Buttons'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export interface DialogProps {
    open?: boolean
    closable?: boolean
    onClose: () => void
    onSubmit?: () => void
    children?: JSX.Element
    sx?: SxProps
    fullWidth?: boolean
}

const Dialog = (props: DialogProps): JSX.Element => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const {
        children,
        onSubmit,
        onClose,
        open = true,
        closable = true,
        sx = {},
        fullWidth = false,
    } = props
    return (
        <MuiDialog
            maxWidth={false}
            open={open}
            fullWidth={fullWidth}
            keepMounted
            onClose={() => {
                if (closable) {
                    onClose()
                }
            }}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    background: '#181818',
                    borderRadius: '8px',
                },
            }}
            sx={{
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                ...(lessThan700px && {
                    '& .MuiDialog-paper': {
                        margin: '0',
                    },
                }),
                ...sx,
            }}
        >
            <DialogContent
                sx={{
                    padding: 0,
                    display: 'flex',
                    backgroundColor: '#181818',
                    borderRadius: '8px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                    border: '1px solid #222',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '@media (max-width:550px)': {
                        minWidth: '0',
                    },
                }}
            >
                {closable && (
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: '#a9a9a9',
                            zIndex: 2,
                            background: '#2f2f2f',
                            '& .MuiSvgIcon-root': {
                                fontSize: '20px',
                            },
                            '@media (max-width:600px)': {
                                '& .MuiSvgIcon-root': {
                                    fontSize: '15px',
                                },
                            },

                            '&:hover': {
                                background: '#181818',
                            },
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                )}
                {children}
            </DialogContent>
            {onSubmit && (
                <DialogButtons onSubmit={onSubmit} onCancel={onClose} />
            )}
        </MuiDialog>
    )
}

export default Dialog
