import React, { JSX } from 'react'

import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { useInView } from 'react-intersection-observer'

import { Breadcrumb } from '@backoffice/components/common'

interface PageHeaderProps {
    title: string
    caption?: string | null
    actions?: JSX.Element[]
    onBack?: () => void
}

const PageHeader = ({ title, actions, caption, onBack }: PageHeaderProps) => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const { ref, inView } = useInView({
        threshold: 1,
        initialInView: true,
    })

    const renderStickyHeader = () => {
        return (
            <Stack
                position="fixed"
                left={0}
                right={0}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={tablet ? '15px' : '25px'}
                sx={{
                    padding: desktop
                        ? '15px 10%'
                        : tablet
                        ? '10px 10%'
                        : '10px 10px',
                    backgroundColor: '#272727',
                    transition: '0.3s ease',
                    top: inView ? '-100%' : 0,
                    opacity: inView ? '0' : 1,
                    zIndex: 2,
                    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                }}
            >
                <Breadcrumb onBack={onBack} title={title} caption={caption} />
                <Stack
                    gap="15px"
                    direction="row"
                    alignItems="center"
                    alignSelf="center"
                >
                    {actions}
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack ref={ref}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={tablet ? '15px' : '25px'}
                sx={{
                    ...(!inView && {
                        opacity: 0,
                    }),
                }}
            >
                <Breadcrumb onBack={onBack} title={title} caption={caption} />
                <Stack
                    gap="15px"
                    direction="row"
                    alignItems="center"
                    alignSelf={tablet ? 'flex-end' : 'flex-start'}
                >
                    {actions}
                </Stack>
            </Stack>
            {renderStickyHeader()}
        </Stack>
    )
}

export default PageHeader
