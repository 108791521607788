import React, { useEffect } from 'react'

import {
    Box,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    SxProps,
    Theme,
    Typography,
} from '@mui/material'
import { EditorContent, useEditor, Editor as TipTapEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import { Delete, Description, Download } from '@mui/icons-material'

import EditorToolbar from './components/EditorToolbar'
import { UploadedItem } from '../../types'

export enum TOOLBAR_VARIANTS {
    DEFAULT = 'default',
    NONE = 'none',
    ATTACHMENT = 'attachment',
}

export interface EditorProps {
    editable?: boolean
    bordered?: boolean
    heightToContent?: boolean
    content?: string
    onChange?: (content: string) => void
    onFileChange?: (items: UploadedItem[]) => void
    uploadedFiles?: UploadedItem[]
    onDeadlineChange?: (value: Date | null) => void
    deadline?: Date | null
    error?: boolean
    helperText?: string
    sx?: SxProps
    toolbar?: TOOLBAR_VARIANTS
    deadlineError?: boolean
}

const Editor: React.FC<EditorProps> = ({
    editable = true,
    content,
    onChange,
    onFileChange,
    uploadedFiles,
    toolbar = TOOLBAR_VARIANTS.DEFAULT,
    bordered = false,
    heightToContent = true,
    deadline,
    onDeadlineChange,
    error = false,
    helperText = '',
    deadlineError,
    sx = {},
}) => {
    const editor = useEditor({
        extensions: [StarterKit, Underline],
        content: content ? JSON.parse(content) : '',
        editable,
        onUpdate: ({ editor }) => {
            const updatedContent = JSON.stringify(editor.getJSON())
            if (onChange) {
                onChange(updatedContent)
            }
        },
    })

    useEffect(() => {
        return () => {
            if (editor) {
                editor.destroy()
            }
        }
    }, [editor])

    useEffect(() => {
        if (editor) {
            editor.setEditable(editable)
        }
    }, [editable])

    useEffect(() => {
        if (editor) {
            const newContent = content ? JSON.parse(content) : ''
            const currentContent = editor.getJSON()

            if (JSON.stringify(newContent) !== JSON.stringify(currentContent)) {
                editor.commands.setContent(newContent)
            }
        }
    }, [content, editor])

    const handleDownloadFile = (item: UploadedItem) => {
        const a = document.createElement('a')
        a.href = item.preview as string
        a.download = item.file.name
        a.target = '_blank'
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    const handleDeleteFile = (id: string, fromClient?: boolean) => {
        if (onFileChange && uploadedFiles) {
            if (fromClient) {
                onFileChange(uploadedFiles.filter(item => item.id !== id))
            } else {
                onFileChange(
                    uploadedFiles.map(item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                delete: true,
                            }
                        }
                        return item
                    })
                )
            }
        }
    }

    const renderFile = (item: UploadedItem) => {
        return (
            <Grid item key={item.id || item.file.name}>
                <Paper
                    elevation={2}
                    data-class="attachment-item"
                    sx={{
                        position: 'relative',
                        width: 90,
                        height: 90,
                        textAlign: 'center',
                        border: '1px solid rgb(70, 70, 70)',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        '&:hover .overlay, &:hover .darken': {
                            opacity: 1,
                        },
                    }}
                >
                    <Box sx={{ position: 'relative' }}>
                        {item.uploadType === 'image' && item.preview ? (
                            <img
                                src={item.preview}
                                alt={item.file.name}
                                style={{
                                    width: '100%',
                                    height: 90,
                                    objectFit: 'cover',
                                    verticalAlign: 'middle',
                                }}
                            />
                        ) : (
                            <Stack
                                gap="10px"
                                alignItems="center"
                                padding="15px"
                            >
                                <Description
                                    sx={{
                                        fontSize: '24px',
                                    }}
                                />
                                <Typography
                                    noWrap
                                    sx={{
                                        maxWidth: '100px',
                                        fontSize: '18px',
                                    }}
                                >
                                    {item.file?.name.substring(
                                        item.file?.name.lastIndexOf(
                                            '.'
                                        ) as number
                                    )}
                                </Typography>
                            </Stack>
                        )}
                        <Box
                            className="darken"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                opacity: 0,
                                transition: 'opacity 0.3s ease',
                            }}
                        />
                        <Stack
                            className="overlay"
                            sx={{
                                flexDirection: 'row',
                                gap: '10px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 2,
                                opacity: 0,
                                transition: 'opacity 0.3s ease',
                            }}
                        >
                            {editable && (
                                <IconButton
                                    size="medium"
                                    onClick={e => {
                                        e.stopPropagation()
                                        handleDeleteFile(
                                            item.id,
                                            item.fromClient
                                        )
                                    }}
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            )}
                            {!item.fromClient && (
                                <IconButton
                                    size="medium"
                                    onClick={e => {
                                        e.stopPropagation()
                                        handleDownloadFile(item)
                                    }}
                                >
                                    <Download fontSize="small" />
                                </IconButton>
                            )}
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
            }}
        >
            {toolbar !== TOOLBAR_VARIANTS.NONE ? (
                editable ? (
                    <EditorToolbar
                        onDeadlineChange={onDeadlineChange}
                        deadline={deadline}
                        deadlineError={deadlineError}
                        active={editable}
                        toolbar={toolbar}
                        editor={editor}
                        onFileChange={onFileChange}
                        uploadedFiles={uploadedFiles}
                    />
                ) : null
            ) : null}

            <Stack
                divider={
                    uploadedFiles &&
                    uploadedFiles.filter(f => !f.delete).length && <Divider />
                }
                sx={{
                    ...(bordered && {
                        border: '1px solid #4c4b4b',
                    }),
                    p: 2,
                    gap: '15px',
                    borderRadius: error ? '4px 0' : 1,
                    ...(!heightToContent && {
                        minHeight: '400px',
                    }),
                    backgroundColor: editable ? '#3a3a3a' : 'inherit',
                    '& .ProseMirror': {
                        color: 'white',
                        ...(!heightToContent && {
                            minHeight: '400px',
                        }),
                        '&:focus': {
                            outline: 'none',
                        },
                    },
                    '& p': {
                        margin: 0,
                    },
                    ...sx,
                }}
            >
                <EditorContent editor={editor as TipTapEditor} />
                <Grid container spacing={2}>
                    {uploadedFiles?.filter(f => !f.delete).map(renderFile)}
                </Grid>
            </Stack>
            {error && (
                <Paper
                    sx={(theme: Theme) => ({
                        position: 'absolute',
                        bottom: '-44px',
                        left: 0,
                        right: 0,
                        padding: '10px',
                        borderRadius: '0 0 4px 4px',
                        backgroundColor: theme.palette.error.main,
                    })}
                >
                    <Typography>{helperText}</Typography>
                </Paper>
            )}
        </Box>
    )
}

export default Editor
