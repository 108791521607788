import { t } from 'i18next'
import { array, boolean, date, mixed, object, string } from 'yup'
import { isAfter, isEqual, startOfDay } from 'date-fns'

import { UploadedItem } from 'types'
import { isTiptapContentEmpty } from '../../../../../utils/common.utils'

export interface HomeworkFormValues {
    content: string
    attachments: UploadedItem[]
    requireModeration: boolean
    editable: boolean
    deadline: Date | null
}

const UploadedItemSchema = object({
    id: string().required(t('auth:errors.required-field') || ''),
    file: mixed()
        .test(
            'is-file-or-object',
            t('backoffice:homework.error.fileValidation') || '',
            value => {
                if (typeof File !== 'undefined' && value instanceof File) {
                    return true
                }
                return (
                    typeof value === 'object' &&
                    value !== null &&
                    'name' in value &&
                    typeof (value as any).name === 'string'
                )
            }
        )
        .required(t('auth:errors.required-field') || ''),
    preview: string().optional(),
    uploadType: string()
        .oneOf(
            ['file', 'image'],
            t('backoffice:homework.error.invalidUploadType') || ''
        )
        .required(t('auth:errors.required-field') || ''),
    fromClient: boolean().optional(),
    delete: boolean().optional(),
})

export const HomeworkFormScheme = object({
    content: string(),
    attachments: array().of(UploadedItemSchema),
    requireModeration: boolean().required(
        t('auth:errors.required-field') || ''
    ),
    editable: boolean().required(t('auth:errors.required-field') || ''),
    deadline: date()
        .nullable()
        .test(
            'is-tomorrow-or-later',
            t('backoffice:homework.error.deadlineMustBeTomorrowOrLater') ||
                'Deadline must be today or later',
            value => {
                if (!value) return true
                const selected = startOfDay(value)
                const today = startOfDay(new Date())
                return isEqual(selected, today) || isAfter(selected, today)
            }
        ),
}).test(
    'contentOrAttachments',
    t('backoffice:homework.error.oneOfRequired') ||
        'Either content or attachments is required',
    function (value) {
        const data =
            typeof value?.content === 'string'
                ? JSON.parse(value?.content)
                : value?.content
        const isContentEmpty = isTiptapContentEmpty(data)
        const attachments = value?.attachments?.filter(a => !a?.delete)
        if (isContentEmpty && attachments?.length === 0) {
            return this.createError({
                path: 'content',
                message:
                    t('backoffice:homework.error.oneOfRequired') ||
                    'Either content or attachments is required',
            })
        }
        return true
    }
)
