import React, { useState } from 'react'

import { Button, Menu, MenuItem, Typography, IconButton } from '@mui/material'
import { Add, KeyboardArrowRight } from '@mui/icons-material'
import { t } from 'i18next'

import { CourseType } from 'api/root/generated'

interface NestedMenuProps<T> {
    items: T[]
    onSelect: (item: T) => void
    compact?: boolean
    size?: 'small' | 'medium' | 'large'
    color?: 'primary' | 'secondary'
}

const AccessMenu = <T extends CourseType>({
    items,
    onSelect,
    compact = false,
    size = 'large',
    color = 'primary',
}: NestedMenuProps<T>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    return (
        <>
            {compact ? (
                <IconButton
                    size={size}
                    color={color}
                    onClick={event => setAnchorEl(event.currentTarget)}
                >
                    <Add />
                </IconButton>
            ) : (
                <Button
                    size={size}
                    variant="text"
                    color={color}
                    startIcon={<Add />}
                    onClick={event => setAnchorEl(event.currentTarget)}
                >
                    <Typography>
                        {t('backoffice:clients.actions.grantAccess')}
                    </Typography>
                </Button>
            )}

            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {items.length === 0 ? (
                    <MenuItem
                        sx={{
                            justifyContent: 'space-between',
                        }}
                        disabled
                        key="empty"
                    >
                        <Typography noWrap sx={{ maxWidth: '250px' }}>
                            Нема недоступного контенту
                        </Typography>
                    </MenuItem>
                ) : (
                    items.map((item: T) => (
                        <MenuItem
                            sx={{
                                justifyContent: 'space-between',
                            }}
                            key={item.id}
                            onClick={event => {
                                setAnchorEl(null)
                                onSelect(item)
                            }}
                        >
                            <Typography noWrap sx={{ maxWidth: '250px' }}>
                                {item?.name}
                            </Typography>
                        </MenuItem>
                    ))
                )}
            </Menu>
        </>
    )
}

export default AccessMenu
