import { object, string } from 'yup'
import { t } from 'i18next'

import { emailValidator } from '@auth/components/forms/index.validators'

export interface AccessFormValues {
    email: string
    stream?: string | null
}

export const AccessFormScheme = object({
    email: string()
        .test(emailValidator)
        .required(t('auth:errors.required-field') || ''),
    stream: string(),
})
