import React, { JSX, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { useTokenVerification, Credentials } from '@auth/hooks/verification'
import { ResetPasswordForm } from '../../forms'
import { Loader } from 'components'
import { paths } from '@auth/index.routes'
import { useDocumentTitle } from 'hooks/common'

const ResetPasswordPage = (): JSX.Element => {
    useDocumentTitle(t('auth:pageTitles.resetPassword'))

    const [searchParams] = useSearchParams()
    const [isLoading, setLoading] = useState(true)
    const [credentials, setCredentials] = useState<Credentials | undefined>()
    const navigate = useNavigate()

    useTokenVerification({
        uid: searchParams.get('uid64'),
        token: searchParams.get('token'),
        onSuccess: (params: Credentials) => {
            setCredentials(params)
        },
        onFinish: () => setLoading(false),
        skip: !searchParams.get('uid64') || !searchParams.get('token'),
    })

    const renderExpiredLayout = () => {
        return (
            <Typography>
                We have sent the new activation link to your email
            </Typography>
        )
    }

    if (isLoading) {
        return <Loader />
    }

    const handleSuccessResetPassword = () => {
        const redirectTo = searchParams.get('redirectTo')
        navigate(`../${paths.login}?redirectTo=${redirectTo}`)
    }

    return (
        <Box
            sx={{
                width: '100%',
                padding: '25px',
            }}
        >
            {credentials ? (
                <ResetPasswordForm
                    onSuccess={handleSuccessResetPassword}
                    uid={credentials.uid}
                    token={credentials.token}
                />
            ) : (
                renderExpiredLayout()
            )}
        </Box>
    )
}

export default ResetPasswordPage
