import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    ModuleCreateDocument,
    ModuleCreateMutation,
    ModuleUpdateDocument,
    ModuleUpdateMutation,
    MutationModuleCreateArgs,
    MutationModuleUpdateArgs,
    ModuleDeleteDocument,
    MutationModuleDeleteArgs,
    ModuleDeleteMutation,
    Maybe,
    ModuleDocument,
} from 'api/root/generated'

export const useModule = (id?: Maybe<string>) => {
    const { data, loading, error, refetch } = useQuery(ModuleDocument, {
        ...(id && {
            variables: {
                id,
            },
        }),
        skip: !id,
    })

    let module

    if (data?.module) {
        module = data.module
    }

    return {
        module,
        loading: loading || typeof data === 'undefined',
        error,
        refetch,
    }
}

export const useModulesAPI = () => {
    const [updateModule, { loading: updating }] =
        useMutation(ModuleUpdateDocument)
    const [createModule, { loading: creating }] =
        useMutation(ModuleCreateDocument)
    const [deleteModule, { loading: deleting }] =
        useMutation(ModuleDeleteDocument)

    const update = (data: MutationModuleUpdateArgs) =>
        updateModule({ variables: data }).then(
            (response: FetchResult<ModuleUpdateMutation>) =>
                response?.data?.moduleUpdate
        )

    const create = (data: MutationModuleCreateArgs) =>
        createModule({ variables: data }).then(
            (response: FetchResult<ModuleCreateMutation>) =>
                response?.data?.moduleCreate
        )

    const remove = (data: MutationModuleDeleteArgs) =>
        deleteModule({ variables: data }).then(
            (response: FetchResult<ModuleDeleteMutation>) =>
                response?.data?.moduleDelete
        )

    return {
        loading: updating || creating || deleting,
        update,
        create,
        remove,
    }
}
