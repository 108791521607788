import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { t } from 'i18next'

declare module '@mui/material/styles' {
    interface Palette {
        gold: Palette['primary']
    }

    interface PaletteOptions {
        gold?: PaletteOptions['primary']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        gold: true
    }
    interface TypographyPropsColorOverrides {
        gold: true
    }
}

const palette = {
    primary: {
        main: '#66b2ff',
    },
    secondary: {
        main: 'rgba(255, 255, 255, 0.7)',
        dark: 'rgba(255, 255, 255, 0.4)',
        light: 'rgba(255, 255, 255, 1)',
    },
    gold: {
        main: '#d6bf62',
        dark: '#97874c',
        light: '#efd77b',
    },
    info: {
        main: '#62f3f5',
    },
    warning: {
        main: '#fbd843',
    },
    error: { main: '#fb4f4d' },
    success: { main: '#23d879' },
    text: {
        secondary: 'rgba(255,255,255,0.7)',
        primary: 'rgba(255,255,255,1)',
    },
    background: {
        default: '#181818',
    },
    divider: 'rgba(255, 255, 255, 0.05)',
}

const theme = createTheme({
    palette: {
        ...palette,
        mode: 'dark',
    },
    breakpoints: {
        values: {
            xs: 428,
            sm: 768,
            md: 1280,
            lg: 1365,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Helvetica',
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper': {
                        borderRadius: '8px 8px 0 0',
                        background: '#424242',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    '& .MuiInputLabel-root': {
                        color: 'white',
                        backgroundColor: '#181818',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6e6e6e',
                    },
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiButtonBase-root': {
                        color: '#6e6e6e',
                    },
                    '& .MuiFormHelperText-root': {
                        '&.Mui-error': {
                            background: palette.error.main,
                            color: 'white',
                            padding: '5px 5px 5px 10px',
                            fontSize: '14px',
                            width: '100%',
                            zIndex: 2,
                            position: 'relative',
                            marginTop: '-1px',
                            borderRadius: '0 0 8px 8px',
                        },
                    },
                },
            },
            defaultProps: {
                variant: 'standard',
                fullWidth: true,
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:focus, &:focus-within': {
                        outline: 'none !important',
                    },
                },
                sizeLarge: {
                    fontSize: '18px',
                    '& .MuiSvgIcon-root': {
                        fontSize: '32px',
                    },
                },
                colorSecondary: {
                    color: palette.secondary.dark,
                    '& .MuiSvgIcon-root': {
                        color: palette.secondary.dark,
                    },
                    '&:hover': {
                        color: palette.secondary.light,
                        '& .MuiSvgIcon-root': {
                            color: palette.text.primary,
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        fill: palette.secondary.dark,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'none',
                    borderRadius: '12px',
                    '&:focus, &:focus-within': {
                        outline: 'none !important',
                    },
                },
                sizeLarge: {
                    fontSize: '18px',
                    '& .MuiSvgIcon-root': {
                        fontSize: '32px',
                    },
                    '& .MuiTypography-root': {
                        fontSize: '18px',
                    },
                    '& .MuiButton-endIcon>*:nth-of-type(1)': {
                        fontSize: '32px',
                    },
                },
                textSecondary: {
                    color: palette.secondary.dark,
                    '& .MuiSvgIcon-root': {
                        color: palette.secondary.dark,
                    },
                    '&:hover': {
                        color: palette.secondary.light,
                        '& .MuiSvgIcon-root': {
                            color: palette.text.primary,
                        },
                    },
                },
                contained: {},
            },
            defaultProps: {
                variant: 'contained',
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true,
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.MuiMenu-paper': {
                        borderRadius: '12px',
                        backgroundColor: palette.background.default,
                        '& .MuiMenuItem-root': {
                            gap: '15px',
                            padding: '10px 25px',
                        },
                    },
                },
            },
            defaultProps: {
                elevation: 1,
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '--DataGrid-overlayHeight': '400px',
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnHeaders': {
                        '&  > div[role="row"]': {
                            background: 'transparent !important',
                        },
                    },

                    '& .MuiDataGrid-overlay': {
                        background: 'transparent',
                    },
                    '& .MuiLinearProgress-root': {
                        height: '2px',
                    },
                    '& .MuiDataGrid-filler': {
                        '& > *': {
                            border: 'none',
                        },
                    },
                    '& .MuiDataGrid-columnHeader': {
                        '&.MuiDataGrid-withBorderColor': {
                            borderColor: 'rgba(255, 255, 255, .2)',
                        },
                        '&:focus, &:focus-within': {
                            outline: 'none',
                        },
                        '&.MuiDataGrid-columnHeaderCheckbox': {
                            '& .MuiSvgIcon-root': {
                                fill: palette.secondary.dark,
                            },
                        },
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: '18px',
                        color: 'rgba(255, 255, 255, 0.35)',
                    },
                    '& .MuiDataGrid-row': {
                        '--rowBorderColor': 'transparent',
                        '& .MuiDataGrid-cell': {
                            cursor: 'pointer',
                            // transition: '20.3s ease',
                            fontSize: '22px',
                            letterSpacing: '-1px',
                            color: palette.text.secondary,
                            '&.secondary': {
                                fontWeight: 'initial',
                                letterSpacing: 'initial',
                                fontSize: '16px',
                            },
                            '&.readonly': {
                                cursor: 'initial',
                            },
                            '&:focus, &:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-actionsCell': {
                                '&:focus, &:focus-within': {
                                    outline: 'none !important',
                                },
                            },
                            '&.MuiDataGrid-cellCheckbox': {
                                '& .MuiSvgIcon-root': {
                                    fill: palette.secondary.dark,
                                },
                            },
                        },
                        '&:hover': {
                            background: 'transparent',
                            '& .MuiDataGrid-cell:not(.readonly)': {
                                color: palette.text.primary,
                            },
                        },
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderColor: palette.divider,
                        '& .MuiTablePagination-root': {
                            color: palette.text.secondary,
                            '& .MuiTablePagination-displayedRows': {
                                fontSize: '16px',
                            },
                        },
                    },
                },
            },
            defaultProps: {
                disableColumnSelector: true,
                disableRowSelectionOnClick: true,
                disableColumnSorting: true,
                disableColumnResize: true,
                disableColumnMenu: true,
                hideFooter: true,
                rowHeight: 82,
                localeText: {
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            t('backoffice:common.table.labelDisplayedRows', {
                                count,
                                to,
                                from,
                            }),
                    },
                },
                slotProps: {
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'linear-progress',
                    },
                },
            },
        },
    },
})

export default theme
