import React from 'react'

import {
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    Notifications,
    NotificationsNone,
    StorefrontTwoTone,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useMatch, useNavigate } from 'react-router-dom'
import { keyframes } from '@emotion/react'

import { paths as storefrontPaths } from '@storefront/index.routes'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { Logo } from 'components'

const shakeAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  10% {
    transform: rotate(-15deg);
    background-color: rgba(214, 191, 98, 0.07);
  }
  20% {
    transform: rotate(15deg);
    background-color: rgba(214, 191, 98, 0.17);
  }
  30% {
    transform: rotate(-15deg);
    background-color: rgba(214, 191, 98, 0.27);
  }
  40% {
    transform: rotate(15deg);
    background-color: rgba(214, 191, 98, 0.37);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const Header = () => {
    const navigate = useNavigate()
    const isDashboard = !!useMatch('/backoffice')

    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const hasNotifications = false

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Logo
                noColor
                onClick={() =>
                    isDashboard
                        ? undefined
                        : navigate(`../${backofficePaths.backoffice}`)
                }
                height="52px"
            />
            <Stack
                gap={tablet ? '25px' : '10px'}
                direction="row"
                alignItems="center"
                divider={<Divider flexItem orientation="vertical" />}
            >
                {/* <Button */}
                {/*    sx={theme => ({ */}
                {/*        '& .MuiButton-startIcon': { */}
                {/*            marginRight: 0, */}
                {/*            color: theme.palette.primary.light, */}
                {/*            '& .MuiSvgIcon-root': { */}
                {/*                fontSize: '32px', */}
                {/*            }, */}
                {/*        }, */}
                {/*        '& .MuiButton-endIcon': { */}
                {/*            marginLeft: 0, */}
                {/*            color: theme.palette.secondary.dark, */}
                {/*        }, */}
                {/*    })} */}
                {/*    color="primary" */}
                {/*    variant="text" */}
                {/*    startIcon={<Add />} */}
                {/*    endIcon={<ArrowDropDown />} */}
                {/* /> */}
                <IconButton
                    size="large"
                    disabled
                    onClick={() => navigate(backofficePaths.notifications)}
                    sx={
                        hasNotifications
                            ? {
                                  animation: `${shakeAnimation} 3s ease-in-out infinite`,
                              }
                            : {}
                    }
                >
                    {hasNotifications ? (
                        <Notifications
                            sx={{
                                color: '#d6bf62',
                            }}
                        />
                    ) : (
                        <NotificationsNone />
                    )}
                </IconButton>
                <Tooltip
                    title={
                        <Typography fontSize="18px">
                            {t('backoffice:toStorefront')}
                        </Typography>
                    }
                >
                    <IconButton
                        onClick={() => navigate(`../${storefrontPaths.expert}`)}
                    >
                        <StorefrontTwoTone
                            sx={{
                                fontSize: '42px',
                                '& > path:first-of-type': {
                                    fill: '#e8383c',
                                    opacity: 1,
                                },
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    )
}

export default Header
