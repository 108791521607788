import React, { JSX } from 'react'

import { IconButton, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { AccountCircle, Add } from '@mui/icons-material'

import {
    AccessMenu,
    PageLayoutMobile,
    Paper,
    ContentList,
} from '@backoffice/components/common'
import { CourseType, UserType } from 'api/root/generated'
import { SearchFieldMobile } from 'components'
import { ClientsPageProps } from '@backoffice/components/pages/ClientsPage/index.types'
import { useDrawer } from '../../../../../hooks/common'

const ClientsPageMobile = ({
    courses,
    onAdd,
    onSearch,
    clients,
    onClick,
    onBack,
}: ClientsPageProps): JSX.Element => {
    const { open, close } = useDrawer()

    const handleOpenContentList = () => {
        open({
            component: ContentList,
            props: {
                courses,
                onSelect: (course: CourseType) => {
                    close()
                    onAdd(course)
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    return (
        <PageLayoutMobile
            pageTitle={t('backoffice:pageTitles.clientsPage')}
            onBack={onBack}
            title={t('backoffice:clients.title') as string}
            caption={t('backoffice:clients.caption') as string}
            actions={[
                <IconButton color="primary" onClick={handleOpenContentList}>
                    <Add />
                </IconButton>,
            ]}
            sx={{
                gap: '25px',
            }}
            toolbar={() => (
                <SearchFieldMobile
                    key="search"
                    sx={{
                        minWidth: '250px',
                    }}
                    light
                    placeholder={t('backoffice:access.search') as string}
                    onChange={onSearch}
                />
            )}
        >
            <Paper
                content={() =>
                    clients?.map((client: UserType) => (
                        <Stack
                            key={client.id}
                            direction="row"
                            alignItems="center"
                            gap="15px"
                            padding="15px 10px"
                            onClick={() => onClick(client.id)}
                        >
                            <AccountCircle
                                sx={{
                                    color: 'rgba(255, 255, 255, .1)',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                }}
                            >
                                {client.email}
                            </Typography>
                        </Stack>
                    ))
                }
            />
        </PageLayoutMobile>
    )
}
export default ClientsPageMobile
