import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    Divider,
    useTheme,
    useMediaQuery,
    IconButton,
} from '@mui/material'
import { t } from 'i18next'
import { EditOutlined } from '@mui/icons-material'

import Layout from './Layout'
import { UserType } from 'api/root/generated'

interface AccountProps {
    user: UserType
    onEditClick: () => void
}

const Socials = ({ user, onEditClick }: AccountProps): JSX.Element => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Layout
            title={t('backoffice:settings.socials.title')}
            actions={[
                <IconButton
                    size="large"
                    color="secondary"
                    onClick={onEditClick}
                >
                    <EditOutlined />
                </IconButton>,
            ]}
        >
            <Stack gap="15px" divider={<Divider />}>
                <Stack
                    direction={tablet ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems={tablet ? 'center' : 'flex-start'}
                    gap="25px"
                    padding="10px 0"
                >
                    <Typography fontSize="22px">
                        {t('backoffice:settings.socials.instagramLabel')}
                    </Typography>

                    <Typography fontSize="22px" />
                </Stack>
                <Stack
                    direction={tablet ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems={tablet ? 'center' : 'flex-start'}
                    gap="25px"
                    padding="10px 0"
                >
                    <Typography fontSize="22px">
                        {t('backoffice:settings.socials.tiktokLabel')}
                    </Typography>
                    <Typography fontSize="22px" />
                </Stack>
                <Stack
                    direction={tablet ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems={tablet ? 'center' : 'flex-start'}
                    gap="25px"
                    padding="10px 0"
                >
                    <Typography fontSize="22px">
                        {t('backoffice:settings.socials.facebookLabel')}
                    </Typography>
                    <Typography fontSize="22px" />
                </Stack>
            </Stack>
        </Layout>
    )
}
export default Socials
