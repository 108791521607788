import React, { JSX } from 'react'

import { Stack, Typography } from '@mui/material'

interface LayoutProps {
    title: string
    children: JSX.Element
    actions?: JSX.Element[]
}

const Layout = ({ title, children, actions }: LayoutProps) => {
    return (
        <Stack
            gap="25px"
            sx={{
                padding: '15px 25px 25px',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
        >
            <Stack
                gap="10px"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography fontSize="30px">{title}</Typography>
                {actions && (
                    <Stack direction="row" alignItems="center" gap="15px">
                        {actions}
                    </Stack>
                )}
            </Stack>
            {children}
        </Stack>
    )
}

export default Layout
