import React, { useEffect, useRef, useState } from 'react'

import { Divider, IconButton, Stack, Typography } from '@mui/material'
import { FormatQuote, Send } from '@mui/icons-material'
import { format } from 'date-fns-tz'

import { UserType, SubmissionCommentType } from 'api/root/generated'
import { Editor, TOOLBAR_VARIANTS } from 'components'
import { UploadedItem } from '../../types'
import { formatUploadedItemFromUrl } from '../../utils/common.utils'

interface CommentsProps {
    comments: SubmissionCommentType[]
    coach: UserType
    readonly?: boolean
    empty?: boolean
    onSendComment: (
        id: string,
        content: string,
        attachments: UploadedItem[],
        onSuccess: (comment: SubmissionCommentType) => void
    ) => void
    submissionId: string
}

const SubmissionCommentsMobile = ({
    comments,
    coach,
    readonly = false,
    empty = false,
    onSendComment,
    submissionId,
}: CommentsProps) => {
    const lastCommentRef = useRef<HTMLDivElement>(null)

    const [submissionComments, setSubmissionComments] =
        useState<SubmissionCommentType[]>(comments)
    console.log(submissionComments)
    useEffect(() => {
        if (lastCommentRef.current) {
            lastCommentRef.current.scrollIntoView()
        }
    }, [])

    const [newCommentContent, setNewCommentContent] = useState('')
    const [newAttachments, setNewAttachments] = useState<UploadedItem[]>([])

    const handleFileUploadChange = (items: UploadedItem[]) => {
        setNewAttachments(items)
    }
    const renderComment = (
        comment: SubmissionCommentType,
        index: number,
        array: SubmissionCommentType[]
    ) => {
        const authoredByCoach = coach?.email === comment.author.email

        return (
            <Stack
                gap="5px"
                key={comment.id}
                ref={index === array.length - 1 ? lastCommentRef : undefined}
            >
                <Stack
                    direction="row"
                    gap="10px"
                    alignItems="baseline"
                    justifyContent="space-between"
                >
                    <Typography
                        noWrap
                        sx={theme => ({
                            maxWidth: '200px',
                            fontSize: '15px',
                            fontWeight: 700,
                            color: authoredByCoach
                                ? theme.palette.primary.main
                                : 'rgba(255, 255, 255, 1)',
                        })}
                    >
                        {authoredByCoach ? coach?.name : comment.author.email}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            color: 'rgba(255, 255, 255, .4)',
                        }}
                    >
                        {format(
                            new Date(comment.createdAt),
                            'dd.MM.yyyy HH:mm',
                            {
                                timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone,
                            }
                        )}
                    </Typography>
                </Stack>
                <Stack direction="row" gap="5px">
                    <FormatQuote
                        sx={{
                            fontSize: '20px',
                            color: 'rgba(255, 255, 255, .2)',
                        }}
                    />
                    <Editor
                        uploadedFiles={formatUploadedItemFromUrl(
                            comment.attachments || []
                        )}
                        editable={false}
                        content={comment.content}
                        sx={{
                            fontSize: '14px',
                            padding: '5px',
                            '& [data-class="attachment-item"]': {
                                width: '45px',
                                height: '45px',
                                '& img': {
                                    height: '45px !important',
                                },
                            },
                        }}
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack
            sx={{
                paddingTop: '20px',
                height: '500px',
                background: '#272727',
            }}
        >
            <Stack sx={{ overflowY: 'auto' }} padding="20px 15px 20px">
                {submissionComments.length > 0 && (
                    <Stack gap="10px" divider={<Divider />}>
                        {!empty && comments.map(renderComment)}
                    </Stack>
                )}
            </Stack>
            <Stack
                sx={{
                    borderTop: '1px solid #272727',
                    background: '#1c1c1c',
                    width: '100%',
                    padding: '10px',
                }}
            >
                <Stack direction="row" alignItems="flex-end" gap="15px">
                    {!readonly && (
                        <Editor
                            content={newCommentContent}
                            bordered
                            editable
                            toolbar={TOOLBAR_VARIANTS.ATTACHMENT}
                            onChange={setNewCommentContent}
                            onFileChange={handleFileUploadChange}
                            uploadedFiles={newAttachments}
                            sx={{
                                fontSize: '14px',
                                padding: '5px 10px',
                                '& [data-class="attachment-item"]': {
                                    width: '45px',
                                    height: '45px',
                                    '& img': {
                                        height: '45px !important',
                                    },
                                },
                            }}
                        />
                    )}
                    {!readonly && (
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() =>
                                onSendComment(
                                    submissionId,
                                    newCommentContent,
                                    newAttachments,
                                    (comment: SubmissionCommentType) => {
                                        setSubmissionComments(prevState => [
                                            ...prevState,
                                            comment,
                                        ])
                                        setNewCommentContent('')
                                        setNewAttachments([])
                                    }
                                )
                            }
                        >
                            <Send />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default SubmissionCommentsMobile
