import React, { useState } from 'react'

import {
    Button,
    Stack,
    TextField,
    Typography,
    MenuItem,
    InputAdornment,
} from '@mui/material'
import { t } from 'i18next'
import isEmail from 'validator/lib/isEmail'
import { KeyboardArrowRight, CallSplitRounded } from '@mui/icons-material'

import { Image } from 'components'
import { CourseStreamType, CourseType } from 'api/root/generated'
import noBackground from 'assets/images/no-background.png'

export interface GrantAccessScreenProps {
    course: CourseType
    onSubmit: (email: string, streamId?: string) => void
    onCancel: () => void
}

const GrantAccessScreen = ({
    course,
    onSubmit,
    onCancel,
}: GrantAccessScreenProps) => {
    const [email, setEmail] = useState('')
    const [stream, setStream] = useState<string>('none')

    return (
        <Stack
            gap="50px"
            sx={{
                padding: '18px 25px 25px',
                width: '600px',
            }}
        >
            <Stack gap="25px">
                <Typography fontSize="20px">
                    {t('backoffice:clients.actions.grantAccessTitle')}
                </Typography>
                <Typography fontSize="17px" color="rgba(255, 255, 255, 0.6)">
                    {t('backoffice:clients.actions.grantAccessCaption')}
                </Typography>
                <TextField
                    error={email !== '' && !isEmail(email)}
                    autoFocus
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    variant="outlined"
                    placeholder={
                        t(
                            'backoffice:clients.actions.grantAccessEmailPlaceholder'
                        ) as string
                    }
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '24px',
                            fontWeight: 700,
                        },
                    }}
                />
                {course.streams.edges.length > 0 && (
                    <Stack gap="20px">
                        <Typography
                            fontSize="17px"
                            color="rgba(255, 255, 255, 0.6)"
                        >
                            {t(
                                'backoffice:clients.actions.chooseStreamCaption'
                            )}
                        </Typography>
                        <TextField
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CallSplitRounded />
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    padding: '5px 0 5px 14px',
                                },
                            }}
                            select
                            variant="outlined"
                            placeholder="Stream"
                            onChange={e => setStream(e.target.value)}
                            value={stream}
                            defaultValue="none"
                        >
                            <MenuItem value="none">
                                {t('backoffice:access.grant.noStream')}
                            </MenuItem>
                            {course.streams.edges
                                .map(e => e?.node as CourseStreamType)
                                .map(stream => (
                                    <MenuItem key={stream.id} value={stream.id}>
                                        {stream?.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Stack>
                )}
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="15px"
                    sx={{
                        padding: '15px',
                        borderRadius: '12px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <Image
                        sx={{
                            maxWidth: '100px',
                        }}
                        src={
                            (course?.appearance?.background
                                ?.img150x100 as string) || noBackground
                        }
                    />
                    <Stack>
                        <Typography
                            fontSize="22px"
                            fontWeight={700}
                            lineHeight="23px"
                        >
                            {course?.name}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Stack direction="row" gap="15px" justifyContent="flex-end">
                <Button color="secondary" variant="text" onClick={onCancel}>
                    <Typography>Назад</Typography>
                </Button>
                <Button
                    disabled={!isEmail(email)}
                    color="success"
                    variant="outlined"
                    endIcon={<KeyboardArrowRight />}
                    onClick={() =>
                        stream === 'none'
                            ? onSubmit(email)
                            : onSubmit(email, stream)
                    }
                >
                    <Typography>Перейти до вибору урокiв</Typography>
                </Button>
            </Stack>
        </Stack>
    )
}

export default GrantAccessScreen
