import React, { JSX } from 'react'

import { t } from 'i18next'
import {
    Button,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import {
    Add,
    DeleteOutline,
    EditOutlined,
    InfoOutlined,
    PlayCircle,
    Quiz,
    CheckOutlined,
    CloseOutlined,
} from '@mui/icons-material'

import { CourseType, ModuleType, LessonType } from 'api/root/generated'
import { Image } from '../index'
import noBackground from '../../assets/images/no-background.png'

export interface ResourceConfirmationProps<T> {
    onApprove: () => void
    onReject: () => void
    data: T
    type: 'lesson' | 'module' | 'course'
}

const ResourceConfirmation = <T extends ModuleType | CourseType | LessonType>({
    onApprove,
    onReject,
    data,
    type,
}: ResourceConfirmationProps<T>): JSX.Element => {
    const theme = useTheme()

    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const image =
        type === 'lesson'
            ? (data as LessonType).videoPreview?.img60x40
            : (data as CourseType).appearance?.background?.img150x100

    return tablet ? (
        <Stack
            sx={{
                minWidth: 'min(80vw, 500px)',
                padding: '40px',
            }}
            gap="25px"
        >
            <Typography
                sx={{
                    fontSize: '20px',
                    maxWidth: '500px',
                }}
            >
                {t('backoffice:confirmation.title')}
            </Typography>
            <Typography
                sx={{
                    fontSize: '24px',
                }}
            >
                {data.name}
            </Typography>
            <Stack direction="row" alignItems="center" gap="15px">
                <InfoOutlined
                    color="warning"
                    sx={{
                        fontSize: '35px',
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '16px',
                        maxWidth: '500px',
                    }}
                >
                    {t('backoffice:confirmation.caption')}
                </Typography>
            </Stack>
            <Stack justifyContent="flex-end" gap="15px" direction="row">
                <Button color="error" variant="outlined" onClick={onReject}>
                    <Typography>
                        {t('backoffice:confirmation.reject')}
                    </Typography>
                </Button>
                <Button
                    sx={theme => ({
                        '& .MuiTypography-root': {
                            fontSize: '16px',
                            color: theme.palette.background.default,
                        },
                    })}
                    onClick={onApprove}
                >
                    <Typography>
                        {t('backoffice:confirmation.approve')}
                    </Typography>
                </Button>
            </Stack>
        </Stack>
    ) : (
        <Stack divider={<Divider />}>
            <Stack padding="20px" gap="15px">
                <Typography fontSize="16px" textAlign="center">
                    Подтвердите удаление ресурса?
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="15px"
                    padding="10px"
                    sx={{
                        borderRadius: '12px',
                        background: '#181818',
                    }}
                >
                    {type !== 'module' && (
                        <Image
                            sx={{
                                minWidth: '32px',
                                width: '32px',
                                height: '32px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                            }}
                            src={image || noBackground}
                        />
                    )}

                    <Typography
                        sx={{
                            fontSize: '18px',
                            lineHeight: '20px',
                        }}
                    >
                        {data.name}
                    </Typography>
                </Stack>
                {type !== 'lesson' && (
                    <Stack direction="row" gap="10px">
                        <InfoOutlined
                            color="warning"
                            sx={{ fontSize: '20px' }}
                        />
                        <Typography
                            sx={{
                                fontSize: '13px',
                            }}
                        >
                            {t('backoffice:confirmation.caption')}
                        </Typography>
                    </Stack>
                )}
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
            >
                <Button
                    fullWidth
                    color="error"
                    variant="text"
                    startIcon={<CloseOutlined />}
                    onClick={onReject}
                    sx={{
                        padding: '15px 25px',
                    }}
                >
                    <Typography>
                        {t('backoffice:confirmation.reject')}
                    </Typography>
                </Button>

                <Button
                    fullWidth
                    color="success"
                    variant="text"
                    startIcon={<CheckOutlined />}
                    onClick={onApprove}
                    sx={{
                        padding: '15px 25px',
                    }}
                >
                    <Typography>
                        {t('backoffice:confirmation.approve')}
                    </Typography>
                </Button>
            </Stack>
        </Stack>
    )
}
export default ResourceConfirmation
