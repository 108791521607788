import { styled } from '@mui/material/styles'

import ScrollToTop from './ScrollToTop'
import Dialog, { DialogProps } from './Dialog'
import Drawer, { DrawerProps } from './Drawer'
import ReactiveDialog from './ReactiveDialog'
import ReactiveDrawer from './ReactiveDrawer'
import Logo, { LogoProps } from './Logo'
import SocialsList, { SocialItem, Socials } from './SocialsList'
import ResourceConfirmation, {
    ResourceConfirmationProps,
} from './ResourceConfirmation'
import FeedbackPreview from './FeedbackPreview'
import FeedbackSample from './FeedbackSample'
import FilePicker from './FilePicker'
import Player from './Player'
import Toast from './Toast'
import SelectField from './SelectField'
import PasswordField from './PasswordField'
import SearchField from './SearchField'
import SearchFieldMobile from './SearchFieldMobile'
import ColorPicker from './ColorPicker'
import GlobalError from './GlobalError'
import Loader from './Loader'
import SubmissionComments from './SubmissionComments'
import SubmissionCommentsMobile from './SubmissionCommentsMobile'
import FileUpload from './FileUpload'
import Editor, { EditorProps, TOOLBAR_VARIANTS } from './Editor'

const Image = styled('img')``
const Input = styled('input')``
const Form = styled('form')``
const Svg = styled('svg')``

export {
    ScrollToTop,
    TOOLBAR_VARIANTS,
    Dialog,
    Drawer,
    ReactiveDialog,
    ReactiveDrawer,
    Logo,
    SocialsList,
    ResourceConfirmation,
    FeedbackPreview,
    FeedbackSample,
    Image,
    Input,
    Form,
    Svg,
    FilePicker,
    Player,
    Toast,
    ColorPicker,
    SelectField,
    PasswordField,
    SearchField,
    SearchFieldMobile,
    GlobalError,
    Loader,
    Socials,
    SubmissionComments,
    SubmissionCommentsMobile,
    FileUpload,
    Editor,
}

export type {
    DialogProps,
    DrawerProps,
    SocialItem,
    ResourceConfirmationProps,
    LogoProps,
    EditorProps,
}
