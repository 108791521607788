import { intervalToDuration, formatDuration } from 'date-fns'
import { uk } from 'date-fns/locale'

import { UploadedItem } from 'types'
import {
    AttachmentInput,
    AttachmentType,
    KeyValType,
} from '../api/root/generated'

interface TiptapNode {
    type: string
    content?: TiptapNode[]
    text?: string
}

export const extractSubdomain = (location: Location): string | null => {
    const URLSegmentsCount = process.env.NODE_ENV === 'production' ? 3 : 2

    const { host } = location
    const parts = host.split('.')
    if (parts.length === URLSegmentsCount) {
        return parts[0]
    }

    return null
}

export const formatUploadedItemFromUrl = (
    attachments?: Array<AttachmentType | null>
): UploadedItem[] => {
    if (!attachments) {
        return []
    }

    const validAttachments = attachments.filter(
        (attachment): attachment is AttachmentType => attachment !== null
    )

    return validAttachments.map(attachment => {
        const { file, id, filename } = attachment

        const fileNameWithParams = file.split('/').pop() || 'file'
        const fileName = fileNameWithParams.split('?')[0]

        const pseudoFile = { name: filename } as File

        const isImage = /\.(jpe?g|png|gif)$/i.test(fileName)

        return {
            id,
            file: pseudoFile,
            preview: file,
            uploadType: isImage ? 'image' : 'file',
        }
    })
}

export const normalizeAttachmentsForUpdate = (attachments: UploadedItem[]) => {
    const result: AttachmentInput[] = []
    attachments.forEach(attachment => {
        const { file, id, fromClient } = attachment
        const validFile = file instanceof File
        if (validFile && fromClient) {
            result.push({ file })
        }
        if (attachment.delete) {
            result.push({ id, delete: true })
        }
    })
    return result
}

export const isTiptapContentEmpty = (
    content: TiptapNode | null | undefined
): boolean => {
    if (!content || content.type !== 'doc' || !Array.isArray(content.content))
        return true

    return content.content.every(
        node =>
            node.type === 'paragraph' &&
            (!node.content ||
                node.content.every(
                    child => child.type === 'text' && !child.text?.trim()
                ))
    )
}
export const resolveTextByStatus = (
    status: KeyValType | undefined | null
): string => {
    switch (status?.val) {
        case 'Pending':
            return 'На перевірці'
        case 'Approved':
            return 'Перевірено'
        case 'Rejected':
            return 'Відхилено'
        default:
            return ''
    }
}

export const formatDateDuration = (range: { start: Date; end: Date }) =>
    formatDuration(intervalToDuration(range), {
        locale: uk,
        format: ['days', 'hours', 'minutes'],
    })
