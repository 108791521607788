import React, { JSX, useRef, useState } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useDialog, useDrawer } from 'hooks/common'
import { useCourses } from 'hooks/courses'
import { paths } from '@backoffice/index.routes'
import ClientsPageMobile from './index.mobile'
import ClientsPageDesktop from './index.desktop'
import { CourseType } from '../../../../../api/root/generated'
import { GrantAccessScreen } from '@backoffice/components/common'
import { useClients } from '@backoffice/hooks/clients'
import { AccessForm } from '@backoffice/components/forms'

const PAGE_SIZE = 15

const ClientsPage = (): JSX.Element => {
    const { open, close } = useDialog()
    const { open: openDrawer, close: closeDrawer } = useDrawer()
    const navigate = useNavigate()

    const theme = useTheme()

    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const [search, setSearch] = useState('')
    const { clients, loading, refetch } = useClients({
        first: PAGE_SIZE,
        offset: 0,
        search: search || undefined,
    })

    const { courses } = useCourses()

    const handleOpenGrantAccessModal = (item: CourseType) => {
        if (tablet) {
            open({
                component: GrantAccessScreen,
                options: {
                    onClose: close,
                },
                props: {
                    course: item,
                    onCancel: close,
                    onSubmit: (email: string, streamId?: string) => {
                        close()
                        navigate(
                            `../${paths.course(
                                item.id
                            )}?selectable=true&email=${email}${
                                streamId ? `&stream=${streamId}` : ''
                            }`
                        )
                    },
                },
            })
        } else {
            openDrawer({
                component: AccessForm,
                options: {
                    onClose: closeDrawer,
                },
                props: {
                    course: item,
                    onCancel: closeDrawer,
                    onSubmit: ({
                        email,
                        stream,
                    }: {
                        email: string
                        stream?: string | null
                    }) => {
                        closeDrawer()
                        navigate(
                            `../${paths.course(
                                item.id
                            )}?selectable=true&email=${email}${
                                stream ? `&stream=${stream}` : ''
                            }`
                        )
                    },
                },
            })
        }
    }

    const handleClientClick = (id: string) => {
        navigate(`../${paths.client(id)}`)
    }

    const handleBack = () => {
        navigate(`../${paths.backoffice}`)
    }

    return tablet ? (
        <ClientsPageDesktop
            onRefetchClients={refetch}
            onSearch={setSearch}
            clients={clients}
            loading={loading}
            courses={courses}
            onAdd={handleOpenGrantAccessModal}
            onClick={handleClientClick}
            onBack={handleBack}
        />
    ) : (
        <ClientsPageMobile
            onRefetchClients={refetch}
            onSearch={setSearch}
            clients={clients}
            loading={loading}
            courses={courses}
            onAdd={handleOpenGrantAccessModal}
            onClick={handleClientClick}
            onBack={handleBack}
        />
    )
}
export default ClientsPage
