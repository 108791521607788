import React, { JSX } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Stack,
    Typography,
    TextField,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'

import { ModuleFormScheme, ModuleFormValues } from './index.schema'
import { useModulesAPI } from '@backoffice/hooks/modules'
import { CourseType, ModuleType } from 'api/root/generated'

interface ModuleFormProps {
    course: CourseType
    module?: ModuleType
    onSuccess?: (action: 'add' | 'update') => void
    onDelete: () => void
}

const ModuleForm = ({
    course,
    module,
    onSuccess,
    onDelete,
}: ModuleFormProps): JSX.Element => {
    const { create, update, loading } = useModulesAPI()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: module
            ? {
                  name: module.name || '',
                  description: module.description || '',
              }
            : {
                  name: '',
                  description: '',
              },
        validationSchema: ModuleFormScheme,
        onSubmit: (values: ModuleFormValues) => {
            if (module) {
                update({
                    input: {
                        moduleId: module.id,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('update')
                    }
                })
            } else {
                create({
                    input: {
                        courseId: course.id,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('add')
                    }
                })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                ...(tablet && {
                    maxWidth: '500px',
                }),
                minWidth: 'min(80vw, 500px)',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize={tablet ? '20px' : '16px'}
                            textAlign={tablet ? 'left' : 'center'}
                        >
                            {module
                                ? t(
                                      'backoffice:content.course.module.editTitle'
                                  )
                                : t(
                                      'backoffice:content.course.module.createTitle'
                                  )}
                        </Typography>
                    </Stack>
                    <TextField
                        fullWidth
                        autoComplete="off"
                        name="name"
                        variant="outlined"
                        placeholder={
                            t(
                                'backoffice:content.course.module.fields.name'
                            ) as string
                        }
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                        sx={{
                            ...(!tablet && {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }),
                        }}
                    />
                    <TextField
                        multiline
                        rows={tablet ? 14 : 10}
                        fullWidth
                        autoComplete="off"
                        name="description"
                        variant="outlined"
                        placeholder={
                            t(
                                'backoffice:content.course.module.fields.description'
                            ) as string
                        }
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                        }
                        sx={{
                            ...(!tablet && {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }),
                        }}
                    />
                    <Stack gap="10px">
                        <Button
                            loading={loading}
                            color="success"
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{ padding: tablet ? '15px' : '10px' }}
                        >
                            <Typography>
                                {module
                                    ? t(
                                          'backoffice:content.course.module.actions.save'
                                      )
                                    : t(
                                          'backoffice:content.course.module.actions.create'
                                      )}
                            </Typography>
                        </Button>
                        {module && (
                            <Button
                                loading={loading}
                                sx={{ padding: '10px' }}
                                color="error"
                                variant="text"
                                fullWidth
                                onClick={() => onDelete()}
                            >
                                <Typography>
                                    {t(
                                        'backoffice:content.course.module.actions.delete'
                                    )}
                                </Typography>
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default ModuleForm
