import React from 'react'

import { useFormik } from 'formik'
import {
    Box,
    TextField,
    Stack,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'

import { SocialsFormScheme, SocialsFormValues } from './index.schema'
import { UserType } from 'api/root/generated'

interface SocialsFormProps {
    onSubmit: (values: SocialsFormValues) => void
    user: UserType
}

const SocialsForm = ({ onSubmit, user }: SocialsFormProps) => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: {
            instagram: '',
            tiktok: '',
            facebook: '',
        },
        validationSchema: SocialsFormScheme,
        onSubmit,
    })

    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                minWidth: 'min(80vw, 500px)',
                ...(tablet && {
                    maxWidth: '500px',
                }),
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize={tablet ? '20px' : '16px'}
                            textAlign={tablet ? 'left' : 'center'}
                        >
                            {t('backoffice:settings.socials.title')}
                        </Typography>
                    </Stack>
                    <Stack gap="25px">
                        <TextField
                            variant="outlined"
                            name="instagram"
                            placeholder={
                                t(
                                    'backoffice:settings.socials.instagramLabel'
                                ) as string
                            }
                            value={formik.values.instagram}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.instagram &&
                                Boolean(formik.errors.instagram)
                            }
                            helperText={
                                formik.touched.instagram &&
                                formik.errors.instagram
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            name="tiktok"
                            placeholder={
                                t(
                                    'backoffice:settings.socials.tiktokLabel'
                                ) as string
                            }
                            value={formik.values.tiktok}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.tiktok &&
                                Boolean(formik.errors.tiktok)
                            }
                            helperText={
                                formik.touched.tiktok && formik.errors.tiktok
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            name="facebook"
                            placeholder={
                                t(
                                    'backoffice:settings.socials.facebookLabel'
                                ) as string
                            }
                            value={formik.values.facebook}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.facebook &&
                                Boolean(formik.errors.facebook)
                            }
                            helperText={
                                formik.touched.facebook &&
                                formik.errors.facebook
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                    </Stack>
                    <Stack gap="10px">
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            color="success"
                            sx={{ padding: tablet ? '15px' : '10px' }}
                        >
                            <Typography>
                                {t('backoffice:settings.socials.save')}
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default SocialsForm
