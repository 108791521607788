import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    TextField,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import { number, object, string } from 'yup'

import { Image } from 'components'
import wayForPayLogo from 'assets/images/wayforpay.png'
import { WayForPayPayload } from '@backoffice/components/pages/SettingsPage/index.types'

export interface WayForPayFormValues {
    paymentService: number
    payload: WayForPayPayload
}

interface WayForPayFormProps {
    paymentService: number
    onSubmit: (values: WayForPayFormValues) => void
    onDelete: (values: WayForPayFormValues) => void
    payload: WayForPayPayload | null
    loading?: boolean
}

const WayForPayForm = ({
    paymentService,
    onSubmit,
    loading,
    payload,
    onDelete,
}: WayForPayFormProps): JSX.Element => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))
    const formik = useFormik({
        initialValues: {
            paymentService,
            payload: payload || {
                merchant_login: '',
                merchant_secret_key: '',
            },
        },
        validationSchema: object({
            paymentService: number(),
            payload: object({
                merchant_login: string().required(
                    t('auth:errors.required-field') as string
                ),
                merchant_secret_key: string().required(
                    t('auth:errors.required-field') as string
                ),
            }),
        }),
        onSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                gap="25px"
                padding="25px"
                sx={{
                    maxWidth: '375px',
                    minWidth: 'min(80vw, 375px)',
                }}
            >
                <Typography
                    fontSize={tablet ? '20px' : '16px'}
                    textAlign={tablet ? 'left' : 'center'}
                >
                    {t('backoffice:settings.payment.forms.wayForPayTitle')}
                </Typography>
                {tablet && (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            boxShadow: '0 0 7px #000',
                            border: `2px solid #000`,
                            padding: '20px 10px',
                            background: '#e5e5e5',
                            position: 'relative',
                            marginLeft: '-24px',
                            marginRight: '-24px',
                        }}
                    >
                        <Image
                            src={wayForPayLogo}
                            sx={{
                                width: '100%',
                            }}
                        />
                    </Stack>
                )}

                <Stack gap="25px">
                    <TextField
                        placeholder="Merchant login"
                        variant="outlined"
                        fullWidth
                        name="payload.merchant_login"
                        value={formik.values.payload.merchant_login}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.merchant_login &&
                            Boolean(formik.errors?.payload?.merchant_login)
                        }
                        helperText={
                            formik.touched?.payload?.merchant_login &&
                            formik.errors?.payload?.merchant_login
                        }
                        sx={{
                            ...(!tablet && {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }),
                        }}
                    />
                    <TextField
                        placeholder="Merchant secret key"
                        variant="outlined"
                        fullWidth
                        name="payload.merchant_secret_key"
                        value={formik.values.payload.merchant_secret_key}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.merchant_secret_key &&
                            Boolean(formik.errors?.payload?.merchant_secret_key)
                        }
                        helperText={
                            formik.touched?.payload?.merchant_secret_key &&
                            formik.errors?.payload?.merchant_secret_key
                        }
                        sx={{
                            ...(!tablet && {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }),
                        }}
                    />
                </Stack>
                <Stack gap="10px">
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="success"
                        sx={{ padding: tablet ? '15px' : '10px' }}
                        loading={loading}
                    >
                        <Typography>
                            {t('backoffice:settings.payment.forms.save')}
                        </Typography>
                    </Button>
                    {payload && (
                        <Button
                            loading={loading}
                            sx={{ padding: '10px' }}
                            color="error"
                            variant="text"
                            fullWidth
                            onClick={() => onDelete(formik.values)}
                        >
                            <Typography>
                                {t('backoffice:settings.payment.forms.cancel')}
                            </Typography>
                        </Button>
                    )}
                </Stack>
            </Stack>
        </form>
    )
}
export default WayForPayForm
