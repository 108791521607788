import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    HomeworkListDocument,
    HomeworkType,
    HomeworkCreateDocument,
    HomeworkCreateMutation,
    HomeworkDeleteDocument,
    HomeworkDeleteMutation,
    HomeworkUpdateDocument,
    HomeworkUpdateMutation,
    MutationHomeworkCreateArgs,
    MutationHomeworkDeleteArgs,
    MutationHomeworkUpdateArgs,
    HomeworkDocument,
} from 'api/root/generated'

export const useHomeworks = (skip = false) => {
    const [response, setResponse] = useState<HomeworkType[]>([])

    const { data, loading, error, refetch } = useQuery(HomeworkListDocument, {
        skip,
    })

    useEffect(() => {
        if (data?.homeworkList) {
            const courses: HomeworkType[] = data?.homeworkList.edges.map(
                edge => edge?.node as HomeworkType
            )
            setResponse(courses)
        }
    }, [data])

    return {
        homeworks: response,
        loading,
        error,
        refetch,
    }
}

export const useHomework = (id?: string, uniqueSubmission = false) => {
    const { data, loading, error, refetch } = useQuery(HomeworkDocument, {
        ...(id && {
            variables: {
                id,
                unique: uniqueSubmission,
            },
        }),
        skip: !id,
    })

    let homework

    if (data?.homeworkDetails) {
        homework = data.homeworkDetails
    }

    return {
        homework,
        loading: loading || typeof data === 'undefined',
        error,
        refetch,
    }
}

export const useHomeworksAPI = () => {
    const [updateHomework, { loading: updateLoading }] = useMutation(
        HomeworkUpdateDocument
    )
    const [createHomework, { loading: createLoading }] = useMutation(
        HomeworkCreateDocument
    )
    const [deleteHomework, { loading: deleteLoading }] = useMutation(
        HomeworkDeleteDocument
    )

    const update = (data: MutationHomeworkUpdateArgs) => {
        return updateHomework({
            variables: data,
        }).then((response: FetchResult<HomeworkUpdateMutation>) => {
            return response?.data?.homeworkUpdate
        })
    }

    const create = (data: MutationHomeworkCreateArgs) => {
        return createHomework({
            variables: data,
        }).then((response: FetchResult<HomeworkCreateMutation>) => {
            return response?.data?.homeworkCreate
        })
    }

    const remove = (data: MutationHomeworkDeleteArgs) => {
        return deleteHomework({
            variables: data,
        }).then((response: FetchResult<HomeworkDeleteMutation>) => {
            return response?.data?.homeworkDelete
        })
    }

    return {
        update,
        create,
        remove,
        loading: createLoading || updateLoading || deleteLoading,
    }
}
