import React from 'react'

import { Button, Divider, Stack, Typography } from '@mui/material'
import {
    Add,
    DeleteOutline,
    EditOutlined,
    PlayCircle,
    Quiz,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import useCourseEntityAPI from '@backoffice/hooks/course'
import useConfirmationDialog from '../../../../hooks/common/useConfirmationDialog'
import {
    CourseType,
    LessonType,
    ModuleType,
} from '../../../../../../api/root/generated'
import { LessonForm } from '@backoffice/components/forms'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { paths as storefrontPaths } from '@storefront/index.routes'
import { useDialog, useDrawer } from 'hooks/common'
import { Image } from '../../../../../../components'
import noBackground from '../../../../../../assets/images/no-background.png'

interface LessonMenuProps {
    onClose: () => void
    lesson: LessonType
    module: ModuleType
}

const LessonMenu = ({ lesson, onClose, module }: LessonMenuProps) => {
    const navigate = useNavigate()

    const { open, close } = useDrawer()
    const { enqueueSnackbar } = useSnackbar()
    const { refetch, removeLesson } = useCourseEntityAPI()
    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const handleOpenLessonForm = () => {
        onClose()
        open({
            component: LessonForm,
            props: {
                lesson,
                module,
                onSuccess: (action: 'add' | 'update') => {
                    refetch()
                    close()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.lesson.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.lesson.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenHomework = () => {
        onClose()
        navigate(
            `../${backofficePaths.homework}?category=lesson&relationId=${lesson?.homework?.relationId}&homeworkId=${lesson?.homework?.id}&title=${lesson?.name}`
        )
    }

    const handleOpenLesson = () => {
        navigate(`../${storefrontPaths.watch(lesson.id)}`)
    }

    const handleDeleteLesson = () => {
        removeLesson({
            input: {
                lessonId: lesson.id,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                close()
                enqueueSnackbar(
                    t(
                        'backoffice:content.course.lesson.notifications.successDeleted'
                    ),
                    { variant: 'success' }
                )
            }
        })
    }

    return (
        <Stack divider={<Divider />} padding="20px" gap="15px">
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                padding="10px"
                sx={{
                    borderRadius: '12px',
                    background: '#181818',
                }}
            >
                <Image
                    sx={{
                        minWidth: '32px',
                        width: '32px',
                        height: '32px',
                        objectFit: 'cover',
                        borderRadius: '4px',
                    }}
                    src={lesson.videoPreview?.img60x40 || noBackground}
                />
                <Typography
                    sx={{
                        fontSize: '18px',
                        lineHeight: '20px',
                    }}
                >
                    {lesson.name}
                </Typography>
            </Stack>

            <Stack
                sx={{
                    '& .MuiButtonBase-root': {
                        width: '100%',
                        padding: '12px 0',
                        justifyContent: 'flex-start',
                    },
                }}
            >
                <Button
                    color="inherit"
                    variant="text"
                    startIcon={<PlayCircle />}
                    onClick={handleOpenLesson}
                >
                    <Typography>
                        {t('backoffice:content.course.lesson.actions.open')}
                    </Typography>
                </Button>
                <Button
                    color="inherit"
                    variant="text"
                    startIcon={lesson?.homework ? <Quiz /> : <Add />}
                    onClick={handleOpenHomework}
                >
                    <Typography>
                        {t(
                            lesson.homework
                                ? 'backoffice:content.course.lesson.actions.updateHomework'
                                : 'backoffice:content.course.lesson.actions.addHomework'
                        )}
                    </Typography>
                </Button>
                <Button
                    color="inherit"
                    variant="text"
                    startIcon={<EditOutlined />}
                    onClick={handleOpenLessonForm}
                >
                    <Typography>
                        {t('backoffice:content.course.lesson.actions.edit')}
                    </Typography>
                </Button>

                <Button
                    color="error"
                    variant="text"
                    startIcon={<DeleteOutline />}
                    onClick={() => {
                        onClose()
                        requestConfirmation(
                            lesson,
                            'lesson',
                            handleDeleteLesson
                        )
                    }}
                >
                    <Typography>
                        {t('backoffice:content.course.lesson.actions.delete')}
                    </Typography>
                </Button>
            </Stack>
        </Stack>
    )
}

export default LessonMenu
