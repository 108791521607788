import React from 'react'

import {
    CircularProgress,
    LinearProgress,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'

import noBackground from 'assets/images/no-background.png'
import { Image } from 'components'

export interface LoadingContent {
    image: string
    name: string
    status: 'IN_PROGRESS' | 'COMPLETED' | 'FAILED'
    moduleId?: string
}

interface ContentLoaderProps {
    items: LoadingContent[]
    visible: boolean
}

const ContentLoader = ({ items, visible }: ContentLoaderProps) => {
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const isAllItemsCompleted = items.every(i => i.status === 'COMPLETED')

    const renderLoadingItem = (item: LoadingContent) => {
        return (
            <Stack direction="row" gap="15px" alignItems="center">
                <Image
                    sx={{
                        width: '80px',
                        height: '50px',
                        objectFit: 'cover',
                    }}
                    src={item.image || noBackground}
                />
                <Stack
                    gap={item.status === 'IN_PROGRESS' ? '22px' : '5px'}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography color="secondary.main">{item.name}</Typography>
                    {item.status === 'IN_PROGRESS' ? (
                        <LinearProgress color="success" />
                    ) : (
                        <Typography
                            fontSize="14px"
                            color={
                                item.status === 'COMPLETED'
                                    ? 'success'
                                    : 'error'
                            }
                        >
                            {item.status === 'COMPLETED'
                                ? 'Завантажено'
                                : 'Сталась помилка'}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack
            sx={theme => ({
                position: 'fixed',
                bottom: 0,
                right: 0,
                zIndex: 25,
                opacity: visible && !isAllItemsCompleted ? 1 : 0,
                transform:
                    visible && !isAllItemsCompleted
                        ? 'translateY(0)'
                        : `translateY(100%)`,
                transition: '0.3s ease',
                background: theme.palette.background.default,
                width: '100%',
                padding: tablet ? '15px 25px 0' : '10px 15px 0',
                borderTop: `1px solid rgba(255, 255, 255, .2)`,
            })}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography color="secondary.dark">
                    Завантаження контенту
                </Typography>
                {isAllItemsCompleted ? (
                    <CheckCircleOutline
                        sx={theme => ({
                            fontSize: '25px',
                            color: theme.palette.success.main,
                        })}
                    />
                ) : (
                    <CircularProgress color="secondary" size={25} />
                )}
            </Stack>
            <Stack
                gap={tablet ? '25px' : '15px'}
                sx={{
                    padding: tablet ? '20px 0 25px' : '10px 0 15px',
                }}
            >
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderLoadingItem(item)}
                    </React.Fragment>
                ))}
            </Stack>
        </Stack>
    )
}

export default ContentLoader
