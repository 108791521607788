export enum ROLES {
    GUEST = 'GUEST',
    CLIENT = 'CLIENT',
    COACH = 'COACH',
}

export const MAX_PHOTOS_LIMIT = 5
export const MAX_PHOTO_SIZE = 20971520 // 20MB
export const MAX_VIDEO_SIZE = 2147483648 // 2GB
export const PHOTO_ACCEPT_EXTENSIONS = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
}
export const VIDEO_ACCEPT_EXTENSIONS = {
    'video/*': [],
}

export const FILE_ACCEPT_EXTENSIONS = {
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
        '.docx',
    ],
    'text/plain': ['.txt'],
}
