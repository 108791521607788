import React, { useState } from 'react'

import {
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import {
    ArrowCircleRightOutlined,
    CallSplitRounded,
    ArrowDropDown,
    ContentCopy,
} from '@mui/icons-material'
import { t } from 'i18next'

import { Image } from 'components'
import noBackground from 'assets/images/no-background.png'
import {
    CourseAccessType,
    CourseStreamType,
    CourseType,
} from 'api/root/generated'

interface ClientAccessItemProps {
    access: CourseAccessType
    streams: CourseStreamType[]
    onClick: (access: CourseAccessType) => void
    onStreamChange: (access: CourseAccessType, streamId?: string | null) => void
    onCopyAccessLink: (access: CourseAccessType) => void
}

const ClientAccessItem = ({
    access,
    onClick,
    streams,
    onStreamChange,
    onCopyAccessLink,
}: ClientAccessItemProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const resolveLessonCountTitle = (lessonCount: number) => {
        if (lessonCount >= 5) {
            return t(
                'backoffice:client.sections.access.lessonCountZeroOrEqualOrMoreThanFive',
                {
                    count: lessonCount,
                }
            )
        }

        if (lessonCount === 1) {
            return t('backoffice:client.sections.access.lessonCountSingle')
        }

        return t(
            'backoffice:client.sections.access.lessonCountMoreThanOneAndLessThanFive',
            {
                count: lessonCount,
            }
        )
    }

    const renderActions = (compact = false) => {
        return (
            <Stack direction="row">
                <Tooltip
                    title={
                        <Typography fontSize="18px" variant="caption">
                            {t('backoffice:access.copyAccessLink')}
                        </Typography>
                    }
                >
                    <IconButton
                        color="secondary"
                        size={compact ? 'small' : 'large'}
                        onClick={e => {
                            e.stopPropagation()
                            onCopyAccessLink(access)
                        }}
                    >
                        <ContentCopy
                            sx={{
                                fontSize: '21px',
                            }}
                        />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    title={
                        <Typography>
                            {t(
                                'backoffice:client.sections.access.checkAccessedContent'
                            )}
                        </Typography>
                    }
                >
                    <IconButton
                        onClick={() => onClick(access)}
                        color="secondary"
                        size={compact ? 'small' : 'large'}
                    >
                        <ArrowCircleRightOutlined />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <Stack
            key={access?.id}
            direction={tablet ? 'row' : 'column-reverse'}
            justifyContent="space-between"
            alignItems={tablet ? 'center' : 'baseline'}
        >
            <Stack gap="25px" direction="row" alignItems="center">
                {desktop && (
                    <Image
                        sx={{
                            width: '100px',
                            height: '75px',
                            objectFit: 'cover',
                            verticalAlign: 'middle',
                        }}
                        src={
                            access?.course.appearance?.background?.img150x100 ||
                            noBackground
                        }
                    />
                )}

                <Stack
                    justifyContent="space-between"
                    divider={<Divider orientation="vertical" flexItem />}
                    gap={tablet ? '3px' : '10px'}
                >
                    <Typography fontSize="20px">
                        {access?.course.name}
                    </Typography>
                    <Stack direction="column" gap="25px">
                        <Button
                            sx={() => ({
                                width: 'fit-content',
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                                '&.MuiButtonGroup-grouped': {
                                    '&:hover': {
                                        borderRightColor:
                                            'rgba(255, 255, 255, 0.2)',
                                    },
                                },
                            })}
                            size="small"
                            color="secondary"
                            variant="outlined"
                            startIcon={<CallSplitRounded />}
                            endIcon={<ArrowDropDown />}
                            onClick={e => setAnchorEl(e.currentTarget)}
                        >
                            <Typography>
                                {access.stream
                                    ? access.stream.name
                                    : t(
                                          'backoffice:client.sections.access.noStream'
                                      )}
                            </Typography>
                        </Button>
                        {!tablet && renderActions()}
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                alignSelf="flex-start"
                gap="25px"
            >
                {desktop && (
                    <Typography fontSize="18px" color="secondary.dark">
                        {(access?.lessons.edges.length as number) ===
                        access?.course?.lessonCount
                            ? t(
                                  'backoffice:client.sections.access.allCourseIsAccessed',
                                  {
                                      accessedLessons: resolveLessonCountTitle(
                                          access?.lessons.edges.length as number
                                      ),
                                      lessonsCount: access?.course?.lessonCount,
                                  }
                              )
                            : t(
                                  'backoffice:client.sections.access.accessedLessonsCount',
                                  {
                                      accessedLessons: resolveLessonCountTitle(
                                          access?.lessons.edges.length as number
                                      ),
                                      lessonsCount: access?.course?.lessonCount,
                                  }
                              )}
                    </Typography>
                )}

                {tablet && renderActions(false)}
            </Stack>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    minWidth: '100%',
                }}
            >
                {streams.length > 0 ? (
                    <>
                        <MenuItem
                            disabled={!access.stream}
                            onClick={() => {
                                onStreamChange(access, null)
                                setAnchorEl(null)
                            }}
                            disableRipple
                        >
                            {t('backoffice:client.sections.access.noStream')}
                        </MenuItem>
                        {streams.map(s => (
                            <MenuItem
                                disabled={access.stream?.id === s.id}
                                key={s.id}
                                onClick={() => {
                                    console.log(access)
                                    onStreamChange(access, s.id)
                                    setAnchorEl(null)
                                }}
                                disableRipple
                            >
                                {s.name}
                            </MenuItem>
                        ))}
                    </>
                ) : (
                    <MenuItem
                        disabled
                        onClick={() => {
                            onStreamChange(access, null)
                            setAnchorEl(null)
                        }}
                        disableRipple
                    >
                        {t('backoffice:client.sections.access.noStreams')}
                    </MenuItem>
                )}
            </Menu>
        </Stack>
    )
}

export default ClientAccessItem
