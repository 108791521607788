import React, { JSX, useState } from 'react'

import {
    Button,
    IconButton,
    Stack,
    SwipeableDrawer,
    Typography,
} from '@mui/material'
import {
    AccessTime,
    AccessTimeOutlined,
    Add,
    LockOpen,
    MoreTime,
    ViewList,
} from '@mui/icons-material'
import { t } from 'i18next'
import { format } from 'date-fns-tz'

import { LessonType, ModuleType } from 'api/root/generated'
import { Image } from 'components'
import {
    PageLayoutMobile,
    Paper,
    ScrollableToolbar,
    DatepickerMobile,
} from '@backoffice/components/common'
import { CourseStreamPageProps } from './index.types'
import noBackground from 'assets/images/no-background.png'
import { useDrawer } from 'hooks/common'

const CourseStreamPageMobile = ({
    selectedStream,
    onSelectStream,
    onBack,
    onAdd,
    streams,
    onChange,
    course,
}: CourseStreamPageProps): JSX.Element => {
    const { open, close } = useDrawer()

    const modules =
        course?.modules?.edges.map(edge => edge?.node as ModuleType) || []

    const handleOpenLessonSchedule = ({
        lessonId,
        openAt,
    }: {
        lessonId: string
        openAt: string | null
    }) => {
        open({
            component: DatepickerMobile,
            props: {
                value: openAt ? new Date(openAt) : null,
                onChange: (v: Date | null) => {
                    onChange(lessonId as string, v ? v.toISOString() : null)
                    close()
                },
                onClose: () => {
                    close()
                },
            },
            options: {
                onClose: () => {
                    close()
                },
            },
        })
    }

    const renderNoStreamsScreen = () => {
        return (
            <Stack alignItems="center" justifyContent="center" paddingTop="25%">
                <ViewList
                    sx={() => ({
                        opacity: '0.4',
                        fontSize: '124px',
                        color: 'rgba(255, 255, 255, 0.15)',
                    })}
                />
                <Stack gap="25px" alignItems="center">
                    <Typography color="secondary.dark" fontSize="14px">
                        {t('backoffice:stream.noStream')}
                    </Typography>
                    <Button
                        onClick={onAdd}
                        sx={() => ({
                            width: 'fit-content',
                            '& .MuiSvgIcon-root': {
                                fontSize: '22px',
                            },
                        })}
                        variant="text"
                        color="primary"
                        startIcon={<Add />}
                    >
                        <Typography fontSize="16px">
                            {t('backoffice:stream.actions.createFirst')}
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
        )
    }

    const renderToolbar = () => (
        <ScrollableToolbar
            actions={streams.map(stream => ({
                label: stream.name,
                key: stream.id,
                onClick: () => {
                    onSelectStream(stream)
                },
                active: stream.id === selectedStream?.id,
            }))}
        >
            <IconButton
                key="add"
                size="small"
                color="secondary"
                onClick={onAdd}
            >
                <Add />
            </IconButton>
        </ScrollableToolbar>
    )

    const renderLesson = (lesson: LessonType) => {
        const schedule = selectedStream
            ? JSON.parse(selectedStream.schedule)
            : {}

        const scheduled = typeof schedule[lesson.id] !== 'undefined'

        return (
            <Stack
                direction="row"
                padding="10px 5px"
                gap="15px"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack direction="row" alignItems="center" gap="15px">
                    <Image
                        sx={{
                            minWidth: '32px',
                            width: '32px',
                            height: '32px',
                            objectFit: 'cover',
                            borderRadius: '4px',
                        }}
                        src={lesson.videoPreview?.img60x40 || noBackground}
                    />
                    <Stack gap="3px" alignItems="flex-start">
                        <Typography
                            sx={{
                                fontSize: '14px',
                                lineHeight: '15px',
                            }}
                        >
                            {lesson.name}
                        </Typography>
                        {scheduled && (
                            <Button
                                variant="text"
                                size="small"
                                sx={{
                                    padding: 0,

                                    '& .MuiButton-icon': {
                                        marginRight: '4px',
                                    },
                                }}
                            >
                                <Typography
                                    color="primary"
                                    sx={{
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                    }}
                                >
                                    {format(
                                        new Date(schedule[lesson.id]),
                                        'dd.MM.yyyy HH:mm',
                                        {
                                            timeZone:
                                                Intl.DateTimeFormat().resolvedOptions()
                                                    .timeZone,
                                        }
                                    )}
                                </Typography>
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <IconButton
                    color={scheduled ? 'primary' : 'secondary'}
                    onClick={() =>
                        handleOpenLessonSchedule({
                            lessonId: lesson.id,
                            openAt: schedule[lesson.id],
                        })
                    }
                >
                    {scheduled ? <AccessTimeOutlined /> : <MoreTime />}
                </IconButton>
            </Stack>
        )
    }

    return (
        <PageLayoutMobile
            pageTitle={`${t('backoffice:pageTitles.streamPage')} | ${
                course?.name
            }`}
            onBack={onBack}
            title="Потоки"
            caption={course?.name}
            toolbar={streams.length > 0 ? renderToolbar : undefined}
            sx={{
                gap: '25px',
            }}
        >
            {streams?.length > 0
                ? modules?.map(module => (
                      <Paper
                          sx={{
                              opacity:
                                  module.lessons.edges?.length === 0 ? 0.4 : 1,
                          }}
                          key={module.id}
                          title={module?.name}
                          content={() =>
                              module?.lessons?.edges
                                  ?.map(edge => edge?.node as LessonType)
                                  ?.map(renderLesson)
                          }
                      />
                  ))
                : renderNoStreamsScreen()}
        </PageLayoutMobile>
    )
}
export default CourseStreamPageMobile
