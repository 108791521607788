import React, { useState } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    FormHelperText,
    Stack,
    Typography,
    TextField,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import { MovieCreationOutlined, PanoramaOutlined } from '@mui/icons-material'

import { FilePicker } from 'components'
import { LessonFormScheme, LessonFormValues } from './index.schema'
import { useLessonsAPI } from '@backoffice/hooks/lessons'
import { LessonType, ModuleType } from 'api/root/generated'
import 'dayjs/locale/uk'

interface LessonFormProps {
    onSuccess: (action: 'add' | 'update') => void
    onCreateNew: (data: any) => void
    lesson?: Partial<LessonType>
    module: ModuleType
}

const LessonForm = ({
    onSuccess,
    lesson,
    module,
    onCreateNew,
}: LessonFormProps) => {
    const [videoPreview, setVideoPreview] = useState<File | null>(null)
    const [preview, setPreview] = useState<string | null>(null)
    const [video, setVideo] = useState<File | null>(null)

    const { create, update, loading } = useLessonsAPI()
    const theme = useTheme()
    const tablet = useMediaQuery(theme.breakpoints.up('sm'))

    const formik = useFormik({
        initialValues: lesson
            ? {
                  name: lesson.name || '',
                  description: lesson.description || '',
                  videoUrl: true,
              }
            : {
                  name: '',
                  description: '',
                  videoUrl: false,
              },
        validationSchema: LessonFormScheme,
        onSubmit: (values: LessonFormValues) => {
            const { name, description } = values
            if (lesson) {
                update({
                    input: {
                        lessonId: lesson.id as string,
                        ...(videoPreview && { videoPreview }),
                        ...(video && { video }),
                        name,
                        description,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('update')
                    }
                })
            } else {
                onCreateNew({
                    moduleId: module.id,
                    ...(videoPreview && { videoPreview }),
                    ...(video && { video }),
                    ...(preview && { preview }),
                    name,
                    description,
                })
                // create({
                //     input: {
                //         moduleId: module.id,
                //         ...(videoPreview && { videoPreview }),
                //         ...(video && { video }),
                //         name,
                //         description,
                //     },
                // })
                //     .then(response => {
                //         if (response?.success) {
                //             onSuccess?.('add')
                //         }
                //     })
                //     .catch(error => {
                //         if (
                //             error.message.includes(
                //                 'Received not compatible datetime'
                //             )
                //         ) {
                //             onSuccess?.('add')
                //         }
                //     })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: tablet ? '25px' : '15px',
                ...(tablet && {
                    maxWidth: '500px',
                }),
                minWidth: 'min(80vw, 500px)',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize={tablet ? '20px' : '16px'}
                            textAlign={tablet ? 'left' : 'center'}
                        >
                            {lesson
                                ? t(
                                      'backoffice:content.course.lesson.editTitle'
                                  )
                                : t(
                                      'backoffice:content.course.lesson.createTitle'
                                  )}
                        </Typography>
                    </Stack>
                    <Stack
                        gap="25px"
                        sx={{
                            ...(loading && {
                                opacity: '0.5',
                                pointerEvents: 'none',
                            }),
                        }}
                    >
                        <TextField
                            variant="outlined"
                            name="name"
                            placeholder={
                                t(
                                    'backoffice:content.course.lesson.fields.name'
                                ) as string
                            }
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            name="description"
                            multiline
                            minRows={8}
                            placeholder={
                                t(
                                    'backoffice:content.course.lesson.fields.description'
                                ) as string
                            }
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                            }
                            helperText={
                                formik.touched.description &&
                                formik.errors.description
                            }
                            sx={{
                                ...(!tablet && {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                }),
                            }}
                        />
                        <Stack>
                            <Stack
                                gap="10px"
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <MovieCreationOutlined />
                                <FilePicker
                                    type="video"
                                    title={t(
                                        'backoffice:content.course.lesson.fields.video'
                                    )}
                                    value={video?.name}
                                    onChange={file => {
                                        setVideo(file)
                                        formik.setFieldValue('videoUrl', true)
                                    }}
                                />
                            </Stack>
                            {formik.touched.videoUrl &&
                                formik.errors.videoUrl && (
                                    <FormHelperText
                                        sx={{
                                            background: '#ad4545',
                                            color: 'white',
                                            padding: '5px',
                                            fontSize: '14px',
                                            width: '100%',
                                            top: '5px',
                                            zIndex: 2,
                                            position: 'relative',
                                        }}
                                    >
                                        {t(
                                            'backoffice:content.course.lesson.form.errors.noVideo'
                                        )}
                                    </FormHelperText>
                                )}
                        </Stack>

                        <Stack
                            gap="10px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <PanoramaOutlined />
                            <FilePicker
                                title={t(
                                    'backoffice:content.course.lesson.fields.videoPreview'
                                )}
                                value={
                                    videoPreview?.name ||
                                    lesson?.videoPreview?.img520x200
                                }
                                onChange={(file: File | null, preview) => {
                                    setPreview(preview)
                                    setVideoPreview(file)
                                }}
                            />
                        </Stack>
                    </Stack>

                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="success"
                        loading={loading}
                        sx={{ padding: tablet ? '15px' : '10px' }}
                    >
                        <Typography>
                            {lesson
                                ? t(
                                      'backoffice:content.course.lesson.actions.save'
                                  )
                                : t(
                                      'backoffice:content.course.lesson.actions.create'
                                  )}
                        </Typography>
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}

export default LessonForm
